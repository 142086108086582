import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useIsValidEmailLazyQuery } from '@/apollo/generated/graphql'
import { CompanyContactPersonForm } from '@/forms/Auth/Registration/CompanyContactPersonForm'
import { CompanyOnboardingLayout } from '@/layouts/CompanyOnboardingLayout'
import { CompanyOnboardingContext } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-onboarding-container'

type ContactPersonPageProps = {
  continueWithRoute: string
}

export const ContactPersonPage: FC<ContactPersonPageProps> = ({ continueWithRoute }) => {
  const [t] = useTranslation()
  const context = useContext(CompanyOnboardingContext)

  const [request] = useIsValidEmailLazyQuery()

  const onFinish = async (values: object) => {
    const response = await request({
      variables: {
        email: context.form.getFieldValue('email'),
      },
    })

    if (!response.error) {
      context.onFinish(continueWithRoute)(values)
    }
  }

  return (
    <CompanyOnboardingLayout step={1} title={t('auth.register.firstStep')}>
      <CompanyContactPersonForm
        form={context.form}
        onFinish={onFinish}
        onValuesChange={context.onValuesChange}
      />
    </CompanyOnboardingLayout>
  )
}
