import { CompanyProjectAssignmentPage } from '@/pages/CompanyPages'

export default {
  path: 'projectAssignment',
  children: [
    {
      path: ':id',
      element: <CompanyProjectAssignmentPage />,
    },
  ],
}
