import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Col, Form, RadioChangeEvent, Row, Space, Typography } from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import { AssignmentLocationType, JobCategory } from '@/apollo/generated/graphql'
import { Button } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { Link } from '@/components/Link'
import { SpecializationFields } from '@/forms/fields/SpecializationFields'
import { TalentRegistrationPayloadState } from '@/pages/AuthenticationPages/RegistrationPages/StudentRegistrationPage'
import { StudentRegistrationSteps } from '@/screens/AuthenticationScreens/StudentRegistrationScreens/components/StudentRegistrationSteps'

export interface StudentInterestsFormData extends TalentRegistrationPayloadState {
  category: JobCategory
  preferred_location_types: AssignmentLocationType[]
}

export type StudentInterestsFormType = React.ComponentProps<typeof Form<StudentInterestsFormData>>

interface StudentCategoriesScreenProps {
  formProps?: StudentInterestsFormType
  name: string
  loading?: boolean
  category: JobCategory
}

const { Text } = Typography

export const StudentCategoriesScreen: FC<StudentCategoriesScreenProps> = ({
  formProps,
  category,
  loading,
}) => {
  const navigate = useNavigate()

  if (!category) {
    navigate('/registration/talent/interests')
  }

  const { t, i18n } = useTranslation()
  const [agreementChecked, setAgreementChecked] = useState(
    formProps?.form?.getFieldValue('agreementChecked') ?? false,
  )

  const [preferredWorkLocation, setPreferredWorkLocation] = useState<CheckboxValueType[]>(
    formProps?.form?.getFieldValue('preferred_location_types') ?? [],
  )
  const breakpoint = useBreakpoint()

  const onWorkLocationChange = (checkedValues: CheckboxValueType[]) => {
    setPreferredWorkLocation(checkedValues)
    formProps?.form?.setFieldValue('preferred_location_types', checkedValues)
  }

  const onSetAgreementChecked = (event: RadioChangeEvent) => {
    setAgreementChecked(event.target.checked)
    formProps?.form?.setFieldValue('agreementChecked', event.target.checked)
  }

  const optionsCheckboxes = [
    { label: t('form.local'), value: AssignmentLocationType.Local, hidden: true },
    { label: t('form.hybrid'), value: AssignmentLocationType.Hybrid },
    { label: t('form.remote'), value: AssignmentLocationType.Remote },
  ]

  useEffect(
    () => () => {
      formProps?.form?.resetFields([`specializations`])
    },
    [],
  )

  return (
    <Form {...formProps}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <StudentRegistrationSteps current={2} />
        </Col>
        <Col span={24}>
          <Row gutter={[0, 15]}>
            <Typography.Title level={breakpoint.xs ? 4 : 3}>
              {t('auth.register.talent.strengths')}
            </Typography.Title>
            <Text italic>{t('auth.register.talent.infoCategories')}</Text>
          </Row>
        </Col>
        <Col span={24}>
          <SpecializationFields
            maxItems={3}
            typeChips='single'
            specializations={category?.specializations || []}
          />

          <Form.Item shouldUpdate>
            {({ getFieldValue }) =>
              getFieldValue('specializations')?.length === 3 && (
                <Text italic>{t('auth.register.talent.secondInfoCategories')}</Text>
              )
            }
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>{t('auth.register.talent.location_types_info')}</Text>
          <Row align='middle'>
            <Form.Item name='preferred_location_types'>
              <Checkbox.Group
                options={optionsCheckboxes.filter((option) => !option.hidden)}
                onChange={onWorkLocationChange}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Space>
                <Checkbox
                  id='terms_and_conditions'
                  checked={agreementChecked}
                  onChange={onSetAgreementChecked}
                />
                <div>
                  <Typography.Text className='font-medium'>
                    {t('auth.register.signCondition.general')}{' '}
                    <Link
                      size='md'
                      to={`${import.meta.env.VITE_APP_BACKEND_URL}documents/terms_talent_kickjobs.${
                        i18n.language
                      }.pdf`}
                      text={t('auth.register.signCondition.terms')}
                      isExternal
                    />
                  </Typography.Text>
                  <Typography.Text className='font-medium'>
                    {' '}
                    &{' '}
                    <Link
                      size='md'
                      to={`${import.meta.env.VITE_APP_BACKEND_URL}documents/privacy_kickjobs.${
                        i18n.language
                      }.pdf`}
                      text={t('auth.register.signCondition.privacy')}
                      isExternal
                    />
                  </Typography.Text>
                </div>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Button
            loading={loading}
            disabled={!agreementChecked || loading || !preferredWorkLocation.length}
            type='primary'
            htmlType='submit'
            block
          >
            {t('auth.register.talent.submit')}
          </Button>
        </Col>
        <Col span={24} />
      </Row>
    </Form>
  )
}
