import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { useIsMobileDevice } from '@/hooks/useIsMobileDevice'

type AuthenticatedProviderContextType = {
  sideBarCollapsed: boolean
  setSideBarCollapsed: Dispatch<SetStateAction<boolean>>
}

export const AuthenticatedLayoutProviderContext =
  createContext<AuthenticatedProviderContextType | null>(null)

export const useAuthenticatedLayoutProviderContext = () => {
  const context = useContext(AuthenticatedLayoutProviderContext)

  if (!context) {
    throw new Error(
      'useAuthenticatedProviderContext can use only then use AuthenticatedProvider component',
    )
  }

  return context
}

export const AuthenticatedLayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMobileDevice = useIsMobileDevice()
  const [sideBarCollapsed, setSideBarCollapsed] = useState(isMobileDevice)

  return (
    <AuthenticatedLayoutProviderContext.Provider value={{ sideBarCollapsed, setSideBarCollapsed }}>
      {children}
    </AuthenticatedLayoutProviderContext.Provider>
  )
}
