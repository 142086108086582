import React from 'react'
import clsx from 'classnames'

import styles from './styles.module.less'

export type InputLabelProps = {
  labelText: React.ReactNode
  active?: boolean
}
type OtherProps = {
  children: React.ReactElement
  active: boolean
  value?: string | number
}

export const FloatingLabel = ({
  active,
  labelText,
  children,
  value,
}: InputLabelProps & OtherProps) => (
  <div className={styles.container}>
    {children}

    <div className={styles.container__floating_label_wrapper}>
      <div>
        <span
          className={clsx(styles.label, {
            [styles.floatingLabel]:
              active || typeof value === 'string' || typeof value === 'number',
          })}
        >
          {labelText}
        </span>
      </div>
    </div>
  </div>
)
