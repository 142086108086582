import React from 'react'
import { Input as AntdInput } from 'antd'

import { FloatingLabelInput, FormItemProps } from '@/components/Input'

export const Input = ({
  label,
  name,
  active = false,
  inputProps,
  ...rest
}: FormItemProps & { active?: boolean; inputProps?: React.ComponentProps<typeof AntdInput> }) => (
  <FloatingLabelInput labelText={label} name={name} active={active} {...rest}>
    <AntdInput size='large' autoComplete='off' {...inputProps} />
  </FloatingLabelInput>
)
