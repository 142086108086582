import React, { FunctionComponent } from 'react'
import { Typography } from 'antd'

import styles from '@/components/DataRequirements/styles.module.less'
import { Icon } from '@/components/Icons'

export interface DataRequirementItemProps {
  done: boolean
  children: React.ReactNode
}
export const DataRequirementItem: FunctionComponent<DataRequirementItemProps> = ({
  done,
  children,
}) => (
  <div>
    <Typography.Text className={styles.data_requirements__item}>
      <Icon name={done ? 'CheckSvg' : 'WarningSvg'} size='md' /> {children}
    </Typography.Text>
  </div>
)
