import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface'

import { nlVatRegexp } from '@/features/form-fields/vat-number-field/lib'

type UseRules = (props?: { required?: boolean; noFormatLimitations?: boolean }) => Rule[]

export const useRules: UseRules = (props) => {
  const [t] = useTranslation()

  const rules: Rule[] = []

  const vatNumber = {
    pattern: nlVatRegexp,
    message: t('errors.invalid_format') ?? undefined,
  }

  if (!props?.noFormatLimitations) {
    rules.push(vatNumber)
  }

  rules.push({ required: props?.required, message: t('errors.required') ?? undefined })

  return rules
}
