import React, { createRef, FC, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Image, Modal, Row, Space, Typography } from 'antd'
import omit from 'lodash/omit'

import { CompanyByCocNumberDetails } from '@/apollo/generated/graphql'
import CompanyInfoImage from '@/assets/images/image_mobile.webp'
import { Button } from '@/components/Button'
import { Link } from '@/components/Link'
import { CocNumberSimpleFormItemField, VatNumberFormItemField } from '@/features/form-fields'
import { CountryFormItemField } from '@/features/form-fields/country-field/ui'
import { useIsMobileDevice } from '@/hooks/useIsMobileDevice'
import { CompanyOnboardingLayout } from '@/layouts/CompanyOnboardingLayout'
import {
  ImageTypeEnum,
  OnboardingImageContext,
} from '@/layouts/UnauthenticatedLayout/UnauthenticatedLayoutTemplate'
import { CompanyOnboardingContext } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-onboarding-container'
import { CompanyKvkSearchContainer } from '@/screens/AuthenticationScreens/CompanyRegistrationScreens/components/CompanyKvkSearch'

import styles from './styles.module.less'

type CompanyBaseInformationPageProps = {
  continueWithRoute: string
}

export const CompanyBaseInformationPage: FC<CompanyBaseInformationPageProps> = ({
  continueWithRoute,
}) => {
  const isMobileDevice = useIsMobileDevice()
  const [modalOpen, setModalOpen] = useState(isMobileDevice)
  const [loading, setLoading] = useState(false)
  const [showCocNumberField, setCocNumberField] = useState(true)
  const context = useContext(CompanyOnboardingContext)
  const imageContext = useContext(OnboardingImageContext)
  const [t] = useTranslation()

  const ref = createRef<typeof context.form>()

  const country = Form.useWatch('country', context.form)

  useEffect(() => {
    imageContext?.setImage(ImageTypeEnum.companyContact)
  }, [])

  const onKvkSearchDetailsHandler = useCallback((data: CompanyByCocNumberDetails) => {
    const values = omit(data, '__typename')

    context.form.setFieldsValue(values as object)

    ref.current?.submit()
  }, [])

  return (
    <CompanyOnboardingLayout title={t('auth.register.secondStep')} step={0}>
      <Space size={18} direction='vertical'>
        <Typography.Text className='font-small' strong>
          {t('auth.register.kvkSearchStep')}
        </Typography.Text>

        <Form ref={ref} form={context.form} onFinish={context.onFinish(continueWithRoute)}>
          <Row gutter={[8, 36]}>
            <Modal
              open={modalOpen}
              maskClosable={false}
              destroyOnClose
              onCancel={() => setModalOpen(false)}
              className={styles.modal}
              footer={null}
            >
              <Image
                src={CompanyInfoImage}
                className={styles.imageModal}
                preview={false}
                onClick={() => setModalOpen(false)}
              />
            </Modal>
            <Col xs={24}>
              <Row gutter={[8, 8]}>
                <Col xs={24}>
                  <CountryFormItemField revalidateFieldsOnChange={[VatNumberFormItemField]} />
                </Col>

                <Col xs={24}>
                  <Form.Item shouldUpdate>
                    <VatNumberFormItemField
                      rules={{
                        required: country?.isEuMember && country?.isForeignCountry,
                        noFormatLimitations: country?.isEuMember && country?.isForeignCountry,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {!!country && (
              <Col xs={24}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    {country.isForeignCountry ? (
                      <CocNumberSimpleFormItemField rules={{ noFormatLimitations: true }} />
                    ) : (
                      <>
                        {showCocNumberField && (
                          <CompanyKvkSearchContainer
                            onSelect={onKvkSearchDetailsHandler}
                            onSkip={() => ref.current?.submit()}
                            onLoading={setLoading}
                            required={!country.isForeignCountry}
                            noFormatLimitations={country.isForeignCountry}
                            maxLength={8}
                          />
                        )}
                      </>
                    )}
                  </Col>

                  {!country.isForeignCountry && (
                    <Col span={24}>
                      <Link
                        isExternal
                        onClick={(e) => {
                          e.preventDefault()
                          setCocNumberField(false)

                          ref.current?.submit()

                          setTimeout(() => {
                            setCocNumberField(true)
                          })
                        }}
                        to='/'
                        text={t('auth.register.kvkSearchSkip')}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            )}

            <Col span={24}>
              <Button type='primary' loading={loading} block htmlType='submit'>
                {t('navigation.continue')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </CompanyOnboardingLayout>
  )
}
