import React, { Suspense } from 'react'
import { createBrowserRouter, Navigate, RouteObject, RouterProvider } from 'react-router-dom'

import { UserRole } from '@/apollo/generated/graphql'
import { useCheckUser } from '@/providers/AuthenticationProvider/hooks/useCheckUser'
import { useUserState } from '@/providers/AuthenticationProvider/hooks/useUserState'
import { useRbac } from '@/rbac/hooks/useRbac'
import { AdminRoutes } from '@/router/AdminRoutes'
import { AuthenticationRoutes } from '@/router/AuthenticationRoutes'
import { CompanyRoutes } from '@/router/CompanyRoutes'
import { NoAccessRoutes } from '@/router/NoAccessRoutes'
import { SearchRoutes } from '@/router/SearchModuleRoutes'
import { StudentRoutes } from '@/router/StudentRoutes'
import { LoadingScreen } from '@/screens/CommonScreens'

const noMatchRoute = {
  path: '*',
  element: <Navigate replace to='/404' />,
}

const RoleBasedBrowserRouter = () => {
  const { validators } = useRbac()

  const getRoutes = (): RouteObject => {
    if (validators.role.validator.has(UserRole.Admin)) {
      return AdminRoutes
    }
    if (validators.role.validator.has(UserRole.Student)) {
      return StudentRoutes
    }

    if (validators.role.validator.has(UserRole.CompanyAdmin)) {
      return CompanyRoutes
    }

    return NoAccessRoutes
  }

  return <RouterProvider router={createBrowserRouter([getRoutes(), SearchRoutes, noMatchRoute])} />
}

const AuthenticatedRouter = () => {
  const [loading] = useCheckUser()

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <LoadingScreen loading={true} />
      </div>
    )
  }

  return (
    <Suspense fallback={'Loading...'}>
      <RoleBasedBrowserRouter />
    </Suspense>
  )
}

export const Router = () => {
  const { isAuthenticated } = useUserState()

  if (isAuthenticated) {
    return <AuthenticatedRouter />
  }

  const router = createBrowserRouter([AuthenticationRoutes, SearchRoutes, noMatchRoute])

  return <RouterProvider router={router} />
}
