import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Form, Row } from 'antd'
import omit from 'lodash/omit'

import {
  Country,
  JobCategory,
  MutationRegisterStudentArgs,
  useIsValidEmailLazyQuery,
  useRegisterStudentMutation,
} from '@/apollo/generated/graphql'
import { GoBackLink } from '@/components/Links/GoBackLink'
import { StudentPersonalInfoFormData } from '@/forms/Auth/Registration/StudentPersonalInfoForm'
import { formatDateToBackendFormat } from '@/helpers/dates'
import useValidationForm from '@/hooks/useValidationForm'
import {
  ImageTypeEnum,
  OnboardingImageContext,
} from '@/layouts/UnauthenticatedLayout/UnauthenticatedLayoutTemplate'
import {
  BirthDayScreen,
  RegistrationCompletedScreen,
  ToYoungScreen,
} from '@/screens/AuthenticationScreens'
import { BirthDayInputType } from '@/screens/AuthenticationScreens/StudentRegistrationScreens/BirthDayScreen'
import { StudentCategoriesScreen } from '@/screens/AuthenticationScreens/StudentRegistrationScreens/StudentCategoriesScreen'
import {
  StudentInterestsFormData,
  StudentInterestsScreen,
} from '@/screens/AuthenticationScreens/StudentRegistrationScreens/StudentInterestsScreen'
import { StudentPersonalInfoScreen } from '@/screens/AuthenticationScreens/StudentRegistrationScreens/StudentPersonalInfoScreen'
import { underAgeValidate } from '@/utils'

export enum StudentRegistrationSteps {
  birthDay = 'birthDay',
  birthDayLess16 = 'birthDayLess16',
  personalInfo = 'personalInfo',
  interests = 'interests',
  categories = 'categories',
  success = 'success',
}

interface StudentRegistrationPageProps {
  activeStep?: StudentRegistrationSteps
  showBackButton?: boolean
}

export type TalentRegistrationPayloadState = Partial<
  Omit<MutationRegisterStudentArgs, 'country'> & { country: Country }
>

const initialState: TalentRegistrationPayloadState = {
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  email_confirmation: undefined,
  birth_date: undefined,

  city: undefined,
  country: undefined,
  phone: undefined,
  street: undefined,
  house: undefined,
  postcode: undefined,

  specializations: [],
  preferred_location_types: [],

  coc_number: undefined,
  legal_name: undefined,
  is_freelancer: false,
  vat_number: undefined,
}

const noBirthdayDataForActiveStep = (activeStep: StudentRegistrationSteps, birthDate?: string) =>
  activeStep !== StudentRegistrationSteps.birthDayLess16 &&
  activeStep !== StudentRegistrationSteps.birthDay &&
  !birthDate

const useStudentStepsRegistrationGuard = (
  activeStep: StudentRegistrationSteps,
  registrationPayload: TalentRegistrationPayloadState,
) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (noBirthdayDataForActiveStep(activeStep, registrationPayload.birth_date)) {
      navigate('/registration/talent')
    }
  }, [activeStep, registrationPayload])
}

export const StudentRegistrationPage: FC<StudentRegistrationPageProps> = ({
  activeStep = StudentRegistrationSteps.birthDay,
  showBackButton = true,
}) => {
  const [registrationPayload, setRegistrationPayload] =
    useState<TalentRegistrationPayloadState>(initialState)

  useStudentStepsRegistrationGuard(activeStep, registrationPayload)

  const navigate = useNavigate()
  const [studentPersonalInfoForm] = Form.useForm()
  const [studentInterestsForm] = Form.useForm()
  const [category, setCategory] = useState<JobCategory>()

  useValidationForm(studentPersonalInfoForm)
  useValidationForm(studentInterestsForm)

  const [registerStudentRequest, { loading }] = useRegisterStudentMutation()

  const [isEmailValidQuery, { loading: validatingEmail }] = useIsValidEmailLazyQuery({
    notifyOnNetworkStatusChange: true,
  })

  const [isOver18, setIsOver18] = useState(false)

  const imageContext = useContext(OnboardingImageContext)

  useEffect(() => {
    imageContext?.setImage(ImageTypeEnum.studentBirthday)
    return () => {
      imageContext?.setImage(ImageTypeEnum.default)
    }
  }, [])

  useEffect(() => {
    if (noBirthdayDataForActiveStep(activeStep, registrationPayload.birth_date)) {
      navigate('/registration/talent')
    }
  }, [activeStep, registrationPayload])

  const onBirthDayFormFinishHandler = useCallback(
    (data: BirthDayInputType) => {
      const { over16, over18 } = underAgeValidate(data.birth_date)

      if (!over16) {
        navigate('/registration/talent/too-young')
      } else {
        setRegistrationPayload((prevState) => ({
          ...prevState,
          birth_date: formatDateToBackendFormat(data.birth_date, ''),
        }))
        setIsOver18(over18)
        navigate('/registration/talent/personal-info')
      }
    },
    [registrationPayload],
  )

  const onLeaveSiteClickedHandler = useCallback(() => {
    navigate('/')
  }, [])

  const onPersonalInfoFormSubmitHandler = useCallback((data: StudentPersonalInfoFormData) => {
    isEmailValidQuery({
      variables: {
        email: data.email,
      },
      onCompleted: (validationResult) => {
        if (validationResult?.isValidEmail) {
          setRegistrationPayload((prevState) => ({
            ...prevState,
            ...data,
          }))
        }
      },
    })
      .then(({ data: validationResult }) => {
        if (validationResult?.isValidEmail) {
          navigate('/registration/talent/interests')
        }
        return false
      })
      .catch(() => {})
  }, [])

  const onInterestsFormSubmitHandler = useCallback(
    (data: StudentInterestsFormData) => {
      setCategory(data?.job_category)

      navigate('/registration/talent/categories')
    },
    [registrationPayload],
  )

  const onCategoriesFormSubmitHandler = useCallback(
    (data: StudentInterestsFormData) => {
      const omittedRegistrationPayload = !registrationPayload.is_freelancer
        ? omit(registrationPayload, [
            'coc_number',
            'vat_number',
            'legal_name',
            'street',
            'house',
            'postcode',
          ])
        : registrationPayload

      registerStudentRequest({
        variables: {
          ...omittedRegistrationPayload,
          ...{ preferred_location_types: data.preferred_location_types },
          ...{ country: registrationPayload.country?.id as string },
          ...{
            specializations: data.specializations?.map((specialization) => ({
              id: specialization.id || specialization.name,
              level: specialization.levels.name,
            })),
          },
        } as MutationRegisterStudentArgs,
        onCompleted: (result) => {
          if (result.registerStudent) {
            navigate('/registration/talent/success')
          }
        },
      })
    },
    [registrationPayload],
  )

  const renderStep = useMemo(() => {
    switch (activeStep) {
      case StudentRegistrationSteps.birthDay:
        imageContext?.setImage(ImageTypeEnum.studentBirthday)
        return (
          <BirthDayScreen
            initialValues={{
              birth_date: registrationPayload.birth_date
                ? new Date(registrationPayload.birth_date)
                : undefined,
            }}
            onFinish={onBirthDayFormFinishHandler}
          />
        )
      case StudentRegistrationSteps.birthDayLess16:
        return <ToYoungScreen onLeaveSiteClicked={onLeaveSiteClickedHandler} />
      case StudentRegistrationSteps.personalInfo:
        imageContext?.setImage(ImageTypeEnum.studentInfo)
        return (
          <StudentPersonalInfoScreen
            loading={validatingEmail}
            isOver18={isOver18}
            formProps={{
              form: studentPersonalInfoForm,
              onFinish: onPersonalInfoFormSubmitHandler,
              initialValues: registrationPayload,
            }}
          />
        )
      case StudentRegistrationSteps.interests:
        imageContext?.setImage(ImageTypeEnum.studentInterests)
        return (
          <StudentInterestsScreen
            name={registrationPayload.first_name as string}
            formProps={{
              form: studentInterestsForm,
              onFinish: onInterestsFormSubmitHandler,
              initialValues: registrationPayload,
            }}
          />
        )
      case StudentRegistrationSteps.categories:
        imageContext?.setImage(ImageTypeEnum.studentInterests)
        return (
          <StudentCategoriesScreen
            loading={loading}
            name={registrationPayload.first_name as string}
            category={category as JobCategory}
            formProps={{
              form: studentInterestsForm,
              onFinish: onCategoriesFormSubmitHandler,
              initialValues: registrationPayload,
            }}
          />
        )
      case StudentRegistrationSteps.success:
        return <RegistrationCompletedScreen email={registrationPayload.email as string} />
      default:
        return <BirthDayScreen />
    }
  }, [registrationPayload, navigate, validatingEmail])

  return (
    <Row gutter={[24, 24]}>
      {showBackButton && (
        <Col span={24}>
          <GoBackLink />
        </Col>
      )}
      <Col span={24}>{renderStep}</Col>
    </Row>
  )
}
