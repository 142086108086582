import React from 'react'
import ReactDOM from 'react-dom/client'

import '@/services'
// import './sentry'
import './index.less'

import App from './App'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
