import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import { useAuthToken } from '@/providers/AuthenticationProvider/hooks/useAuthToken'

export const useLogout = () => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const [, setAuthToken, removeAuthToken, , removeAuthAdminToken, adminToken] = useAuthToken()

  return async () => {
    await client.clearStore()

    removeAuthToken()

    if (adminToken) {
      setAuthToken(adminToken)
      removeAuthAdminToken()
    } else {
      navigate('/')
    }
  }
}
