import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '@kickjobs/uikit'
import { Dropdown } from 'antd'

import { Logo } from '@/components/Logo'
import { UserAvatar } from '@/containers/avatar/UserAvatar'
import { ChatHeaderMenuButton } from '@/containers/Buttons/HeaderMenu/ChatHeaderMenuButton'
import { NotificationHeaderMenuButton } from '@/containers/Buttons/HeaderMenu/NotificationHeaderMenuButton'
import { useIsMobileDevice } from '@/hooks/useIsMobileDevice'
import { UserActionsDropDown } from '@/layouts/AuthenticatedLayout/Actions/components/UserActionsDropDown'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

import styles from './styles.module.less'

export const useTranslatedSearchLayoutStructure = () => {
  const { t } = useTranslation()
  const { isCompany, isStudent, isAdmin } = useRbacShortcuts()
  const navigate = useNavigate()
  const isMobileDevice = useIsMobileDevice()

  let navigation

  if (isCompany) {
    navigation = {
      onLogoClickHandler: () => navigate('/'),
      items: [
        {
          label: t('publicMarketplace.company.navigation.marketplace'),
          to: '/marketplace?type=projects',
        },
        {
          label: t('publicMarketplace.company.navigation.vacancies'),
          to: '/marketplace?type=students',
          separator: true,
        },
        {
          label: t('publicMarketplace.company.navigation.faq'),
          to: 'https://kickjobs.com/en/customer-service-freelancer/',
        },
      ],
      children: (
        <div className='flex flex-wrap gap-2'>
          <Button className='w-7/12 sm:w-auto' onClick={() => navigate('/vacancies/create')}>
            {t('common.placeJob')}
          </Button>
          <>
            <NotificationHeaderMenuButton />

            <ChatHeaderMenuButton />
          </>

          <div className='sm:block hidden mx-2 pt-2 pb-2 border-r border-gray-75'></div>

          <div className='flex items-center'>
            {(isMobileDevice && <UserActionsDropDown className={styles.userActions} />) || (
              <Dropdown
                arrow
                overlay={<UserActionsDropDown />}
                trigger={['click']}
                placement='bottomLeft'
                data-cy='cy-user-actions-dropdown'
              >
                <div>
                  <UserAvatar size={44} />
                </div>
              </Dropdown>
            )}
          </div>
        </div>
      ),
    }
  } else if (isStudent) {
    navigation = {
      onLogoClickHandler: () => navigate('/'),
      items: [
        { label: t('publicMarketplace.student.navigation.marketplace'), to: '/jobs' },
        {
          label: t('publicMarketplace.student.navigation.faq'),
          to: 'https://kickjobs.com/en/customer-service-freelancer/',
        },
      ],
      children: (
        <div className='flex flex-wrap gap-2'>
          <Button className='w-7/12 sm:w-auto' onClick={() => navigate('/projects/create')}>
            {t('marketplace.project.create')}
          </Button>
          <>
            <NotificationHeaderMenuButton />

            <ChatHeaderMenuButton />
          </>

          <div className='sm:block hidden mx-2 pt-2 pb-2 border-r border-gray-75'></div>

          <div className='flex items-center'>
            <div className='flex items-center'>
              {(isMobileDevice && <UserActionsDropDown className={styles.userActions} />) || (
                <Dropdown
                  arrow
                  overlay={<UserActionsDropDown />}
                  trigger={['click']}
                  placement='bottomLeft'
                  data-cy='cy-user-actions-dropdown'
                >
                  <div>
                    <UserAvatar size={44} />
                  </div>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      ),
    }
  } else if (isAdmin) {
    navigation = {
      onLogoClickHandler: () => navigate('/'),
      items: [
        { label: t('publicMarketplace.visitor.navigation.marketplace'), to: '/marketplace' },
        {
          label: t('publicMarketplace.visitor.navigation.vacancies'),
          to: '/jobs',
          separator: true,
        },
        {
          label: t('publicMarketplace.visitor.navigation.faq'),
          to: 'https://kickjobs.com/en/contact/',
        },
      ],
      children: <></>,
    }
  } else {
    navigation = {
      onLogoClickHandler: () => navigate('/'),
      items: [
        { label: t('publicMarketplace.visitor.navigation.marketplace'), to: '/marketplace' },
        {
          label: t('publicMarketplace.visitor.navigation.vacancies'),
          to: '/jobs',
          separator: true,
        },
        {
          label: t('publicMarketplace.visitor.navigation.faq'),
          to: 'https://kickjobs.com/en/contact/',
        },
      ],
      children: (
        <div className='flex gap-2 justify-end'>
          <Link to='/'>
            <Button className='bg-white border border-orange-100 text-dark font-medium'>
              {t('publicMarketplace.visitor.navigation.loginButton')}
            </Button>
          </Link>
        </div>
      ),
    }
  }

  const footer = [
    {
      items: (
        <div className='flex flex-col gap-4'>
          <Link to='/'>
            <Logo bright />
          </Link>
          <span className='text-blue-150 font-opensans text-base'>
            {t('publicMarketplace.footer.feedback')}
          </span>
        </div>
      ),
    },
    {
      title: t('publicMarketplace.footer.companies'),
      items: [
        { label: t('publicMarketplace.footer.marketplace'), to: '/marketplace' },
        {
          label: t('publicMarketplace.footer.howItWorks'),
          to: 'https://kickjobs.com/hoe-werkt-het/',
        },
        { label: t('publicMarketplace.footer.rates'), to: 'https://kickjobs.com/tarieven/' },
      ],
    },
    {
      title: t('publicMarketplace.footer.freelancers'),
      items: [
        { label: t('publicMarketplace.footer.jobs'), to: '/jobs' },
        { label: t('publicMarketplace.footer.createProject'), to: '/projects/create' },
        {
          label: t('publicMarketplace.footer.howItWorks'),
          to: 'https://kickjobs.com/en/freelancers-how-it-works/',
        },
        {
          label: t('publicMarketplace.footer.faq'),
          to: 'https://kickjobs.com/en/customer-service-contractor/',
        },
      ],
    },
    {
      title: t('publicMarketplace.footer.kickjobs'),
      items: [
        {
          label: t('publicMarketplace.footer.remote'),
          to: t('publicMarketplace.footer.remoteLink'),
        },
        { label: t('publicMarketplace.footer.about'), to: 'https://kickjobs.com/en/about-us/' },
        {
          label: t('publicMarketplace.footer.customerService'),
          to: 'https://kickjobs.com/contact/',
        },
        {
          label: t('publicMarketplace.footer.agencies'),
          to: t('publicMarketplace.footer.agenciesLink'),
        },
        { label: t('publicMarketplace.footer.blog'), to: 'https://kickjobs.com/blog/' },
      ],
    },
  ]

  const footerBottom = [
    {
      label: t('publicMarketplace.bottomFooter.clientTerms'),
      to: t('publicMarketplace.bottomFooter.clientTermsLink'),
    },
    {
      label: t('publicMarketplace.bottomFooter.freelancerTerms'),
      to: t('publicMarketplace.bottomFooter.freelancerTermsLink'),
    },
    {
      label: t('publicMarketplace.bottomFooter.privacy'),
      to: t('publicMarketplace.bottomFooter.privacyLink'),
    },
    {
      label: t('publicMarketplace.bottomFooter.cookie'),
      to: t('publicMarketplace.bottomFooter.cookieLink'),
    },
    {
      label: t('publicMarketplace.bottomFooter.assignmentAgreement'),
      to: t('publicMarketplace.bottomFooter.assignmentAgreementLink'),
    },
  ]

  return { navigation, footer, footerBottom }
}

export default useTranslatedSearchLayoutStructure
