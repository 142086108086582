import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormInstance } from 'antd/es/form/hooks/useForm'

const useValidationForm = (form: FormInstance) => {
  const { i18n } = useTranslation()

  const handleLanguageChanged = useCallback(() => {
    // Get the keys of the form object as an array
    const fieldKeys = Object.keys(form.getFieldsValue())

    // Filter the keys to only include those with errors
    const keysWithErrors = fieldKeys.filter((key) => form.getFieldError(key).length)

    // Validate the fields with errors
    form.validateFields(keysWithErrors)
  }, [form, i18n])

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged)

    return () => {
      i18n.off('languageChanged', handleLanguageChanged)
    }
  }, [i18n.language])
}

export default useValidationForm
