import React, {
  Children,
  cloneElement,
  FC,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import clsx from 'classnames'

import { ChipItem } from '@/components/Chips/ChipItem'
import { SimpleChipItem } from '@/components/Chips/SimpleChipItem'
import { ChipItemProps } from '@/components/Chips/types'

import styles from './styles.module.less'

interface IChipItem {
  name: string
  value: string
  children?: ReactElement | string
}

export interface ChipsProps {
  children:
    | ReactElement<PropsWithChildren<ChipItemProps>>[]
    | ReactElement<PropsWithChildren<ChipItemProps>>
  defaultValues?: IChipItem[]
  onChange?: (chips: IChipItem[] | IChipItem) => void
  onSelectChip?: (chip: IChipItem) => void
  onRemoveChip?: (event: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => void
  type?: string
  className?: string
}

interface ChipsComposition {
  ChipItem: FunctionComponent<ChipItemProps>
  SimpleChipItem: FunctionComponent<ChipItemProps>
}

export const Chips: FC<ChipsProps> & ChipsComposition = ({
  children,
  type = 'single',
  defaultValues,
  onChange,
  onRemoveChip,
  onSelectChip,
  className,
}) => {
  const [selectedChips, setSelectedChips] = useState<IChipItem[]>([])
  const [selectedChip, setSelectedChip] = useState<IChipItem>()

  useEffect(() => {
    if (defaultValues && defaultValues[0]?.name) {
      if (type === 'single') {
        setSelectedChip(defaultValues)
      } else {
        setSelectedChips(defaultValues)
      }
    } else {
      setSelectedChips([])
    }
  }, [defaultValues])

  return (
    <div className={clsx(className, styles.chip)}>
      {Children.map(children, (child) => {
        const item = child

        if (item.type === ChipItem || item.type === SimpleChipItem) {
          if (type === 'single') {
            const isActive = selectedChip?.name === item.props.name

            const onSelect = () => {
              setSelectedChip(item.props)
              onSelectChip?.(item.props)
              onChange?.(item.props)
            }

            const onRemoveSelection = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
              event.stopPropagation()
              setSelectedChip(undefined)
              onChange?.([])
            }

            const classnames = clsx(item.props.className, styles.chip__item, {
              [styles.active]: isActive,
            })

            return cloneElement(item, {
              isActive,
              onSelect,
              onRemoveSelection,
              className: classnames,
            })
          }

          if (type === 'multiple') {
            const isActive = !!selectedChips.find((chip) => chip?.name === item.props.name)

            const onSelect = () => {
              const chips = [...selectedChips, item.props]

              onChange?.(chips.map((chip) => chip.name))

              onSelectChip?.(item.props)
            }

            const onRemoveSelection = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
              event.stopPropagation()

              const chips = selectedChips.filter((chip) => chip?.name !== item.props.name)

              setSelectedChips(chips)

              onChange?.(chips.map((chip) => chip.name))

              onRemoveChip?.(event, item.props.name)
            }

            const classnames = clsx(item.props.className, styles.chip__item, {
              [styles.active]: isActive,
            })

            return cloneElement(item, {
              isActive,
              onSelect,
              onRemoveSelection,
              className: classnames,
            })
          }
        }

        return null
      })}
    </div>
  )
}

Chips.SimpleChipItem = SimpleChipItem
Chips.ChipItem = ChipItem
