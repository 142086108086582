import React, { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Typography } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import isFuture from 'date-fns/isFuture'

import { Button } from '@/components/Button'
import { DateField } from '@/forms/fields'

export type BirthDayInputType = { birth_date: Date }
export type BirthDayScreenType = ComponentProps<typeof Form<BirthDayInputType>>

export const BirthDayScreen: FC<BirthDayScreenType> = ({ onFinish, ...props }) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()

  return (
    <Form onFinish={onFinish} {...props}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Typography.Title level={breakpoint.xs ? 4 : 3}>
            {t('auth.register.talent.whenIsYourBirthday')}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <DateField
            name='birth_date'
            placeholder={`${t('common.select')} ${t('common.birth_date')} ${t(
              'common.example_date',
            )}`}
            disabledDate={(current) => isFuture(current)}
          />
        </Col>
        <Col span={24}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Button
                disabled={!getFieldValue('birth_date')}
                type='primary'
                block
                htmlType='submit'
              >
                {t('navigation.continue')}
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
