import React, { ComponentType, FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import { EMAIL_TOKEN } from '@/utils/constants/reserved_search_params'

export const withTokenEmailChecker =
  <P extends { emailToken: string }>(Page: ComponentType<P>) =>
  <T extends object>(WrappedComponent: ComponentType<T>) => {
    const Component: FC<T> = (props) => {
      const [params] = useSearchParams()

      const token = params.get(EMAIL_TOKEN) as string

      if (token) {
        const pageProps = { emailToken: token } as P

        return <Page {...pageProps} />
      }

      return <WrappedComponent {...props} />
    }

    return Component
  }
