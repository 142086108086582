import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'
// eslint-disable-next-line import/no-extraneous-dependencies
import { InputRef } from 'rc-input/lib/interface'

import { detectAutofill } from '@/utils/detectAutoFill'

import { FloatingLabel, InputLabelProps } from '../FloatingLabel'

type TextInputProps = {
  name: React.ReactNode
}

export type FormItemProps = Parameters<(typeof Form)['Item']>[0]

export const FloatingLabelInput = ({
  name,
  labelText,
  active,
  children,
  ...rest
}: TextInputProps & InputLabelProps & Omit<FormItemProps, 'label'>) => {
  const ref = useRef<InputRef>()
  const [isActive, setIsActive] = useState<boolean>(active || false)

  if (!name) {
    throw new Error('Name is required using FloatingLabelInput Component')
  }

  useEffect(() => {
    if (ref?.current?.input) {
      detectAutofill(ref.current.input)
        .then((isAutoFilled) => {
          if (isAutoFilled) {
            setIsActive(true)
          }

          return null
        })
    }
  }, [])

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => {
        // TODO: I need refactor this callback in case when name is InternalNamePath
        if (typeof name === 'string') {
          return prevValues[name] !== currentValues[name]
        }

        return false
      }}
    >
      {({ getFieldValue }) => (
        <FloatingLabel active={isActive} labelText={labelText} value={getFieldValue(name)}>
          <Form.Item name={name} {...rest}>
            {children}
          </Form.Item>
        </FloatingLabel>
      )}
    </Form.Item>
  )
}
