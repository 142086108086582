import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import clsx from 'classnames'

import { Link } from '@/components/Link'
import { useLogout } from '@/providers/AuthenticationProvider/hooks/useLogout'

import styles from './styles.module.less'

interface UserActionsDropDownProps {
  className?: string
}

export const UserActionsDropDown: FC<UserActionsDropDownProps> = ({ className }) => {
  const { t } = useTranslation()
  const logout = useLogout()

  return (
    <div className={clsx(className, styles.container)}>
      <Row gutter={[16, 26]}>
        <Col xs={24}>
          <Link to='/profile' text={t('navigation.profile')} icon='UserSvg' />
        </Col>
        <Col xs={24}>
          <Link
            to='/'
            text={t('auth.logout')}
            icon='SignOutSvg'
            onClick={logout}
            data-cy='cy-auth.logout'
          />
        </Col>
      </Row>
    </div>
  )
}
