import { FC, PropsWithChildren } from 'react'
import { Typography } from 'antd'
import clsx from 'classnames'

import styles from './styles.module.less'

type TextProps = {
  small?: boolean
  italic?: boolean
}

export const Text: FC<PropsWithChildren<TextProps>> = ({ children, small, italic }) => (
  <Typography.Text
    className={clsx(styles.text, {
      [styles.text__small]: small,
      [styles.text__italic]: italic,
    })}
  >
    {children}
  </Typography.Text>
)
