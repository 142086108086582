import { ChangeEventHandler, FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'

import {
  getCountryCodeForRegion,
  getCountryCodeFromPhoneNumber,
  getNationalPhoneNumber,
} from '@/shared/phonenumber'
import { CountrySelect, PhoneInput } from '@/shared/phonenumber/ui'

import styles from './style.module.less'

export const PhoneNumber: FC<{
  onChange?: (value: string | undefined) => void
  defaultCountryCode?: string
  value?: string
}> = ({ onChange, defaultCountryCode, value }) => {
  const rowRef = useRef(null)

  const [t] = useTranslation()

  const [region, setRegion] = useState<string | undefined>(() => {
    if (value) {
      return getCountryCodeFromPhoneNumber(value)
    }

    return undefined
  })
  const [number, setNumber] = useState<string | undefined>(
    value ? getNationalPhoneNumber(value) : undefined,
  )

  // eslint-disable-next-line no-nested-ternary
  const countryCodeForRegion = region
    ? getCountryCodeForRegion(region)
    : defaultCountryCode
    ? getCountryCodeForRegion(defaultCountryCode)
    : undefined

  const onRegionChangeHandler = (_value: unknown): void => {
    setRegion(_value as string)
    setNumber(undefined)
    onChange?.(undefined)
  }

  const onPhoneNumberChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    setNumber(event.target.value)

    if (!event.target.value) {
      onChange?.(undefined)
    } else {
      onChange?.(
        `${countryCodeForRegion ? `+${countryCodeForRegion}` : ''}${event.target.value || ''}`,
      )
    }
  }

  return (
    <Row wrap={false} ref={rowRef} className={styles.country_select__row}>
      <Col flex='none'>
        <CountrySelect
          value={region}
          defaultValue={defaultCountryCode}
          className={styles.country_select}
          onChange={onRegionChangeHandler}
          getDropdownContainer={() => rowRef.current as unknown as HTMLElement}
        />
      </Col>
      <Col flex='auto'>
        <PhoneInput
          countryCode={countryCodeForRegion}
          className={styles.phone_input}
          placeholder={t('settings.user.phone_number') || ''}
          pattern='[0-9]*'
          value={number}
          onChange={onPhoneNumberChangeHandler}
        />
      </Col>
    </Row>
  )
}
