import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ItemProps } from '@/components/LabelsView/ItemProps'

const Language: FC<ItemProps> = ({ value }) => {
  const [t] = useTranslation()

  return <>{t(`languages.${value}`)}</>
}

export default Language
