import { useMemo } from 'react'

import {
  Notification,
  TalentSidebarMenuCallsToActionsFragment,
  useReadNotificationMutation,
  useTalentCallsToActionQuery,
} from '@/apollo/generated/graphql'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

const useCallToActionsTalentNotifications = () => {
  const { isStudent } = useRbacShortcuts()

  const { data, loading, refetch } = useTalentCallsToActionQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !isStudent,
  })

  const [readNotificationRequest] = useReadNotificationMutation({
    onCompleted: () => {
      refetch()
    },
  })

  const readNotification = (notification: Notification) =>
    readNotificationRequest({
      variables: {
        notification: notification.id,
      },
    })

  const callsToActions = useMemo(
    () => (data?.me as TalentSidebarMenuCallsToActionsFragment)?.notificationsCallsToAction,
    [(data?.me as TalentSidebarMenuCallsToActionsFragment)?.notificationsCallsToAction],
  )

  const dashboardNotificationsCount = callsToActions?.dashboard?.callsToActionCount
  const jobsNotificationsCount = callsToActions?.jobs?.callsToActionCount
  const documentsNotificationsCount = callsToActions?.documents?.callsToActionCount
  const reviewsNotificationsCount = callsToActions?.reviews?.callsToActionCount
  const profileNotificationsCount = callsToActions?.profile?.callsToActionCount

  return {
    loading,
    callsToActions,
    dashboardNotificationsCount,
    jobsNotificationsCount,
    documentsNotificationsCount,
    reviewsNotificationsCount,
    profileNotificationsCount,
    readNotification,
    refetch,
  }
}

export default useCallToActionsTalentNotifications
