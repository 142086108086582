import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'

type Subscribe = (fieldName: string) => (...rest: any[]) => void
type StoreField = (fieldName: string) => unknown

type FieldsEventsSubscriberContextType = {
  subscribe: Subscribe
  storeField: StoreField
}

type Children = (helpers: FieldsEventsSubscriberContextType) => ReactNode

type FieldsEvents = {
  [fieldName: string]: unknown
}

const FieldsEventsSubscriberContext = createContext<FieldsEventsSubscriberContextType | null>(null)

export const FieldsEventsSubscriberProvider: FC<{ children: Children | ReactNode }> = ({
  children,
}) => {
  const [fieldsState, setFieldsEvents] = useState<FieldsEvents>({})

  const subscribe: Subscribe = (fieldName) => (value) => {
    const clonedFieldsState = { ...fieldsState }

    clonedFieldsState[fieldName] = value

    setFieldsEvents(clonedFieldsState)
  }

  const storeField = (fieldName: string) => fieldsState[fieldName]

  useEffect(() => {
    setFieldsEvents({})
  }, [])

  return (
    <FieldsEventsSubscriberContext.Provider value={{ subscribe, storeField }}>
      {typeof children === 'function' ? children({ subscribe, storeField }) : children}
    </FieldsEventsSubscriberContext.Provider>
  )
}

export const useFieldsEventsSubscriber = () => {
  const context = useContext(FieldsEventsSubscriberContext)

  if (!context) {
    throw new Error(
      'useFieldsEventsSubscriber can using only with FieldsEventsSubscriberProvider calling above',
    )
  }

  return context
}
