import { useLocation, useNavigate } from "react-router-dom"

import { Student, useStudentProfileQuery } from "@/apollo/generated/graphql";
import { StudentProjectCreatedSuccessfullyScreen } from "@/screens/StudentScreens/StudentProjectCreatedSuccessfullyScreen";

export const StudentProjectCreatedSuccessfullyPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data: dataMe, loading: loadingMe } = useStudentProfileQuery({
        fetchPolicy: 'network-only',
      })
    
      const isPrivateProfile =
          !(dataMe?.me as Student)?.public_profile_issues?.marked_as_public && !loadingMe

    const onAgree = () => {
        navigate(isPrivateProfile ? `/projects/${location.state.id}` : `/marketplace/${location.state.publicId}/${location.state.projectId}`)
    }

    return <StudentProjectCreatedSuccessfullyScreen onAgree={onAgree} />

}