import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { To } from 'react-router-dom'

import { CallToActionBadge } from '@/components/CallToActions/CallToActionBadge'
import { Icon } from '@/components/Icons'
import { MenuItemProps } from '@/components/Menu/MenuItem'

export interface MenuItem extends Omit<MenuItemProps, 'active' | 'onClick' | 'badgeCounter'> {
  name: string
  isExternal?: boolean
  badgeCounter?: number | ReactNode
  to: To
}

export type MenuItems = (MenuItem | [MenuItem, [MenuItem, ...MenuItem[]]])[]

type GetMenuItemByName = (name: string) => MenuItem

export const useExistingMenuItems = (): [MenuItems, GetMenuItemByName] => {
  const { t } = useTranslation()

  const items: MenuItems = [
    {
      name: 'home',
      to: '/',
      text: 'Home',
      icon: <Icon name='HomeSvg' size='lg' />,
    },
    {
      name: 'home-vacancies',
      to: '/',
      text: t('navigation.jobs'),
      icon: <Icon name='JobsSvg' style={{ color: 'yellow' }} size='lg' />,
      badgeCounter: <CallToActionBadge type='jobs' />,
    },
    {
      name: 'reviews',
      to: '/reviews',
      text: 'Reviews',
      icon: <Icon name='ReviewSvg' size='lg' />,
      badgeCounter: <CallToActionBadge type='reviews' />,
    },
    {
      name: 'marketplace',
      to: '/projects',
      text: t('navigation.marketplace'),
      icon: <Icon name='MarketplaceSvg' size='lg' />,
    },
    {
      name: 'tab-marketplace-search',
      to: {
        pathname: '/marketplace',
      },
      text: t('marketplace.tabs.search'),
      icon: <Icon name='SearchSvg' size='lg' />,
    },
    {
      name: 'tab-marketplace-projects',
      to: {
        pathname: '/projects',
        search: '?tab=projects',
      },
      text: t('marketplace.tabs.projects'),
      icon: <Icon name='LayersSvg' size='lg' />,
    },
    {
      name: 'tab-marketplace-proposals',
      to: {
        pathname: '/projects',
        search: '?tab=proposals',
      },
      text: t('marketplace.tabs.proposals'),
      icon: <Icon name='DocumentSvg' size='lg' />,
    },
    {
      name: 'tab-marketplace-in_progress',
      to: {
        pathname: '/projects',
        search: '?tab=in_progress',
      },
      text: t('marketplace.tabs.in_progress'),
      icon: <Icon name='TimeSvg' size='lg' />,
    },
    {
      name: 'tab-marketplace-archive',
      to: {
        pathname: '/projects',
        search: '?tab=archive',
      },
      text: t('marketplace.tabs.archive'),
      icon: <Icon name='ArchiveSvg' size='lg' />,
    },
    {
      name: 'companies',
      to: '/companies',
      text: t('navigation.companies'),
      icon: <Icon name='JobsSvg' size='lg' />,
    },
    {
      name: 'students',
      to: '/students',
      text: t('navigation.students'),
      icon: <Icon name='ResumeSvg' size='lg' />,
    },
    {
      name: 'documents',
      to: '/documents',
      text: t('navigation.documents'),
      icon: <Icon name='DocumentSvg' size='lg' />,
      badgeCounter: <CallToActionBadge type='documents' />,
    },
    {
      name: 'invoices-documents',
      to: {
        pathname: '/documents',
        search: '?tab=invoices',
      },
      text: t('invoices.tabs.invoices'),
      icon: <Icon name='DocumentSvg' size='lg' />,
    },
    {
      name: 'agreements-documents',
      to: {
        pathname: '/documents',
        search: '?tab=agreements',
      },
      text: t('invoices.tabs.agreements'),
      icon: <Icon name='DocumentSvg' size='lg' />,
    },
    {
      name: 'annual-overviews-documents',
      to: {
        pathname: '/documents',
        search: '?tab=annual-overview',
      },
      text: t('invoices.tabs.annualOverviews'),
      icon: <Icon name='DocumentSvg' size='lg' />,
    },
    {
      name: 'documents',
      to: '/documents',
      text: t('navigation.documents'),
      icon: <Icon name='DocumentSvg' size='lg' />,
      badgeCounter: <CallToActionBadge type='documents' />,
    },
    {
      name: 'settings',
      to: '/settings',
      text: t('navigation.settings'),
      icon: <Icon name='SettingsSvg' size='lg' />,
    },
    {
      name: 'profile',
      to: '/profile',
      text: 'Profile',
      icon: <Icon name='InvoicesSvg' size='lg' />,
      badgeCounter: <CallToActionBadge type='profile' />,
    },
    // {
    //   name: 'jobs',
    //   to: '/jobs',
    //   text: t('navigation.jobs'),
    //   icon: <Icon name='JobsSvg' size='lg' />,
    // },
    {
      name: 'tab-vacancies',
      to: {
        pathname: '/',
        search: '?tab=vacancies',
      },
      text: t('jobs.tabs.jobs'),
      icon: <Icon name='JobsSvg' size='lg' />,
    },
    {
      name: 'tab-applications',
      to: {
        pathname: '/',
        search: '?tab=applications',
      },
      text: t('jobs.tabs.applications'),
      icon: <Icon name='UserSvg' size='lg' />,
    },
    {
      name: 'tab-in-progress',
      to: {
        pathname: '/',
        search: '?tab=in_progress',
      },
      text: t('jobs.tabs.in_progress'),
      icon: <Icon name='DocumentSvg' size='lg' />,
    },
    {
      name: 'tab-archive',
      to: {
        pathname: '/',
        search: '?tab=archive',
      },
      text: t('jobs.tabs.archive'),
      icon: <Icon name='TimeSvg' size='lg' />,
    },
    {
      name: 'tab-assignments-applications',
      to: {
        pathname: '/assignments',
        search: '?tab=applications',
      },
      text: t('jobs.tabs.applications'),
      icon: <Icon name='UserSvg' size='lg' />,
    },
    {
      name: 'tab-assignments-in-progress',
      to: {
        pathname: '/assignments',
        search: '?tab=in_progress',
      },
      text: t('jobs.tabs.in_progress'),
      icon: <Icon name='DocumentSvg' size='lg' />,
    },
    {
      name: 'tab-assignments-archive',
      to: {
        pathname: '/assignments',
        search: '?tab=archive',
      },
      text: t('jobs.tabs.archive'),
      icon: <Icon name='TimeSvg' size='lg' />,
    },
    // {
    //   name: 'create-job',
    //   to: '/jobs/create',
    //   text: t('jobs.placeAJob'),
    //   icon: <Icon name='PlusSvg' size='lg' />,
    // },
    {
      name: 'create-vacancy',
      to: '/vacancies/create',
      text: t('jobs.placeJob'),
      icon: <Icon name='PlusSvg' size='lg' />,
    },
    {
      name: 'vacancies',
      to: '/vacancies',
      text: t('navigation.jobs'),
      icon: <Icon name='JobsSvg' style={{ color: 'yellow' }} size='lg' />,
    },
    {
      name: 'assignments',
      to: '/assignments',
      text: t('navigation.jobs'),
      icon: <Icon name='JobsSvg' style={{ color: 'yellow' }} size='lg' />,
      badgeCounter: <CallToActionBadge type='jobs' />,
    },
    {
      name: 'search-job',
      to: '/jobs',
      text: t('dashboard.student.search_jobs'),
      icon: <Icon name='SearchSvg' size='lg' />,
      isExternal: true,
    },
  ]

  const getMenuItemByName = (name: string) => {
    const item = items.flat(2).find(({ name: itemName }) => itemName === name)

    if (typeof item !== 'undefined') return item

    throw new Error(`menu item with name ${name} does not exists`)
  }

  return [items, getMenuItemByName]
}
