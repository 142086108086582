import React from 'react'

import { CompanyAdditionalInformationPage } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-additional-information-page'
import { CompanyBaseInformationPage } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-base-information-page'
import { CompanyOnboardingContainer } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-onboarding-container'
import { CompanyRegistrationCompletePage } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-registration-complete-page'
import { ContactPersonPage } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/contact-person-page'

export const CompanyOnboardingRoutes = {
  path: 'company',
  element: <CompanyOnboardingContainer />,
  children: [
    {
      index: true,
      element: (
        <CompanyBaseInformationPage continueWithRoute='/registration/company/additional-information' />
      ),
    },
    {
      path: 'contact-person',
      element: (
        <ContactPersonPage continueWithRoute={'/registration/company/registration-complete'} />
      ),
    },
    {
      path: 'additional-information',
      element: (
        <CompanyAdditionalInformationPage continueWithRoute='/registration/company/contact-person' />
      ),
    },
    {
      path: 'registration-complete',
      element: <CompanyRegistrationCompletePage />,
    },
  ],
}
