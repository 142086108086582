import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Radio, Row, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import nl from 'date-fns/locale/nl'

import { CompanyByCocNumberDetails, Country } from '@/apollo/generated/graphql'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import Noob from '@/components/Noob'
import { Notification } from '@/components/Notification'
import { VatNumberFormItemField } from '@/features/form-fields'
import { CountryFormItemField } from '@/features/form-fields/country-field'
import { useValidationRules } from '@/forms/useValidationRules'
import capitalize from '@/helpers/capitalize'
import capitalizeEveryWord from '@/helpers/capitalizeEveryWord'
import { CompanyKvkSearchContainer } from '@/screens/AuthenticationScreens/CompanyRegistrationScreens/components/CompanyKvkSearch'
import { FormItemPhone } from '@/widgets/form-item-phone/ui'

export interface StudentPersonalInfoFormData {
  first_name: string
  last_name: string
  email: string
  email_confirmation: string

  is_freelancer: boolean
  coc_number?: string
  vat_number?: string
  legal_name?: string

  street?: string
  city: string
  country: Country
  house?: string
  postcode?: string
}

export type StudentPersonalInfoFormType = React.ComponentProps<
  typeof Form<StudentPersonalInfoFormData>
>

export interface StudentPersonalInfoFormProps {
  formProps?: Partial<StudentPersonalInfoFormType>
  isOver18: boolean
  loading?: boolean
}

export const StudentPersonalInfoForm: FC<StudentPersonalInfoFormProps> = ({
  formProps,
  isOver18,
  loading,
}) => {
  const { t } = useTranslation()
  const [form] = useForm<StudentPersonalInfoFormData>()
  const { requiredWithWhitespace, firstName, lastName, required, email, confirmEmail } =
    useValidationRules()

  const formInstance = useMemo(() => formProps?.form ?? form, [formProps?.form])

  const onKvkSearchDetailsHandler = useCallback((data: CompanyByCocNumberDetails) => {
    formInstance.setFieldsValue({ ...formInstance.getFieldsValue(), ...data })
  }, [])

  const country = Form.useWatch('country', formInstance)
  const isForeignCountry = country?.id !== nl.code?.toUpperCase()

  return (
    <Form {...formProps} form={formInstance}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Input
                label={t('common.firstName')}
                name='first_name'
                inputProps={{
                  onInput: (e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = capitalizeEveryWord(e.target.value)
                    return e.target.value
                  },
                }}
                rules={[...requiredWithWhitespace, firstName()]}
              />
            </Col>
            <Col span={24}>
              <Input
                label={t('common.lastName')}
                name='last_name'
                inputProps={{
                  onInput: (e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = capitalize(e.target.value)
                    return e.target.value
                  },
                }}
                rules={[...requiredWithWhitespace, lastName]}
              />
            </Col>
            <Col span={24}>
              <Input
                label={t('form.city_of_residence')}
                name='city'
                rules={[...requiredWithWhitespace]}
              />
            </Col>

            <Col xs={24}>
              <CountryFormItemField />
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => (
                  <FormItemPhone defaultCountryCode={getFieldValue('country')?.id} />
                )}
              </Form.Item>
            </Col>
            {!isForeignCountry && <Notification type='info' title={t('form.info_mobile_number')} />}
            <Col span={24}>
              <Input
                label={t('common.email')}
                name='email'
                rules={[required, email]}
                inputProps={{
                  type: 'email',
                }}
              />
            </Col>
            <Col span={24}>
              <Input
                label={t('common.emailConfirmation')}
                name='email_confirmation'
                rules={[required, email, confirmEmail('email')]}
                inputProps={{
                  type: 'email',
                }}
              />
            </Col>
          </Row>
        </Col>
        {isOver18 && (
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Typography.Text strong>{t('common.isFreelancer')}</Typography.Text>
              </Col>
              <Col span={24}>
                <Form.Item name='is_freelancer'>
                  <Radio.Group>
                    <Radio value={false}>{t('common.no')}</Radio>
                    <Radio value={true}>{t('common.yes')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  if (!getFieldValue('is_freelancer')) {
                    return <Noob />
                  }

                  return (
                    <Col span={24}>
                      <Row gutter={[8, 8]}>
                        <Col span={24}>
                          <CompanyKvkSearchContainer
                            onSelect={onKvkSearchDetailsHandler}
                            maxLength={isForeignCountry ? undefined : 8}
                            noFormatLimitations={isForeignCountry}
                          />
                        </Col>
                        <Col span={24}>
                          <VatNumberFormItemField
                            rules={{
                              required: !isForeignCountry,
                              noFormatLimitations: isForeignCountry,
                            }}
                          />
                        </Col>
                        {!isForeignCountry && (
                          <Col span={24}>
                            <Notification
                              type='info'
                              title={t('settings.notification.enter_valid_vat')}
                            />
                          </Col>
                        )}
                        <Col span={24}>
                          <Input
                            name='legal_name'
                            label={t('common.companyNameLegal')}
                            rules={requiredWithWhitespace}
                          />
                        </Col>
                        <Col span={24}>
                          <Input
                            name='street'
                            label={t('form.street')}
                            rules={requiredWithWhitespace}
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            name='house'
                            label={t('form.house')}
                            rules={requiredWithWhitespace}
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            name='postcode'
                            label={t('form.postcode')}
                            rules={requiredWithWhitespace}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )
                }}
              </Form.Item>
            </Row>
          </Col>
        )}
        <Col span={24}>
          <Button type='primary' block htmlType='submit' loading={loading}>
            {t('navigation.continue')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
