import { Children, cloneElement, FC, FunctionComponent, ReactElement } from 'react'
import { Space } from 'antd'

import {
  DataRequirementItem,
  DataRequirementItemProps,
} from '@/components/DataRequirements/DataRequirementItem'
import {
  DataRequirementTitle,
  DataRequirementTitleProps,
} from '@/components/DataRequirements/DataRequirementTitle'

interface DataRequirementsProps {
  children: ReactElement[]
}

interface DataRequirementsComposition {
  Title: FunctionComponent<DataRequirementTitleProps>
  Item: FunctionComponent<DataRequirementItemProps>
}

export const DataRequirements: FC<DataRequirementsProps> & DataRequirementsComposition = ({
  children,
}) => (
  <Space align='start' direction='vertical'>
    {Children.map(children, (child) => {
      if (child.type === DataRequirementTitle) {
        return cloneElement(child)
      }

      if (child.type === DataRequirementItem) {
        return cloneElement(child, {
          done: child.props.done,
        })
      }

      return null
    })}
  </Space>
)

DataRequirements.Item = DataRequirementItem
DataRequirements.Title = DataRequirementTitle
