import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import { Button } from '@/components/Button'
import { Illustration } from '@/components/Illustration'
import { Title } from '@/screens/CommonScreens/ReviewJobScreen/components/Title'

type Props = {
    profileId: string
}

export const StudentProfileSuccessScreen: FC<Props> = ({ profileId }) => {
  const [t] = useTranslation()

  const breakpoints = useBreakpoint()

  const navigate = useNavigate()     
    
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Row justify='center'>
          <Illustration
            name='celebrate'
            imageProps={{
              width: breakpoints.xs ? '160px' : '200px',
              height: breakpoints.xs ? '160px' : '200px',
            }}
          />
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify='center'>
          <Title> {t('profile.public_profile.success_page.title')}</Title>
        </Row>
      </Col>

      <Col xs={24} md={24}>
        <Row justify='center'>
          <Col xs={12} md={6}>
            <Button type='primary' block  data-cy='view-details' onClick={() => navigate(`/marketplace/${profileId}`)}>
              {t('profile.public_profile.success_page.button')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}