import { createContext, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Form, FormInstance } from 'antd'
import merge from 'lodash/merge'

import { useRegisterCompanyMutation } from '@/apollo/generated/graphql'
import { CountryEntity } from '@/entities/country'
import { FieldsEventsSubscriberProvider } from '@/forms/fields/providers/FieldsEventsSubscriberProvider'
import { LoadingScreen } from '@/screens/CommonScreens'

type CompanyOnboardingForm = {
  coc_number: string
  legal_name: string
  name?: string
  street: string
  house: string
  postcode: string
  city: string
  sector: string
  email: string
  first_name: string
  last_name: string
  email_confirmation: string
  position: string
  phone: string
  country: CountryEntity
  vat_number: string
}

type CompanyOnboardingContextType = {
  formValues: CompanyOnboardingForm
  form: FormInstance<CompanyOnboardingForm>
  onFinish: (continueWithRoute: string) => <T extends object>(values: T) => void
  onValuesChange: (values: CompanyOnboardingForm) => void
}

export const CompanyOnboardingContext = createContext<CompanyOnboardingContextType>(
  {} as CompanyOnboardingContextType,
)

export const CompanyOnboardingContainer = () => {
  const [formValues, setFormValues] = useState<CompanyOnboardingForm>({} as CompanyOnboardingForm)

  const navigate = useNavigate()
  const [form] = Form.useForm<CompanyOnboardingForm>()

  const [registerCompanyRequest, { loading: submitting }] = useRegisterCompanyMutation()

  const registerCompanyHandler = () => {
    const { country, ...rest } = formValues

    return registerCompanyRequest({
      variables: {
        country: country.id,
        ...rest,
      },
    })
  }

  const onFinish =
    (continueWithRoute: string) =>
    <T extends object>(values: T) => {
      setFormValues((prevState) => merge(prevState, values))

      switch (continueWithRoute) {
        case '/registration/company/registration-complete':
          registerCompanyHandler().then((result) => {
            if (!result.errors) {
              navigate(continueWithRoute)
            }
          })
          break

        default:
          navigate(continueWithRoute)
      }
    }

  const onValuesChange = (values: CompanyOnboardingForm) => {
    setFormValues((prevState) => merge(prevState, values))
  }

  return (
    <FieldsEventsSubscriberProvider>
      <CompanyOnboardingContext.Provider value={{ form, onFinish, onValuesChange, formValues }}>
        <LoadingScreen loading={submitting}>
          <Outlet />
        </LoadingScreen>
      </CompanyOnboardingContext.Provider>
    </FieldsEventsSubscriberProvider>
  )
}
