import { z } from 'zod'

import { makeSearchParamsObjSchema } from '@/utils/routing'

const objSchema = z.object({
    type: z.enum(['students', 'projects']).catch('students'),
    categories: z.coerce.string()
        .transform(value => {
            if (value === 'undefined') {
                return []
            }
            return value.split(',').map(String)
        })
        .pipe(z.string().min(1).array()).catch([]),
    skills: z.coerce.string()
        .transform(value => {
            if (value === 'undefined') {
                return []
            }
            return value.split(',').map(String)
        })
        .pipe(z.string().min(1).array()).catch([]),
    specializations: z.coerce.string()
        .transform(value => {
            if (value === 'undefined') {
                return []
            }
            return value.split(',').map(String)
        })
        .pipe(z.string().min(1).array()).catch([]),
    range: z.string().nullable()
    .transform(value => value?.split(',')
        .map(Number)).pipe(z.number().array().min(2).nullable()).catch(null),
    countries: z.coerce.string()
        .transform(value => {
            if (value === 'undefined') {
                return []
            }
            return value.split(',').map(String)
        })
        .pipe(z.string().min(1).array()).catch([]),
    orderBy: z.enum(['desc', 'asc']).catch('desc'),
})

const searchParamsObjSchema = makeSearchParamsObjSchema(objSchema)

export function getSearchFilters(params: URLSearchParams) {
    const parsedSchema = searchParamsObjSchema.safeParse(params)

    if (!parsedSchema.success) {
        parsedSchema.error.format()
        throw new Error('Error')
    }

    return parsedSchema.data
}
