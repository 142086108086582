import { FC } from 'react'
import { Avatar } from 'antd'

import { AvatarInitial } from '@/components/AvatarInitial'

interface CompanyAvatarProps {
  name: string
  size?: 'small' | 'large' | number
  logo?: string | null
}

export const CompanyAvatar: FC<CompanyAvatarProps> = ({ name, logo, size = 36 }) => {
  const getAvatarSize = () => {
    if (size === 'large') return 120
    if (typeof size === 'number') return size

    return 64
  }

  return logo ? (
    <Avatar src={logo} size={getAvatarSize()} />
  ) : (
    <AvatarInitial initials={name} size={getAvatarSize()} />
  )
}
