import { useTranslation } from 'react-i18next'
import { Col, Form, FormProps as AntdFormProps, Row, Typography } from 'antd'

import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { useValidationRules } from '@/forms/useValidationRules'

type FormProps = AntdFormProps<{ email: string }>

interface ForgotPasswordProps {
  submitting: boolean
  onFinish: FormProps['onFinish']
}

export const ForgotPasswordScreen = ({ submitting, onFinish }: ForgotPasswordProps) => {
  const { required, email } = useValidationRules()
  const { t } = useTranslation()

  return (
    <Form onFinish={onFinish}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Typography.Title level={3}>{t('auth.forgotPassword.v2.title')}</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text>{t('auth.forgotPassword.v2.text')}</Typography.Text>
        </Col>
        <Col span={24}>
          <Input
            label={t('common.emailAddress')}
            name='email'
            rules={[required, email]}
            inputProps={{
              type: 'email',
            }}
          />
        </Col>
        <Col span={24}>
          <Button type='primary' htmlType='submit' block loading={submitting}>
            {t('auth.forgotPassword.v2.submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
