import { useState } from 'react'

import { regexRules } from '@/forms/helpers'

const hasCapitalLetter = (string?: string) => regexRules.hasCapitalChar.test(string || '')
const hasNumberLetter = (string?: string) => regexRules.hasNumberChar.test(string || '')
const hasSpecialChar = (string?: string) => regexRules.hasSpecialChar.test(string || '')
const hasMinLength = (string?: string) => (string || '').length > 7

export const useTestPassword = () => {
  const [password, setPassword] = useState<string>()
  const [passwordConfirm, setPasswordConfirm] = useState<string>()

  return [
    { setPassword, setPasswordConfirm },
    {
      hasCapitalLetter: hasCapitalLetter(password),
      hasNumberLetter: hasNumberLetter(password),
      hasSpecialChar: hasSpecialChar(password),
      hasMinLength: hasMinLength(password),
      inputsMatch: !!password && password === passwordConfirm,
      allPassed:
        hasCapitalLetter(password) &&
        hasNumberLetter(password) &&
        hasSpecialChar(password) &&
        hasMinLength(password) &&
        !!password &&
        password === passwordConfirm,
    },
  ]
}
