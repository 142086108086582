import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Typography } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import { AssignmentLocationType } from '@/apollo/generated/graphql'
import { Button } from '@/components/Button'
import { JobCategory } from '@/components/Chips'
import { StudentRegisterCategoriesChipsField } from '@/forms/fields/CategoriesChipsField/StudentRegisterCategoriesChipsField'
import { useValidationRules } from '@/forms/useValidationRules'
import { TalentRegistrationPayloadState } from '@/pages/AuthenticationPages/RegistrationPages/StudentRegistrationPage'
import { StudentRegistrationSteps } from '@/screens/AuthenticationScreens/StudentRegistrationScreens/components/StudentRegistrationSteps'

export interface StudentInterestsFormData extends TalentRegistrationPayloadState {
  job_category?: JobCategory[]
  preferred_location_types: AssignmentLocationType[]
}

export type StudentInterestsFormType = React.ComponentProps<typeof Form<StudentInterestsFormData>>

interface StudentInterestsScreenProps {
  formProps: StudentInterestsFormType
  name: string
  loading?: boolean
}

export const StudentInterestsScreen: FC<StudentInterestsScreenProps> = ({ formProps, name }) => {
  const { t } = useTranslation()

  const { required } = useValidationRules()
  const breakpoint = useBreakpoint()
  return (
    <Form
      {...formProps}
      onFinish={(data) =>
        formProps.onFinish({
          ...data,
        })
      }
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <StudentRegistrationSteps current={1} />
        </Col>
        <Col span={24}>
          <Typography.Title level={breakpoint.xs ? 4 : 3}>
            Hi {name}! {t('auth.register.talent.interests')}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Row gutter={[0, 24]}>
            <Typography.Text italic>{t('auth.register.talent.chooseMainCategory')}</Typography.Text>
            <Col span={24}>
              <Form.Item shouldUpdate>
                {({ setFieldValue }) => (
                  <StudentRegisterCategoriesChipsField
                    name='job_category'
                    rules={[required]}
                    onChange={(category) => {
                      setFieldValue('job_category', category)
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => (
              <Button
                disabled={!getFieldValue('job_category')}
                type='primary'
                htmlType='submit'
                block
              >
                {t('navigation.continue')}
              </Button>
            )}
          </Form.Item>
        </Col>
        <Col span={24} />
      </Row>
    </Form>
  )
}
