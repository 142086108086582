import React, { FC, PropsWithChildren } from 'react'
import { Col, Row, Typography } from 'antd'

import { GoBackLink } from '@/components/Links/GoBackLink'
import { CompanyRegistrationSteps } from '@/screens/AuthenticationScreens/CompanyRegistrationScreens/components/CompanyRegistrationSteps'

type CompanyOnboardingLayoutProps = {
  step: number
  title?: string | null
}

export const CompanyOnboardingLayout: FC<PropsWithChildren<CompanyOnboardingLayoutProps>> = ({
  title,
  step,
  children,
}) => (
  <Row gutter={[24, 24]}>
    <Col span={24}>
      <GoBackLink />
    </Col>
    <Col span={24}>
      <CompanyRegistrationSteps current={step} />
    </Col>
    {!!title && (
      <Col span={24}>
        <Typography.Title level={3}>{title}</Typography.Title>
      </Col>
    )}

    <Col span={24}>{children}</Col>
  </Row>
)
