import { useMeQuery } from '@/apollo/generated/graphql'

import { IRbacProps } from '../rbac'
import RequirementValidator from '../RequirementValidator'

import useValidator from './useValidator'

type GetValidatorType<C extends RequirementValidator<unknown>> = C extends RequirementValidator<
  infer T
>
  ? T
  : unknown

export const useRbac = () => {
  const { data } = useMeQuery()

  const role = useValidator({
    values: [data?.me?.role] || [],
  })

  const validators = {
    role,
  }

  const satisfy = <Role extends GetValidatorType<typeof role.validator>>(
    snapshots?: Partial<IRbacProps<Role>>,
  ): boolean => {
    if (!snapshots) return true
    return role.satisfy(snapshots.role)
  }

  return {
    validators,
    satisfy,
  }
}
