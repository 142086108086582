import React, { ComponentProps, FC } from 'react'
import { Form } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import { DatePicker } from '@/components/Datepicker'
import { Icon } from '@/components/Icons'

import styles from './styles.module.less'

interface DateFieldProps extends Pick<ComponentProps<(typeof Form)['Item']>, 'rules'> {
  name: NamePath
  placement?: ComponentProps<typeof DatePicker>['placement']
  placeholder?: ComponentProps<typeof DatePicker>['placeholder']
  disabled?: boolean
  disabledDate?: (current: Date) => void
}

export const DateField: FC<DateFieldProps> = ({
  name,
  placement = 'bottomLeft',
  placeholder,
  disabled,
  rules,
  disabledDate,
}) => (
  <Form.Item name={name} rules={rules}>
    <DatePicker
      disabled={disabled}
      disabledDate={disabledDate}
      suffixIcon={<Icon name='CalendarSvg' size='lg' />}
      allowClear={true}
      size='large'
      className={styles.date_field}
      placement={placement}
      placeholder={placeholder}
    />
  </Form.Item>
)
