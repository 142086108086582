import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Space, Typography } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import { Illustration } from '@/components/Illustration'
import { EmptyLayout } from '@/layouts'
import { Title } from '@/screens/CommonScreens/ReviewJobScreen/components/Title'

interface ErrorBoundaryScreenProps {
  error?: string
  info?: string
}

const { Text } = Typography

export const ErrorBoundaryScreen: FC<ErrorBoundaryScreenProps> = ({ error, info }) => {
  const [t] = useTranslation()
  const breakpoints = useBreakpoint()

  return (
    <EmptyLayout>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Row justify='center'>
            <Illustration
              name='boundary'
              imageProps={{
                width: breakpoints.xs ? '160px' : '200px',
                height: breakpoints.xs ? '160px' : '200px',
              }}
            />
          </Row>
        </Col>
        <Col xs={24}>
          <Space size={16} align='center' direction='vertical' className='w-full'>
            <Title>{t('errors.errorBoundary.title')}</Title>
            <Text className='block w-full text-center'>
              {t('errors.errorBoundary.description')}
            </Text>
            {['dev', 'local'].includes(import.meta.env.VITE_APP_ENV) && (
              <>
                <Text strong>{error}</Text>
                <Text strong>{info}</Text>
              </>
            )}
          </Space>
        </Col>
      </Row>
    </EmptyLayout>
  )
}
