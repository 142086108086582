import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Modal, Typography } from 'antd'

import { Student, useMeQuery } from '@/apollo/generated/graphql'
import { Button } from '@/components/Button'
import Noob from '@/components/Noob'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

import styles from './styles.module.less'

const { Text } = Typography

const withModalSettingsChecker =
  <T extends object>(WrappedComponent: React.ComponentType<T>): FC<T> =>
  (props) => {
    const location = useLocation()
    const { data } = useMeQuery()
    const { isStudent } = useRbacShortcuts()

    const { t } = useTranslation()

    const isProfileRouter =
      `${location.pathname}${location.search}` === '/profile?tab=specializationsAndSkills'

    const renderModalChecker = (isStudent &&
      (data?.me as Student)?.settings?.subcategories_warning_message &&
      !isProfileRouter && (
        <Modal
          title={null}
          open={true}
          footer={null}
          onCancel={() => {}}
          closable={false}
          centered
          className={styles.containerModalChecker}
        >
          <Text className={styles.title}>
            <Text strong>{t('settings.modalChecker.new')}</Text> {t('settings.modalChecker.info')}
          </Text>
          <Link to='/profile?tab=specializationsAndSkills' className={styles.linkButton}>
            <Button type='primary'>{t('settings.modalChecker.goToProfile')}</Button>
          </Link>
        </Modal>
      )) || <Noob />

    return (
      <>
        <WrappedComponent {...props} />
        {renderModalChecker}
      </>
    )
  }

export default withModalSettingsChecker
