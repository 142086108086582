import useCallToActionsCompanyNotifications from '@/pages/CommonPages/ToDoPage/hooks/useCallToActionsCompanyNotifications'
import useCallToActionsMockedNotifications from '@/pages/CommonPages/ToDoPage/hooks/useCallToActionsMockedNotifications'
import useCallToActionsTalentNotifications from '@/pages/CommonPages/ToDoPage/hooks/useCallToActionsTalentNotifications'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

const useCallToActionsCommonNotifications = () => {
  const { isStudent, isCompany, isAdmin } = useRbacShortcuts()

  const studentData = useCallToActionsTalentNotifications()
  const companyData = useCallToActionsCompanyNotifications()

  const adminData = useCallToActionsMockedNotifications()

  if (isStudent) {
    return studentData
  }

  if (isCompany) {
    return companyData
  }

  if (isAdmin) {
    return adminData
  }

  return undefined
}

export default useCallToActionsCommonNotifications
