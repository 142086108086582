import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface'

import { cocRegexp } from '@/features/form-fields/coc-number-field/lib'

type UseRules = (props?: { required?: boolean; noFormatLimitations?: boolean }) => Rule[]

export const useRules: UseRules = (props) => {
  const [t] = useTranslation()

  const rules: Rule[] = []

  if (!props?.noFormatLimitations) {
    rules.push({
      pattern: cocRegexp,
      message: t('errors.invalid_format') ?? undefined,
    })
  }

  rules.push({ required: props?.required, message: t('errors.required') || '' })

  return rules
}
