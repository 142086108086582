import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Image, Modal, Space, Typography } from 'antd'

import { CompanyUser, useMeQuery } from '@/apollo/generated/graphql'
import MonitorSvg from '@/assets/illustrations/illustration-monitor.svg'
import { Button } from '@/components/Button'
import Noob from '@/components/Noob'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

import styles from './styles.module.less'

const { Title, Text } = Typography

const withVacanciesCompanyChecker =
  <T extends object>(WrappedComponent: React.ComponentType<T>): FC<T> =>
  (props) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(true)
    const { data } = useMeQuery()
    const { isCompany } = useRbacShortcuts()

    const renderModalChecker = (isCompany &&
      localStorage.getItem('showPublishVacancyPushPage') === 'true' &&
      (data?.me as CompanyUser).company?.total_vacancies_count === 0 && (
        <Modal
          title={null}
          width={400}
          open={open}
          footer={null}
          onCancel={() => {
            setOpen(false)
            localStorage.removeItem('showPublishVacancyPushPage')
          }}
          centered
          className={styles.containerModalChecker}
        >
          <>
            <div className={styles.imageContainer}>
              <Image src={MonitorSvg} preview={false} />
            </div>
            <Space direction='vertical' className='w-full'>
              <Title level={4} className={styles.title}>
                {t('settings.modalChecker.postJob')}
              </Title>{' '}
              <Text className={styles.description}>{t('settings.modalChecker.findMatch')}</Text>
            </Space>
            <Link
              to='/vacancies/create'
              className={styles.linkButton}
              onClick={() => localStorage.removeItem('showPublishVacancyPushPage')}
            >
              <Button type='primary' block>
                {t('settings.modalChecker.buttonPostJob')}
              </Button>
            </Link>
          </>
        </Modal>
      )) || <Noob />

    return (
      <>
        <WrappedComponent {...props} />
        {renderModalChecker}
      </>
    )
  }

export default withVacanciesCompanyChecker
