import { ValidatorSnapshot } from '../rbac'
import RequirementValidator from '../RequirementValidator'

interface IValidatorProps<T> {
  values: T
}

const useValidator = <T>({ values }: IValidatorProps<T[]>) => {
  const validator = new RequirementValidator<T>(values || [])

  const satisfy = (snapshot: ValidatorSnapshot<T> | undefined): boolean => {
    if (!snapshot) return true

    const [method, ...args] = snapshot
    if (method === 'has' || method === 'doesNotHave') {
      const [arg] = args

      return validator[method](arg)
    }

    return validator[method](...args)
  }

  return {
    validator,
    satisfy,
  }
}

export default useValidator
