import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Layout } from 'antd'

import { Logo } from '@/components/Logo'

import headerStyles from './header.module.less'

type HeaderProps = {
  collapseButton: ReactNode
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ children, collapseButton }) => (
  <Layout.Header style={{ padding: 0 }}>
    <header>
      <div className={headerStyles.wrapper}>
        <div className={headerStyles.logo}>
          {collapseButton}
          <Link to='/'>
            <Logo />
          </Link>
        </div>
        <div className={headerStyles.actions}>{children}</div>
      </div>
    </header>
  </Layout.Header>
)
