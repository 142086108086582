import { RouteObject } from 'react-router-dom'

import { ErrorBoundary } from '@/containers/ErrorBoundary'
import * as Layouts from '@/layouts'
import { NotFoundPage, ToDoPage } from '@/pages/CommonPages'
import * as CompanyPages from '@/pages/CompanyPages'
import { CompanyChatPage } from '@/pages/CompanyPages'
import AuthRedirectRoutes from '@/router/AuthRedirectRoutes'
import { ProfileRoute } from '@/router/CompanyRoutes/profile.route'
import ProjectAssignmentRoutes from '@/router/CompanyRoutes/ProjectAssignmentRoutes'
import ProjectsRoutes from '@/router/CompanyRoutes/ProjectsRoutes'

export const CompanyRoutes: RouteObject = {
  path: '/',
  element: <ErrorBoundary />,
  children: [
    {
      element: <Layouts.AuthenticatedLayout />,
      children: [
        {
          path: 'chat',
          element: <CompanyChatPage />,
        },
      ],
    },
    {
      element: <Layouts.AuthenticatedLayout />,
      children: [
        {
          path: 'chat/:id',
          element: <CompanyChatPage />,
        },
      ],
    },
    {
      element: <Layouts.AuthenticatedLayout />,
      children: [
        {
          index: true,
          element: <CompanyPages.JobsAdministrationPage />,
        },
        ProfileRoute,
        {
          path: 'vacancies',
          children: [
            {
              index: true,
              element: <CompanyPages.JobsAdministrationPage />,
            },
            {
              path: ':id',
              element: <CompanyPages.VacancyPage />,
            },
          ],
        },
        ProjectAssignmentRoutes,
        {
          path: 'projects',
          children: [
            {
              index: true,
              element: <CompanyPages.CompanyMarketPlacePage />,
            },
          ],
        },
        {
          path: 'assignments',
          children: [
            {
              path: ':id',
              element: <CompanyPages.AssignmentPage />,
            },
          ],
        },
        {
          path: 'documents',
          element: <CompanyPages.MyDocumentsPage />,
        },
        {
          path: 'reviews',
          element: <CompanyPages.CompanyReviewsPage />,
        },
        {
          path: 'to-do',
          element: <ToDoPage />,
        },
        ...AuthRedirectRoutes,
      ],
    },
    {
      element: <Layouts.EmptyLayout />,
      children: [
        {
          path: '404',
          element: <NotFoundPage />,
        },
        {
          path: 'review/:jobID',
          element: <CompanyPages.CompanyReviewJobPage />,
        },
        {
          path: 'vacancies/create',
          element: <CompanyPages.VacancyCreatePage />,
        },
        {
          path: 'vacancies/create/:id/success',
          element: <CompanyPages.CreateVacancySuccessPage />,
        },
        {
          path: 'vacancies/reopen/:id/success',
          element: <CompanyPages.VacancyReopenSuccessPage />,
        },
        {
          path: 'vacancies/:id/edit',
          element: <CompanyPages.VacancyEditPage />,
        },
        {
          path: 'vacancies/:id/reopen',
          element: <CompanyPages.VacancyReopenPage />,
        },
        {
          path: 'projectAssignments/:id/review',
          element: <CompanyPages.CompanyReviewProjectAssignmentPage />,
        },
        {
          path: 'assignments/:jobID/review',
          element: <CompanyPages.CompanyReviewAssignmentPage />,
        },
        {
          path: 'assignments/:id/chat',
          element: <CompanyPages.AssignmentChatRedirectPage />,
        },
        {
          path: 'assignments/:id/payment-success',
          element: <CompanyPages.AssignmentProjectSuccessPage />,
        },
        {
          path: 'projectAssignment/:id/payment-success',
          element: <CompanyPages.AssignmentProjectSuccessPage />,
        },
        {
          path: 'talents/:id/chat',
          element: <CompanyPages.TalentChatRedirectPage />,
        },
        ProjectsRoutes,
      ],
    },
  ],
}
