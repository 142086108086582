// eslint-disable-next-line import/no-extraneous-dependencies
import { PhoneNumberUtil } from 'google-libphonenumber'

export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneUtil = PhoneNumberUtil.getInstance()
  try {
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber, '')

    return phoneUtil.isValidNumber(parsedPhoneNumber)
  } catch (error) {
    return false
  }
}
