import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useRules } from '@/features/form-fields/coc-number-field/model'
import { FormField } from '@/shared'
import { Input } from '@/shared/input'

type CocNumberSimpleFormItemFieldProps = {
  rules?: Exclude<Parameters<typeof useRules>[0], undefined>
  disabled?: boolean
}

export const CocNumberSimpleFormItemField: FC<CocNumberSimpleFormItemFieldProps> = ({
  rules,
  ...props
}) => {
  const [t] = useTranslation()

  return (
    <FormField name='coc_number' label={t('auth.register.chamber_id')} rules={useRules(rules)}>
      <Input {...props} />
    </FormField>
  )
}
