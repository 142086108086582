// @media only screen and (max-width: 600px) {...}
// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}
// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {...}
// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {...}
// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}

import { useMediaQuery } from '@/hooks/useMediaQuery'

export const useBreakpoints = () => {
  const xs = useMediaQuery('(max-width: 600px)')
  const sm = useMediaQuery('(min-width: 600px)')
  const md = useMediaQuery('(min-width: 768px)')
  const lg = useMediaQuery('(min-width: 992px)')
  const xl = useMediaQuery('(min-width: 1200px)')

  return {
    xs,
    sm,
    md,
    lg,
    xl,
  }
}

export const useCurrentBreakpoint = () => {
  const breakpoints = useBreakpoints()

  return Object.keys(
    Object.fromEntries(Object.entries(breakpoints).filter(([, value]) => value)),
  ).pop() as keyof ReturnType<typeof useBreakpoints> | undefined
}
