import { FC } from 'react'
import { Skeleton } from 'antd'

import { CompanyUser, useMeQuery } from '@/apollo/generated/graphql'
import { AvatarInitial } from '@/components/AvatarInitial'
import { CompanyAvatar } from '@/containers/avatar/CompanyAvatar'
import { StudentAvatar } from '@/containers/avatar/StudentAvatar'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

interface UserAvatarProps {
  size?: number
}
export const UserAvatar: FC<UserAvatarProps> = ({ size = 36 }) => {
  const { isStudent, isCompany } = useRbacShortcuts()
  const { data, loading } = useMeQuery({
    fetchPolicy: 'cache-only',
  })

  if (loading) {
    return <Skeleton.Avatar active size={size} />
  }

  if (isStudent) {
    return <StudentAvatar size={size} />
  }

  if (isCompany) {
    return (
      <CompanyAvatar
        name={(data?.me as CompanyUser)?.company?.name}
        logo={(data?.me as CompanyUser)?.company?.logo}
        size={size}
      />
    )
  }

  return <AvatarInitial initials={data?.me?.first_name} size={size} />
}
