import React, { useState } from 'react'
import { Form, FormItemProps, Select as AntdSelect } from 'antd'

import { FloatingLabel } from '@/components/Input'

export const Select = ({
  label,
  name,
  activeSelect = false,
  selectProps,
  onFocusSelect,
  ...rest
}: FormItemProps & {
  activeSelect?: boolean
  onFocusSelect?: () => void
  selectProps: React.ComponentProps<typeof AntdSelect>
}) => {
  const [active, setActive] = useState(activeSelect)

  const onFocus = () => {
    onFocusSelect?.()
    setActive(true)
  }

  const onBlur = () => {
    setActive(activeSelect || false)
  }

  return (
    <Form.Item shouldUpdate>
      {({ getFieldValue }) => (
        <FloatingLabel labelText={label} active={active || !!getFieldValue(name)}>
          <Form.Item name={name} {...rest}>
            <AntdSelect
              size='large'
              options={selectProps.options}
              {...selectProps}
              getPopupContainer={(trigger) => trigger.parentNode}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Form.Item>
        </FloatingLabel>
      )}
    </Form.Item>
  )
}
