class RequirementValidator<T> {
  constructor(protected requirements: T[]) {}

  public has(...requirements: [T]): boolean {
    if (requirements.length > 1) {
      throw TypeError('has method should be used only with 1 argument')
    }

    const [requirement] = requirements
    return this.requirements.some((r) => r === requirement)
  }

  public doesNotHave(...requirements: [T]): boolean {
    if (requirements.length > 1) {
      throw TypeError('doesNotHave method should be used only with 1 argument')
    }

    const [requirement] = requirements
    return this.requirements.every((r) => r !== requirement)
  }

  public any(...requirements: T[]): boolean {
    if (requirements.length === 0) {
      throw TypeError("'any' method should be used with at least 1 argument")
    }
    return requirements.some((r) => this.has(r))
  }

  public all(...requirements: T[]): boolean {
    if (requirements.length === 0) {
      throw TypeError("'all' method should be used with at least 1 argument")
    }
    return requirements.every((r) => this.has(r))
  }
}

export default RequirementValidator
