import { cloneElement, ComponentProps, FC, isValidElement, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormInstance } from 'antd'

import { FloatingLabel } from '@/shared/label'
import { Text } from '@/shared/typography'

interface FieldFCComposition {
  fieldName: string
}

export type FieldFC<T extends object = object> = FC<T> & FieldFCComposition

interface FormFieldProps extends ComponentProps<typeof Form.Item> {
  name: string
  disabled?: boolean
  // @todo: Should be refactored, this property should take only FieldFC type, I should be possible only then all form fields will use FormField component as a provider
  revalidateFieldsOnChange?: (FieldFC | string)[]
}

interface FieldMiddlewareProps extends Pick<FormFieldProps, 'revalidateFieldsOnChange'> {
  instance: FormInstance
  onChange?: (...rest: any[]) => void
}

export const FieldMiddleware: FC<PropsWithChildren<FieldMiddlewareProps & object>> = ({
  children,
  instance,
  onChange,
  revalidateFieldsOnChange,
  ...props
}) => {
  const onChangeHandler: FieldMiddlewareProps['onChange'] = (args) => {
    revalidateFieldsOnChange?.forEach(async (field) => {
      await instance.validateFields([typeof field === 'string' ? field : field.fieldName])
    })

    onChange?.(args)
  }

  const newProps = {
    ...props,
    onChange: onChangeHandler,
  }

  return <>{isValidElement(children) && children && cloneElement(children, { ...newProps })}</>
}

export const FormField: FC<PropsWithChildren<FormFieldProps>> = ({
  label,
  name,
  rules,
  children,
  revalidateFieldsOnChange,
  ...rest
}) => {
  const [t] = useTranslation()

  const isRequired = rules?.some((rule) => 'required' in rule && rule.required)

  const getLabelText = () => {
    if (!label) return null

    const postfix = !isRequired ? <Text small italic>{`(${t('form.optional')})`}</Text> : null

    return (
      <span>
        {label} {postfix}
      </span>
    )
  }

  return (
    <Form.Item shouldUpdate>
      {(instance) => (
        <FloatingLabel label={getLabelText()} active={!!instance.getFieldValue(name)}>
          <Form.Item name={name} rules={rules} {...rest}>
            <FieldMiddleware
              instance={instance as FormInstance}
              revalidateFieldsOnChange={revalidateFieldsOnChange}
              {...rest}
            >
              {children}
            </FieldMiddleware>
          </Form.Item>
        </FloatingLabel>
      )}
    </Form.Item>
  )
}
