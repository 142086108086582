export const detectAutofill = (element: Element) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(
        // can be checked by appearance === 'menulist-button' but is not working fo password type
        // window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button'
        window.getComputedStyle(element, null).getPropertyValue('background-color') ===
          'rgb(232, 240, 254)',
      )
    }, 1200)
  })
