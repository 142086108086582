import React, { FC } from 'react'
import { Image } from 'antd'

import * as illustrations from './exports'

export type IllustrationName = keyof typeof illustrations

interface IllustrationProps {
  name: IllustrationName
}

export const Illustration: FC<
  IllustrationProps & { imageProps?: Omit<React.ComponentProps<typeof Image>, 'preview'> }
> = ({ name, imageProps }) => <Image src={illustrations[name]} {...imageProps} preview={false} />
