import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useConfirmUserEmailChangeMutation } from '@/apollo/generated/graphql'
import { useOnMountUnsafe } from '@/hooks/useOnMountUnsafe'
import EmptyLayout from '@/layouts/EmptyLayout'
import { withTokenEmailChecker } from '@/pages/CommonPages/EmailConfirmationPage/withTokenEmailChecker'
import { CongratsScreen } from '@/screens/common/CongratsScreen'
import { LoadingScreen } from '@/screens/CommonScreens'

export const EmailConfirmationPage: FC<{ emailToken: string }> = ({ emailToken }) => {
  const [t] = useTranslation()

  const [request, { loading, error }] = useConfirmUserEmailChangeMutation()

  useOnMountUnsafe(() => {
    request({
      variables: { token: emailToken },
    })
  })

  useEffect(() => {
    if (error) {
      throw new Error(error.message)
    }
  }, [error])

  return (
    <LoadingScreen loading={loading}>
      <EmptyLayout>
        <CongratsScreen title={t('email.success_changed')} />
      </EmptyLayout>
    </LoadingScreen>
  )
}

export const withEmailConfirmationPage = withTokenEmailChecker(EmailConfirmationPage)
