import { UnauthenticatedLayout } from '@/layouts'
import { LoginPage, NotFoundPage, ResetPasswordPage } from '@/pages/AuthenticationPages'
import { ForgotPasswordPage } from '@/pages/AuthenticationPages/ForgotPasswordPage'
import {
  StudentRegistrationPage,
  StudentRegistrationSteps,
} from '@/pages/AuthenticationPages/RegistrationPages/StudentRegistrationPage'
import { WpSitePage } from '@/pages/AuthenticationPages/WpSitePage'
import { CompanyOnboardingRoutes } from '@/router/AuthenticationRoutes/CompanyOnboardingRoutes'
import {
  AccountTypeScreen,
  BirthDayScreen,
  CreatePasswordScreen,
  ToYoungScreen,
} from '@/screens/AuthenticationScreens'

export const AuthenticationRoutes = {
  path: '/',
  element: <UnauthenticatedLayout />,
  children: [
    {
      path: '/wp',
      element: <WpSitePage />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
    {
      index: true,
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'password-reset',
      element: <ResetPasswordPage />,
    },
    // V1 route backward compatability
    {
      path: 'auth/resetPassword',
      element: <ResetPasswordPage />,
    },
    {
      path: 'registration',
      children: [
        {
          index: true,
          element: <AccountTypeScreen />,
        },
        {
          path: 'talent',
          children: [
            {
              index: true,
              element: <StudentRegistrationPage activeStep={StudentRegistrationSteps.birthDay} />,
            },
            {
              path: 'too-young',
              element: (
                <StudentRegistrationPage
                  showBackButton={false}
                  activeStep={StudentRegistrationSteps.birthDayLess16}
                />
              ),
            },
            {
              path: 'personal-info',
              element: (
                <StudentRegistrationPage activeStep={StudentRegistrationSteps.personalInfo} />
              ),
            },
            {
              path: 'interests',
              element: <StudentRegistrationPage activeStep={StudentRegistrationSteps.interests} />,
            },
            {
              path: 'categories',
              element: <StudentRegistrationPage activeStep={StudentRegistrationSteps.categories} />,
            },
            {
              path: 'success',
              element: (
                <StudentRegistrationPage
                  showBackButton={false}
                  activeStep={StudentRegistrationSteps.success}
                />
              ),
            },
          ],
        },
        CompanyOnboardingRoutes,
        {
          path: 'birth-day',
          element: <BirthDayScreen />,
        },
        {
          path: 'to-young',
          element: <ToYoungScreen />,
        },
        {
          path: 'create-password',
          element: <CreatePasswordScreen />,
        },
      ],
    },
  ],
}
