import AuthenticatedLayout from '@/layouts/AuthenticatedLayout'
import EmptyLayout from '@/layouts/EmptyLayout'
import { StudentProjectAssignmentPage } from '@/pages/StudentPages'
import { StudentProposedProjectSuccessPage } from '@/pages/StudentPages/StudentProposedProjectSuccessPage'

export default {
  path: 'projectAssignment',
  children: [
    {
      element: <AuthenticatedLayout />,
      path: ':id',
      children: [
        {
          index: true,
          element: <StudentProjectAssignmentPage />,
        }
      ]
    },
    {
      element: <EmptyLayout />,
      path: ':id/success',
      children: [
        {
          index: true,
          element: <StudentProposedProjectSuccessPage />
        }
      ]
    }
  ],
}
