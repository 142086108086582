import { Dispatch, FC, ReactElement, ReactNode, SetStateAction, useState } from 'react'
import { Modal as AntdModal } from 'antd'

type ModalChildren = (args: { setOpen: Dispatch<SetStateAction<boolean>> }) => ReactNode

interface ModalProps {
  controls: (args: { setOpen: Dispatch<SetStateAction<boolean>> }) => ReactElement
  children: ReactNode | ModalChildren
  className?: string
}

export const Modal: FC<ModalProps> = ({ children, controls, ...rest }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {controls({ setOpen })}
      <AntdModal
        title={null}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        style={{ padding: 0, top: 20 }}
        maskClosable={false}
        destroyOnClose
        {...rest}
      >
        {typeof children === 'function' ? children({ setOpen }) : children}
      </AntdModal>
    </>
  )
}
