import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Typography } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import { Button } from '@/components/Button'

interface ToYoungScreenProps {
  onLeaveSiteClicked?: () => void
}

export const ToYoungScreen: FC<ToYoungScreenProps> = ({ onLeaveSiteClicked }) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={breakpoint.xs ? 4 : 3}>
          {t('auth.register.talent.tooYoung')} 😔
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Button type='primary' block onClick={onLeaveSiteClicked}>
          {t('auth.register.talent.leaveWebsite')}
        </Button>
      </Col>
    </Row>
  )
}
