export const regexRules = {
  phone: /^0[1-9]\d{8}$/,
  coc: /^\d{8}$/,
  cocOptional: /^$|^\d{8}$/,
  digitsOptional: /^$|^\d{6}$/,
  iban: /^NL\d{2}[A-Z]{4}\d{10}$/,
  bic: /^[A-Za-z]{4} ?[A-Za-z]{2} ?[A-Za-z0-9]{2} ?([A-Za-z0-9]{3})?$/,
  vat: /^(NL)?\d{9}B\d{2}$/,
  linkedin: /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
  specialCharacter: /(?=.*[!@#$%^&*])/,
  hasNumber: /\d/,
  sizeCharacter: /(?=.*[a-z])(?=.*[A-Z])/,
  atLeastOneCapitalLetter: /(?=.*?[A-Z]).*/,
  moneyAmount: /\B(?=(\d{4})+(?!\d))/g,
  uuid4: /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/i,
  resetPwdToken:
    /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}-?[a-f0-9]{32}/i,
  hasCapitalChar: /[A-Z]+/,
  hasNumberChar: /[0-9]+/,
  hasSpecialChar: /\W|_+/,
  hasSpacesEndOfParagraph: /\s+(?=<\/p>)/,
  hasNbsp: /&nbsp;/,
  isFirstUppercaseLetter: /^([A-Z][a-zA-Z-]*)(\s[A-Z][a-zA-Z-]*)*$/,
  firstName: /^[A-Z][a-z]*(?:\s[A-Z][a-z]*)*$/,
  lastName: /^[a-zA-Z\s-]+$/,
  positiveValue: /^[1-9]\d*(.\d+)?$/,
  maxFixedAmount: /^-?10000$|^-?[1-9]\d{0,3}$/,
  maxHourlyAmount: /^-?1000$|^-?[1-9]\d{0,2}$/,
  workingHours: /^(?:[0-9]|1[0-9]|2[0-4])$/,
  htmlTagPattern: /(<([^>]+)>)/gi,
  commaDelimited: /^([a-z0-9\s]+,)*([a-z0-9\s]+){1}$/i,
  max2Decimals: /^\d+(\.\d{0,2})?$/,
  maxOneDecimals: /^\d+(\.\d{1})?$/,
  mobileNumber:
    /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/,
  
  internationalPhoneNumberPattern: /(\+?\d{1,4}[-.\s]?)?(\(?\d{1,4}\)?[-.\s]?)?(\d{1,4}[-.\s]?){1,4}/g,
  emailPattern: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
}
