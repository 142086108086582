import { FC } from 'react'
import { Divider, Typography } from 'antd'

import styles from '@/components/Cards/CardDataView/styles.module.less'
import BirthDate from '@/components/LabelsView/BirthDate'
import Default from '@/components/LabelsView/Default'
import Description from '@/components/LabelsView/Description'
import { ItemProps } from '@/components/LabelsView/ItemProps'
import Language from '@/components/LabelsView/Language'
import Mail from '@/components/LabelsView/Mail'
import Password from '@/components/LabelsView/Password'
import Phone from '@/components/LabelsView/Phone'

export type ListTypeComponents = keyof typeof ListTypeComponent

export interface CardDataViewItemProps extends ItemProps {
  name: string
  type?: ListTypeComponents
}

const { Text } = Typography

const componentFactory =
  ({ Component }: { Component: FC<ItemProps> }) =>
  ({ value, name, formatValue }: CardDataViewItemProps) =>
    (
      <>
        <Divider />
        <div className={styles.card_data_view__item}>
          <div className={styles.card_data_view__name}>
            <Text strong>{name}</Text>
          </div>
          <div className={styles.card_data_view__name}>
            <Component value={value} formatValue={formatValue} />
          </div>
        </div>
      </>
    )

const ListTypeComponent = {
  mail: componentFactory({ Component: Mail }),
  password: componentFactory({ Component: Password }),
  default: componentFactory({ Component: Default }),
  birthdate: componentFactory({ Component: BirthDate }),
  phone: componentFactory({ Component: Phone }),
  language: componentFactory({ Component: Language }),
  description: componentFactory({ Component: Description }),
}

export const CardDataViewItem: FC<CardDataViewItemProps> = (props) => {
  const InnerComponent = ListTypeComponent[props.type || 'default']

  return <InnerComponent {...props} /> || null
}
