import { createRef, useEffect } from 'react'

type EventListenerCallback = Parameters<typeof document['addEventListener']>[1]

export const useClickOutsideRef = <T extends HTMLElement>(
  callback?: (...args: unknown[]) => void,
) => {
  const ref = createRef<T>()

  useEffect(() => {
    const handleClickOutside: EventListenerCallback = (event) => {
      if (ref.current && !ref?.current.contains(event.target as Node)) {
        callback?.()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref])

  return ref
}
