import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface'

import { PhoneNumber } from '@/features/phone-number'
import { phoneNumberValidator } from '@/features/phone-number/lib'
import { useValidationRules } from '@/forms/useValidationRules'

export const FormItemPhone: FC<{ defaultCountryCode?: string; required?: boolean }> = ({
  defaultCountryCode,
  required,
}) => {
  const { required: requiredValidator } = useValidationRules()
  const [t] = useTranslation()

  const rules: Rule[] = [phoneNumberValidator(t('errors.invalid_format') || '')]

  if (required) {
    rules.push(requiredValidator)
  }

  return (
    <Form.Item name='phone' rules={rules}>
      <PhoneNumber defaultCountryCode={defaultCountryCode} />
    </Form.Item>
  )
}
