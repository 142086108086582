import loadable from '@loadable/component'

import { LoadingScreen } from '@/screens/CommonScreens'

export const MarketplacePage = loadable(() =>
  import('@/pages/SearchModulePages/MarketplacePage').then((module) => ({
    default: module.MarketplacePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const MarketplaceProjectPage = loadable(() =>
  import('@/pages/SearchModulePages/MarketplaceProjectPage').then((module) => ({
    default: module.MarketplaceProjectPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const MarketplaceStudentPage = loadable(() =>
  import('@/pages/SearchModulePages/MarketplaceStudentPage').then((module) => ({
    default: module.MarketplaceStudentPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const SearchVacanciesPage = loadable(() =>
  import('@/pages/SearchModulePages/SearchVacanciesPage').then((module) => ({
    default: module.SearchVacanciesPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const SearchVacancyPage = loadable(() =>
  import('@/pages/SearchModulePages/SearchVacancyPage').then((module) => ({
    default: module.SearchVacancyPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          