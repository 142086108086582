import { getCountries } from '@/shared'

export const getCountryEntries = (to_be_first?: string, ...args: Parameters<typeof getCountries>) =>
  Object.entries(getCountries(...args))
    .sort((a, b) => {
      const elementA = a[1]
      const elementB = b[1]

      if (elementA < elementB) {
        return -1
      }
      if (elementA > elementB) {
        return 1
      }
      return 0
    })
    .sort(([x], [y]) => {
      if (x === to_be_first?.toUpperCase()) {
        return -1
      }
      if (y === to_be_first?.toUpperCase()) {
        return 1
      }

      return 0
    })
