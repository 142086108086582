import React, { ChangeEvent, ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Typography } from 'antd'
import debounce from 'lodash/debounce'

import { Button } from '@/components/Button'
import { Password } from '@/components/Input'
import { useTestPassword } from '@/hooks/useTestPassword'
import { PasswordDataRequirements } from '@/screens/AuthenticationScreens/CreatePasswordScreen/components/PasswordDataRequirements'

export interface ResetPasswordFormVariables {
  password: string
  password_confirmation: string
}

interface CreatePasswordScreenProps {
  formProps?: ComponentProps<typeof Form<ResetPasswordFormVariables>>
  loading?: boolean
}

export const CreatePasswordScreen: FC<CreatePasswordScreenProps> = ({ formProps, loading }) => {
  const { t } = useTranslation()
  const [
    { setPassword, setPasswordConfirm },
    { hasCapitalLetter, hasNumberLetter, hasSpecialChar, hasMinLength, inputsMatch, allPassed },
  ] = useTestPassword()

  const onPasswordChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword?.(event.target.value)
  }

  const debouncedOnPasswordChangeHandler = debounce(onPasswordChangeHandler, 500)

  const onPasswordConfirmChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm?.(event.target.value)
  }

  const debouncedOnPasswordConfirmChangeHandler = debounce(onPasswordConfirmChangeHandler, 500)

  return (
    <Form {...formProps}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Typography.Title level={2}>{t('auth.createPassword.title')}</Typography.Title>
        </Col>
        <Col span={24}>
          <PasswordDataRequirements
            hasCapitalLetter={hasCapitalLetter ?? false}
            hasMinLength={hasMinLength ?? false}
            hasNumberLetter={hasNumberLetter ?? false}
            hasSpecialChar={hasSpecialChar ?? false}
            inputsMatch={inputsMatch ?? false}
          />
        </Col>

        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Password
                name='password'
                label={t('common.password')}
                onChange={debouncedOnPasswordChangeHandler}
              />
            </Col>
            <Col span={24}>
              <Password
                name='password_confirmation'
                label={t('settings.repeat_password')}
                onChange={debouncedOnPasswordConfirmChangeHandler}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Button loading={loading} disabled={!allPassed} type='primary' htmlType='submit' block>
            {t('navigation.continue')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
