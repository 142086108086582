import { FC, ReactNode } from 'react'
import { Typography } from 'antd'

import styles from '@/components/DataRequirements/styles.module.less'

export interface DataRequirementTitleProps {
  children: ReactNode
}

export const DataRequirementTitle: FC<DataRequirementTitleProps> = ({ children }) => (
  <Typography.Text className={styles.data_requirements__title}>{children}</Typography.Text>
)
