import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Typography } from 'antd'

import { RegisterCompanyInput } from '@/apollo/generated/graphql'
import { Button } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { Input } from '@/components/Input'
import { CocNumberSimpleFormItemField } from '@/features/form-fields'
import { CompanySectorSelectField } from '@/forms/fields'
import { useValidationRules } from '@/forms/useValidationRules'
import { CompanyOnboardingContext } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-onboarding-container'

export type CompanyInfoFormType = React.ComponentProps<typeof Form<RegisterCompanyInput>>

export const CompanyInfoForm: FC<CompanyInfoFormType> = ({ ...props }) => {
  const { t, i18n } = useTranslation()
  const { requiredWithWhitespace } = useValidationRules()
  const [agreementChecked, setAgreementChecked] = useState(false)
  const context = useContext(CompanyOnboardingContext)

  const country = context.form.getFieldValue('country')


  return (
    <Form {...props}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <CocNumberSimpleFormItemField
                rules={{
                  required: !country?.isForeignCountry,
                  noFormatLimitations: country?.isForeignCountry,
                }}
              />
            </Col>
            <Col span={24}>
              <Input
                name='legal_name'
                label={t('common.companyNameLegal')}
                rules={requiredWithWhitespace}
              />
            </Col>
            <Col span={24}>
              <Input name='name' label={t('common.company')} rules={requiredWithWhitespace} />
            </Col>
            <Col span={24}>
              <Input name='street' label={t('common.streetName')} rules={requiredWithWhitespace} />
            </Col>
            <Col span={24}>
              <Input name='house' label={t('common.houseNumber')} rules={requiredWithWhitespace} />
            </Col>
            <Col span={24}>
              <Input name='postcode' label={t('common.zipCode')} rules={requiredWithWhitespace} />
            </Col>
            <Col span={24}>
              <Input name='city' label={t('form.city')} rules={requiredWithWhitespace} />
            </Col>
            <Col span={24}>
              <CompanySectorSelectField name='sector' />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={2}>
              <Checkbox onChange={(e) => setAgreementChecked(e.target.checked)} />
            </Col>
            <Col span={22}>
              <Typography.Text>
                {t('auth.register.signCondition.general')}{' '}
                <a
                  href={`${import.meta.env.VITE_APP_BACKEND_URL}documents/terms_company_kickjobs.${
                    i18n.language
                  }.pdf`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('auth.register.signCondition.terms')}
                </a>
              </Typography.Text>
              <Typography.Paragraph>
                &{' '}
                <a
                  href={`${import.meta.env.VITE_APP_BACKEND_URL}documents/privacy_kickjobs.${
                    i18n.language
                  }.pdf`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('auth.register.signCondition.privacy')}
                </a>
              </Typography.Paragraph>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Button disabled={!agreementChecked} type='primary' htmlType='submit' block>
            {t('navigation.continue')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
