import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneNumberUtil = PhoneNumberUtil.getInstance()

export const getNationalPhoneNumber = (phone_number: string) => {
  try {
    const phoneNumber = phoneNumberUtil.parse(phone_number, '')

    if (phoneNumberUtil.isValidNumber(phoneNumber)) {
      return phoneNumberUtil.getNationalSignificantNumber(phoneNumber)
    }

    return undefined
  } catch (error) {
    return undefined
  }
}
