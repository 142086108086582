import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Image, Row, Typography } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import CelebratePng from '@/assets/illustrations/illustration-celebrate.png'
import { Button } from '@/components/Button'

interface RegistrationCompletedScreenProps {
  email: string
}

export const RegistrationCompletedScreen: FC<RegistrationCompletedScreenProps> = ({ email }) => {
  const breakpoint = useBreakpoint()
  const { t } = useTranslation()

  const imageStyles = !breakpoint.md
    ? {
        display: 'flex',
        justifyContent: 'center',
      }
    : {}

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <div style={imageStyles}>
          <Image src={CelebratePng} preview={false} />
        </div>
      </Col>
      <Col span={24}>
        <Typography.Title level={2}>
          {t('auth.register.common.accountCreationSuccess')}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <Typography.Text>
          {t('auth.register.common.commonText.start')}{' '}
          <Typography.Text strong>{email}</Typography.Text>{' '}
          {t('auth.register.common.commonText.end')}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Link to='/'>
          <Button type='primary' block>
            {t('auth.register.common.toLogin')}
          </Button>
        </Link>
      </Col>
    </Row>
  )
}
