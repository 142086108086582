import { MenuItems, useExistingMenuItems } from './useExistingMenuItems'

export const useAdminMenuItems = (): MenuItems => {
  const [, getMenuItemByName] = useExistingMenuItems()

  return [
    getMenuItemByName('vacancies'),
    getMenuItemByName('companies'),
    getMenuItemByName('students'),
    [
      getMenuItemByName('marketplace'),
      [
        getMenuItemByName('tab-marketplace-projects'),
        getMenuItemByName('tab-marketplace-proposals'),
        getMenuItemByName('tab-marketplace-in_progress'),
        getMenuItemByName('tab-marketplace-archive'),
      ],
    ],
    getMenuItemByName('documents'),
    getMenuItemByName('settings'),
  ]
}
