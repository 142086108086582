import { FC } from 'react'
import { Avatar, Skeleton } from 'antd'

import { Student, useStudentProfileQuery } from '@/apollo/generated/graphql'
import { AvatarInitial } from '@/components/AvatarInitial'

interface StudentAvatarProps {
  size?: number
}

export const StudentAvatar: FC<StudentAvatarProps> = ({ size = 100 }) => {
  const { data, loading } = useStudentProfileQuery()

  const student = data?.me as Student

  if (loading) {
    return <Skeleton.Avatar active />
  }

  if (student.avatar) {
    return <Avatar src={student.avatar} size={size} />
  }

  return <AvatarInitial initials={student?.first_name} size={size} />
}
