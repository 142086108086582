import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '@/components/Button'

export const CreateJobButton = () => {
  const [t] = useTranslation()

  return (
    <Link to='/vacancies/create'>
      <Button type='primary'>{t('cards.createFirstJobCard.button')}</Button>
    </Link>
  )
}
