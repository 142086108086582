import { useMeQuery } from '@/apollo/generated/graphql'
import { useAuthToken } from '@/providers/AuthenticationProvider/hooks/useAuthToken'

type UserIsFetching = boolean

export const useCheckUser = (): [UserIsFetching] => {
  const [, , removeAuthToken] = useAuthToken()
  const { loading } = useMeQuery({
    onCompleted: (data) => {
      if (!data.me) {
        removeAuthToken()
      }
    },
  })

  return [loading]
}
