import React from 'react'
import { Input as AntdInput } from 'antd'

import { FloatingLabelInput, FormItemProps } from '@/components/Input'

export const Search = ({
  label,
  name,
  inputProps,
  ...rest
}: FormItemProps & { inputProps?: React.ComponentProps<typeof AntdInput> }) => (
  <FloatingLabelInput labelText={label} name={name} {...rest}>
    <AntdInput size='large' className='search-input' {...inputProps} />
  </FloatingLabelInput>
)
