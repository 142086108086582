import React, { FC, useMemo } from 'react'
import { List, Typography } from 'antd'
import clsx from 'classnames'

import { Icon } from '@/components/Icons'

import styles from './styles.module.less'

type NotificationType = 'success' | 'info' | 'error'

type NotificationProps = {
  type: NotificationType
  title: React.ReactNode | string | string[] | null
}
const { Text } = Typography
export const Notification: FC<NotificationProps> = ({ type, title }) => {
  const classes = clsx(styles.notification, styles[`${type}`])

  const icon = useMemo(() => {
    switch (type) {
      case 'info':
        return 'InfoSvg'
      case 'error':
        return 'ErrorSvg'

      default:
        return 'CheckSvg'
    }
  }, [type])

  if (Array.isArray(title)) {
    return (
      <div className={classes}>
        <Icon name={icon} size='lg' />

        <List
          size='small'
          dataSource={title}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text>{item}</Typography.Text>
            </List.Item>
          )}
        ></List>
      </div>
    )
  }

  return (
    <div className={classes}>
      <Icon name={icon} size='lg' />
      <Text className={styles.title}>{title}</Text>
    </div>
  )
}
