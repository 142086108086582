import { useContext } from 'react'

import { countryProviderContext } from '@/entities/country/ui/country-provider'

export const useCountryProviderContext = () => {
  const context = useContext(countryProviderContext)

  if (!context) {
    throw new Error('useCountryProviderContext can use only with CountryProvider')
  }

  return context
}
