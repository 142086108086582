import React, { createContext, FC, PropsWithChildren, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout as AntdLayout } from 'antd'

import { Actions } from '@/layouts/AuthenticatedLayout/Actions'
import { CollapseSiderButton } from '@/layouts/AuthenticatedLayout/CollapseSiderButton'
import { AuthenticatedLayoutProvider } from '@/layouts/AuthenticatedLayout/provider'
import { SidebarMenu } from '@/layouts/AuthenticatedLayout/SidebarMenu'
import { Sider } from '@/layouts/AuthenticatedLayout/Sider'
import withHelmet from '@/layouts/hocs/withHelmet'
import withModalSettingsChecker from '@/layouts/hocs/withModalSettingsChecker'
import withVacanciesCompanyChecker from '@/layouts/hocs/withVacanciesCompanyChecker'
import { withEmailConfirmationPage } from '@/pages/CommonPages/EmailConfirmationPage'
import { CallsToActionsProvider } from '@/providers/CallsToActionsProvider'

import { Content } from './Content'
import { Header } from './Header'
import styles from './styles.module.less'
import {
  AuthenticatedLayoutContextType,
  LayoutConfig,
  LayoutConfigUnion,
  WithLayoutConfigProps,
} from './types'

export const AuthenticatedLayoutContext = createContext<
  AuthenticatedLayoutContextType<LayoutConfigUnion<LayoutConfig>>
>({} as AuthenticatedLayoutContextType<LayoutConfigUnion<LayoutConfig>>)

export const withLayoutTheme = <T extends WithLayoutConfigProps>(WrappedComponent: FC<T>) => {
  const ComponentWithLayoutTheme: FC<Omit<T, keyof WithLayoutConfigProps>> = (props) => {
    const { config } = useContext(AuthenticatedLayoutContext)

    return <WrappedComponent {...(props as T)} config={config} />
  }

  return ComponentWithLayoutTheme
}

export const LayoutTheme: FC<PropsWithChildren> = ({ children }) => <>{children}</>

const AuthenticatedLayout: FC<PropsWithChildren> = () => (
  <CallsToActionsProvider>
    <AuthenticatedLayoutProvider>
      <AntdLayout className={styles.layout}>
        <Header collapseButton={<CollapseSiderButton />}>
          <Actions />
        </Header>
        <AntdLayout>
          <Sider>
            <SidebarMenu />
          </Sider>
          <AntdLayout className={styles.content_layout}>
            <Content>
              <Outlet />
            </Content>
          </AntdLayout>
        </AntdLayout>
      </AntdLayout>
    </AuthenticatedLayoutProvider>
  </CallsToActionsProvider>
)

export default withEmailConfirmationPage(
  withVacanciesCompanyChecker(withModalSettingsChecker(withHelmet(AuthenticatedLayout))),
)
