import { ComponentProps, forwardRef, useEffect, useRef } from 'react'
import { Input, InputRef } from 'antd'
import clsx from 'classnames'

import { makeId } from '@/utils/makeId'

import styles from './styles.module.less'

type PhoneInputProps = Omit<ComponentProps<typeof Input>, 'size'> & { countryCode?: number }

export const PhoneInput = forwardRef<InputRef, PhoneInputProps>(
  ({ countryCode, className, ...rest }, ref) => {
    const id = useRef<string>(makeId(5))

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault()
    }

    const handleKeyboard = (event: KeyboardEvent) => {
      if (
        event.code === 'ArrowDown' ||
        event.code === 'ArrowUp' ||
        event.code === 'Equal' ||
        event.code === 'NumpadAdd' ||
        event.key === '-' ||
        event.key === '.'
      ) {
        event.preventDefault()
      }
    }

    useEffect(() => {
      const input$ = document.getElementById(id.current)
      input$?.addEventListener('wheel', handleWheel, { passive: false })
      input$?.addEventListener('keydown', handleKeyboard)

      return () => {
        input$?.removeEventListener('wheel', handleWheel)
        input$?.removeEventListener('keydown', handleKeyboard)
      }
    }, [])

    return (
      <div className={styles.country_phone} data-before={countryCode ? `+${countryCode}` : null}>
        <Input
          ref={ref}
          size='large'
          type='number'
          id={id.current}
          {...rest}
          className={clsx(className, [styles.country_phone__disable_controls], {
            [styles.country_phone__padding_sm]:
              !!countryCode && countryCode.toString().length === 1,
            [styles.country_phone__padding_md]:
              !!countryCode && countryCode.toString().length === 2,
            [styles.country_phone__padding_lg]:
              !!countryCode && countryCode.toString().length === 3,
          })}
        />
      </div>
    )
  },
)
