import React from 'react'
import { Link as LinkReact, LinkProps as LinkPropsReactRouterDom } from 'react-router-dom'
import clsx from 'classnames'

import { Icon } from '@/components/Icons'

import styles from './styles.module.less'

type SvgName = Pick<Parameters<typeof Icon>[0], 'name'>['name']

interface LinkProps extends React.ComponentProps<typeof LinkReact> {
  icon?: SvgName
  isExternal?: boolean
  type?: string
  text: string
  to: string
  size?: 'sm' | 'lg' | 'md' | 'xl' | 'xxl'
}

export const Link = ({
  to,
  text,
  icon,
  isExternal = false,
  size = 'md',
  type = 'default',
  state,
  className,
  ...rest
}: LinkProps & LinkPropsReactRouterDom) => {
  const { onClick } = rest

  const children = () => (
    <>
      {icon && <Icon name={icon} size={size} />} {text}
    </>
  )

  const classes = clsx(className, styles.link, styles[`link_${type}`], styles[`link_${size}`])

  if (isExternal) {
    return (
      <a href={to} className={classes} target='_blank' onClick={onClick}>
        {children()}
      </a>
    )
  }

  return (
    <LinkReact to={to} state={state} className={classes} {...rest}>
      {children()}
    </LinkReact>
  )
}
