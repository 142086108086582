import { CreateJobButton } from '@/components/buttons/CreateJobButton'
import { SearchJobsButton } from '@/components/buttons/SearchJobsButton'
import Noob from '@/components/Noob'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

export const HeaderButtons = () => {
  const { isStudent, isCompany } = useRbacShortcuts()

  if (isStudent) return <SearchJobsButton />
  if (isCompany) return <CreateJobButton />
  return <Noob />
}
