import format from 'date-fns/format'
import enGB from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import parse from 'date-fns/parse'
import subMinutes from 'date-fns/subMinutes'

import {
  backendDateFormat,
  dateTimeChatFormat,
  defaultDateFormat,
  hoursFormat,
  jobCardsDateFormat,
  onlyDateFormat,
} from '@/constants/dates'
import { i18n } from '@/services'

export const formatDateMonthDefaultFormat = (date: string | null | undefined) =>
  date
    ? format(new Date(date), jobCardsDateFormat, {
        locale: i18n.language === nl.code ? nl : enGB,
      })
        .split('.')
        .join('')
    : ''

export const formatDateDefaultFormat = (
  date: string | null | undefined,
  dateFormat: string = defaultDateFormat,
) => (date ? format(new Date(date), dateFormat) : '')

export const parseToBackendDate = (date: string | null | undefined) =>
  date ? parse(date, backendDateFormat, new Date()) : 0

export const formatDateToBackendFormat = <T, D extends Date, N extends number>(
  date: D | N,
  otherwise: T,
): string | T => (date ? format(date, backendDateFormat) : otherwise)

export const datePeriod = (
  start: string | undefined,
  end?: string,
  dateFormat: string = defaultDateFormat,
) => `
  ${start ? format(parseToBackendDate(start), dateFormat) : ''} ${
  end
    ? `${i18n.t('common.date_period.to')} ${format(parseToBackendDate(end), dateFormat)}`
    : `${i18n.t('common.date_period.to')} ${i18n.t('common.present')}`
}
  `

export const utcToLocaleDate = (
  date: string | null | undefined,
  dateFormat = dateTimeChatFormat,
  dateParseFormat = dateTimeChatFormat,
) => {
  if (date) {
    const parsedDate = parse(date, dateParseFormat, new Date())

    return format(subMinutes(parsedDate, parsedDate.getTimezoneOffset()), dateFormat).toString()
  }

  return 0
}

export const utcToLocaleDateHours = (dateString: string) => {
  const parsedDate = parse(dateString, dateTimeChatFormat, new Date())

  const date = format(
    subMinutes(parsedDate, parsedDate.getTimezoneOffset()),
    onlyDateFormat,
  ).toString()
  const hours = format(
    subMinutes(parsedDate, parsedDate.getTimezoneOffset()),
    hoursFormat,
  ).toString()

  return { date, hours }
}

const convertToISO8601 = (dateString: string): string => {
  const isoDateString = `${dateString.replace(' ', 'T')}Z`
  const dateObject = new Date(Date.parse(isoDateString))
  return dateObject.toISOString()
}

export const convertUtcToLocalDate = (utcDateString: string): Date => {
  const utcDate = new Date(convertToISO8601(utcDateString))
  const localOptions = { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }
  const localDateString = utcDate.toLocaleString('en-US', localOptions)
  return new Date(localDateString)
}
