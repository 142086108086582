import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { PortfolioPage } from '@kickjobs/search-marketplace-module'

import { usePublicProjectQuery, usePublicProjectsQuery } from '@/apollo/generated/graphql'
import capitalize from '@/helpers/capitalize'
import { formatDateDefaultFormat } from '@/helpers/dates'
import { getMetaShortDescription } from '@/helpers/getMetaShortDescription'
import MetaTagsProvider from '@/layouts/provider/MetaTagsProvider'
import { useUserState } from '@/providers/AuthenticationProvider/hooks/useUserState'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'
import { LoadingScreen } from '@/screens/CommonScreens'

import useTranslatedSearchLayoutStructure from '../staticData'

export const MarketplaceProjectPage = () => {
  const { navigation, footer, footerBottom } = useTranslatedSearchLayoutStructure()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { projectName = '' } = useParams()
  const { isAuthenticated } = useUserState()
  const { isStudent, isCompany } = useRbacShortcuts()

  const { data, loading } = usePublicProjectQuery({
    variables: {
      id: projectName.match(/-(\d+)$/)?.[1] || '',
    },
  })

  const { data: relatedProjects } = usePublicProjectsQuery({
    variables: {
      first: 5,
      page: 1,
      categoryId: data?.publicProject?.category?.id || [],
    },
  })

  if (data?.publicProject) {
    const {
      id,
      title,
      description,
      amount,
      duration_days: durationDays,
      revisions_count: revisionsCount,
      bullet_points: bulletPoints,
      images,
      user,
    } = data.publicProject

    if (data?.publicProject === null) {
      navigate(`/marketplace`)
    }

    const parsedProjectData = {
      portfolio: {
        title,
        description: `${description}`,
        price: `€${amount.toFixed(2).replace('.', ',')}`,
        images: images?.map(({ path }) => path) || [],
        duration: durationDays,
        bulletPoints: bulletPoints?.map((point) => `${point}`),
        chatLink: isAuthenticated && !isStudent ? `/talents/${user?.id}/chat` : '/',
        applyLink: isCompany && `/projects/${id}/apply`,
        freelancer: {
          avatar: user?.avatar || 'https://fakeimg.pl/300x300/?text=avatar',
          name: `${user?.firstName} ${user?.lastName}`,
          reviewsCount: `${user?.received_reviews?.paginatorInfo.total}`,
          totalRate: `${user?.star_rating}`,
          link: user?.publicId ? `/marketplace/${user?.publicId}` : '#',
        },
        revisions:
          revisionsCount === 0 || revisionsCount === 99
            ? t('marketplace.project.form.unlimited')
            : `${revisionsCount}`,
        delivery: `${durationDays}`,
        reviews: user?.received_reviews
          ? user?.received_reviews?.data.map(
              ({ jobInfo, star_rating: rate, created_at: postedTime, comment }) => ({
                rate: `${rate}`,
                postedTime: formatDateDefaultFormat(postedTime),
                description: comment,
                title: jobInfo.title,
              }),
            )
          : [],
        reviewsCount: `${user?.received_reviews?.paginatorInfo.total}`,
      },
      relatedJobs:
        relatedProjects?.publicProjects.data
          .filter(({ id: projectId }) => id !== projectId)
          .map(
            ({
              id: projectId,
              title: projectTitle,
              images: projectImages,
              duration_days,
              amount: projectAmount,
              user: projectUser,
            }) => ({
              coverImage: `${projectImages?.length && projectImages[0].path}`,
              avatar: `${projectUser?.avatar}`,
              title: `${projectTitle}`,
              price: `${projectAmount}`,
              delivery: `${duration_days}`,
              ownerName: `${projectUser?.firstName} ${projectUser?.lastName}`,
              rate: `${projectUser?.star_rating}`,
              totalRates: `${projectUser?.received_reviews?.paginatorInfo.total}`,
              location: 'Netherlands',
              link: `/marketplace/${projectUser?.publicId}/${encodeURIComponent(title?.replace(
                /\s/g,
                '',
              ))}-${projectId}`,
            }),
          )
          .slice(0, 4) || [],
    }

    return (
      <MetaTagsProvider
        title={
          t('meta.project.by', {
            title: capitalize(data.publicProject.title),
            studentName: `${data?.publicProject.user?.firstName as string} ${
              data?.publicProject.user?.lastName as string
            }`,
          }) || ''
        }
        metaDescription={getMetaShortDescription(data.publicProject.description)}
      >
        <div data-module>
          <PortfolioPage
            content={parsedProjectData}
            loading={loading}
            headerNavigation={navigation}
            footerItems={footer}
            footerBottomItems={footerBottom}
          />
        </div>
      </MetaTagsProvider>
    )
  }
  return <LoadingScreen loading />
}
