import { Component, ErrorInfo, ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { ErrorBoundaryScreen } from '@/screens/common/ErrorBoundaryScreen'
import { LoadingScreen } from '@/screens/CommonScreens'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundaryWrap extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ error, errorInfo: info })
  }

  render() {
    const { hasError, error, errorInfo } = this.state

    if (hasError) {
      if (error?.message.includes('Failed to fetch dynamically imported module')) {
        window.location.reload()

        return <LoadingScreen loading />
      }

      return <ErrorBoundaryScreen error={error?.message} info={errorInfo?.componentStack} />
    }

    return this.props.children
  }
}

export const ErrorBoundary = () => (
  <ErrorBoundaryWrap>
    <Outlet />
  </ErrorBoundaryWrap>
)
