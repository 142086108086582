import React, { FC } from 'react'
import { Space, Typography } from 'antd'

import styles from './styles.module.less'

const { Paragraph, Text } = Typography

export const ClearAutoCompleteResults: FC<React.ComponentProps<typeof Paragraph>> = ({
  children = 'Clear',
  onClick,
  ...props
}) => (
  <Space className={styles.item} direction='horizontal' onClick={onClick}>
    <Text {...props}>{children}</Text>
  </Space>
)
