import { ReactNode } from 'react'
import { Image, Typography } from 'antd'
import clsx from 'classnames'

import styles from './styles.module.less'

export type CardSelectionProps = {
  text: string | ReactNode
  imageSrc: string
  active?: boolean
}

export const CardSelection = ({ text, active, imageSrc }: CardSelectionProps) => {
  const classes = clsx(styles.container, {
    [styles.active]: active,
  })

  return (
    <div className={classes}>
      <div>
        <Image src={imageSrc} preview={false} width={120} height={120} />

        {typeof text === 'string' ? <Typography.Text strong>{text}</Typography.Text> : text}
      </div>
    </div>
  )
}
