import { ApolloProvider } from '@/apollo/provider'
import { CountryProvider } from '@/entities/country/ui/country-provider'
import { ThemeConfigProvider } from '@/providers/theme-provider'
import { Router } from '@/router'

function App() {
  return (
    <ApolloProvider>
      <CountryProvider homeCountryCountryCode='nl'>
        <ThemeConfigProvider theme='dark'>
          <Router />
        </ThemeConfigProvider>
      </CountryProvider>
    </ApolloProvider>
  )
}

export default App
