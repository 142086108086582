import { FC } from 'react'
import { Badge } from 'antd'

import { useCallsToActionsContext } from '@/providers/CallsToActionsProvider'

type CallToActionBadgeType = {
  type: Exclude<keyof ReturnType<typeof useCallsToActionsContext>['callsToActions'], '__typename'>
}

export const CallToActionBadge: FC<CallToActionBadgeType> = ({ type }) => {
  const c = useCallsToActionsContext()

  const { [type]: value } = c?.callsToActions || {}

  return <Badge count={value?.callsToActionCount} />
}
