import React, { FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CompanyInfoForm } from '@/forms/Auth/Registration/CompanyInfoForm'
import { CompanyOnboardingLayout } from '@/layouts/CompanyOnboardingLayout'
import {
  ImageTypeEnum,
  OnboardingImageContext,
} from '@/layouts/UnauthenticatedLayout/UnauthenticatedLayoutTemplate'
import { CompanyOnboardingContext } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-onboarding-container'

type CompanyAdditionalInformationPageProps = {
  continueWithRoute: string
}

export const CompanyAdditionalInformationPage: FC<CompanyAdditionalInformationPageProps> = ({
  continueWithRoute,
}) => {
  const [t] = useTranslation()
  const context = useContext(CompanyOnboardingContext)
  const imageContext = useContext(OnboardingImageContext)

  useEffect(() => {
    imageContext?.setImage(ImageTypeEnum.companyInfo)
  }, [])

  return (
    <CompanyOnboardingLayout step={0} title={t('auth.register.secondStep')}>
      <CompanyInfoForm
        form={context.form}
        onFinish={context.onFinish(continueWithRoute)}
        onValuesChange={context.onValuesChange}
      />
    </CompanyOnboardingLayout>
  )
}
