import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Typography } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import { Button } from '@/components/Button'
import { Illustration } from '@/components/Illustration'
import { Title } from '@/screens/CommonScreens/ReviewJobScreen/components/Title'

type Props = {
    onAgree?: () => void
}

export const StudentProjectCreatedSuccessfullyScreen: FC<Props> = ({ onAgree }) => {
  const [t] = useTranslation()

  const breakpoints = useBreakpoint()

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Row justify='center'>
          <Illustration
            name='celebrate'
            imageProps={{
              width: breakpoints.xs ? '160px' : '200px',
              height: breakpoints.xs ? '160px' : '200px',
            }}
          />
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify='center'>
          <Title> {t('marketplace.project.success_created')}</Title>
        </Row>
      </Col>

      <Col xs={24} md={24} flex='auto'>
        <Row justify='center'>
          <Typography.Text className='text-center'>
            {t('marketplace.project.projectSuccessfullyCreatedPage.description')}
          </Typography.Text>
        </Row>
      </Col>

      <Col xs={24} md={24}>
        <Row justify='center'>
          <Col xs={12} md={6}>
            <Button type='primary' block  data-cy='view-details' onClick={onAgree}>
              {t('marketplace.project.projectSuccessfullyCreatedPage.buttonText')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
