import { createContext, FC, PropsWithChildren, useContext } from 'react'

import useCallToActionsCommonNotifications from '@/pages/CommonPages/ToDoPage/hooks/useCallToActionsCommonNotifications'

type CallsToActionsContextType = ReturnType<typeof useCallToActionsCommonNotifications>

const CallsToActionsContext = createContext<CallsToActionsContextType | null>(null)

export const useCallsToActionsContext = () => useContext(CallsToActionsContext)

export const CallsToActionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useCallToActionsCommonNotifications()

  return <CallsToActionsContext.Provider value={value}>{children}</CallsToActionsContext.Provider>
}
