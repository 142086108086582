import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'antd'

import { Icon } from '@/components/Icons'
import { ItemProps } from '@/components/LabelsView/ItemProps'
import { Modal } from '@/hooks/modal/Modal'
import { useIsMobileDevice } from '@/hooks/useIsMobileDevice'

import styles from './styles.module.less'

const { Text, Title } = Typography

const Description: FC<ItemProps> = ({ value, formatValue }) => {
  const [t] = useTranslation()
  const isMobileDevice = useIsMobileDevice()

  return (
    <>
      <div className={styles.description}>
        <Modal
          controls={({ setOpen }) => (
            <>
              {value && (
                <Text className={styles.description__preview} onClick={() => setOpen(true)}>
                  <Icon size='md' name='EyeOpenSvg' />
                  {t('common.preview')}
                </Text>
              )}
            </>
          )}
        >
          <div className={styles.description__content}>
            <Title level={isMobileDevice ? 5 : 3}>{t('common.description')}</Title>
            {formatValue ? formatValue(value) : value}
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Description
