import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneNumberUtil = PhoneNumberUtil.getInstance()

export const getCountryCodeFromPhoneNumber = (phone_number: string) => {
  try {
    const parsedPhoneNumber = phoneNumberUtil.parse(phone_number, '')

    return phoneNumberUtil.getRegionCodeForNumber(parsedPhoneNumber)
  } catch (error) {
    return undefined
  }
}
