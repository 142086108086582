import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Skeleton, Space } from 'antd'

import { JobCategory, useJobCategoriesQuery } from '@/apollo/generated/graphql'
import { Chips } from '@/components/Chips'
import { ChipItem } from '@/components/Chips/ChipItem'
import { Rule } from '@/forms/useValidationRules'

import styles from './styles.module.less'

interface CategoriesChipsFieldProps {
  name?: string
  rules?: Rule[]
  onChange?: (category: JobCategory) => void
}

export const StudentRegisterCategoriesChipsField: FC<CategoriesChipsFieldProps> = ({
  name = 'jobCategories',
  rules,
  onChange,
}) => {
  const { i18n } = useTranslation()
  const { data, loading, refetch } = useJobCategoriesQuery({
    variables: {
      page: 1,
      first: 100,
      withoutHidden: true,
      hasSpecializations: true,
    },
  })

  useEffect(() => {
    refetch()
  }, [i18n.language])

  const jobCategories = data?.jobCategories?.data || []

  return (
    <Space direction='vertical' size={12}>
      {(!loading && (
        <Form.Item name={name} rules={rules}>
          <Form.Item shouldUpdate>
            {({ getFieldValue, setFieldValue }) => (
              <Chips
                defaultValues={getFieldValue(name)}
                onChange={(value) => {
                  setFieldValue(name, value)
                  const category = jobCategories.find(
                    (jobCategory) => jobCategory.id === value.name,
                  )

                  onChange?.(category as JobCategory)
                }}
              >
                {jobCategories
                  .slice()
                  .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
                  .map((value, index) => {
                    const selectedCategory = getFieldValue(name)?.id === value?.id

                    return (
                      <ChipItem
                        key={index}
                        name={value.id}
                        className={selectedCategory ? styles.isMainChip : ''}
                      >
                        {value.name}
                      </ChipItem>
                    )
                  })}
              </Chips>
            )}
          </Form.Item>
        </Form.Item>
      )) || (
        <div className={styles.skeleton}>
          {Array.from(Array(10).keys()).map(() => (
            <Skeleton.Avatar active size={36} />
          ))}
        </div>
      )}
      {loading && <Skeleton active paragraph={{ rows: 4, width: '20rem' }} />}
    </Space>
  )
}
