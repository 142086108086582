import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCountryProviderContext } from '@/entities/country/model/useCountryProviderContext'
import { Country, getCountryEntries } from '@/shared'
import { euMembers } from '@/shared/countries/lib/euMembers'

export interface CountryEntity extends Country {
  isEuMember: boolean
  isForeignCountry: boolean
}

type Helpers = {
  getCountryById: (id: string) => CountryEntity | undefined
  createEntityByCountry: (country: Country) => CountryEntity
}

type UseCountries = () => {
  countries: [string, CountryEntity][]
  helpers: Helpers
}

export const useCountries: UseCountries = () => {
  const { i18n } = useTranslation()

  const { homeCountryCountryCode } = useCountryProviderContext()

  const isEuMember = (country_code: string) => euMembers.includes(country_code)

  const isForeignCountry = (country_code: string) => country_code !== homeCountryCountryCode

  const createEntityByCountry = (country: Country): CountryEntity => ({
    ...country,
    isEuMember: isEuMember(country.id),
    isForeignCountry: isForeignCountry(country.id),
  })

  const countries: [string, CountryEntity][] = useMemo(() => {
    const value = getCountryEntries(undefined, i18n.language)

    return value.map(([key, entry]) => [key, createEntityByCountry(entry)])
  }, [i18n.language])

  const helpers = {
    getCountryById: (id: string) => countries.find(([countryId]) => countryId === id)?.[1],
    createEntityByCountry,
  }

  return {
    countries,
    helpers,
  }
}
