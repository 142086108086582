import React, { FC, UIEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { Select } from 'antd'
import debounce from 'lodash/debounce'

import { FloatingLabel } from '@/components/Input'
import { QueryHookTypeWithRequiredArgs } from '@/containers/DataTable/types'

import styles from './styles.module.less'

const { Option } = Select

const initialClientFilters = {
  first: 10,
  page: 1,
  search: '',
}

export interface AutoCompleteInfiniteSelectProps {
  id?: string
  disabled?: boolean
  query: QueryHookTypeWithRequiredArgs
  onChange: (id: string, name: string, ...rest: any[]) => void
  defaultValue?: {
    id: string
    name: string
  }
  onClear?: () => void
  placeholder?: string
  label: string
  searchQuery?: string
  getLabelFrom?: string
}

interface ChildrenProps {
  id: string
  [key: string]: string
}

const AutoCompleteInfiniteSelect: FC<AutoCompleteInfiniteSelectProps> = ({
  defaultValue,
  onChange,
  onClear,
  query,
  disabled,
  placeholder,
  label: labelText,
  searchQuery = 'name',
  getLabelFrom = 'name',
  id,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(initialClientFilters)
  const [children, setChildren] = useState<ChildrenProps[]>([])
  const [item, setItem] = useState(
    defaultValue?.id ? { value: defaultValue.id, label: defaultValue.name } : {},
  )
  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    // set default value of Select if component received from props
    if (defaultValue) {
      onChange?.(defaultValue?.id?.toString(), defaultValue?.name?.toString())
    }
  }, [])

  const { data, loading } = query({
    fetchPolicy: 'network-only',
    variables: {
      first: selectedFilters.first,
      page: selectedFilters.page,
      [searchQuery]: selectedFilters.search,
    },
    onCompleted: (res: {
      [property: string]: {
        data: ChildrenProps[]
      }
    }) => {
      // get first object of response and set as options by id and dynamic name from props, default is name
      const options = res[Object.keys(res)[0]].data.map((option) => ({
        ...option,
        name: option[getLabelFrom],
      }))

      // check if input has letters and insert new data from back or if wasn't - save initial values + next 10 values (pagination)
      if (selectedFilters.search.length > 0 || selectedFilters.page === 1) {
        setChildren(options)
      } else {
        setChildren((prevState) => [...prevState, ...options])
      }
    },
  })

  const onScroll: UIEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as HTMLDivElement

    if (
      !loading &&
      Math.round(target.scrollTop + target.offsetHeight) === target.scrollHeight &&
      data[Object.keys(data)[0]].paginatorInfo.total > children.length
    ) {
      setSelectedFilters((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }))
    }
  }

  const debouncedOnSearchHandler = useMemo(() => {
    const debouncedFunc = (newValue: string) => {
      if (newValue === '') {
        setChildren([])
      }

      setSelectedFilters({
        page: 1,
        first: 10,
        search: newValue.length > 2 ? newValue : '',
      })
    }

    return debounce(debouncedFunc, 500)
  }, [setSelectedFilters])

  const onChangeHandler = useCallback(
    (value: string, option: { children: string } | Array<{ children: string }>) => {
      if (value && option) {
        const label = Array.isArray(option)
          ? option.map((o) => o.children).join(', ')
          : option.children
        setItem({ value, label })
        onChange?.(value, label)
      }
    },
    [setItem, children],
  )

  const onHandleClear = useCallback(() => {
    setItem({})

    setSelectedFilters(initialClientFilters)

    return onClear && onClear()
  }, [])

  const onBlur = useCallback(() => {
    setIsActive(false)
  }, [setIsActive])

  const onFocus = useCallback(() => {
    setIsActive(true)
  }, [setIsActive])

  const onDropdownVisibleChangeHandler = useCallback((open: boolean) => {
    document.body.style.overflow = open ? 'hidden' : ''
  }, [])

  const optionsJsx = children.map((child) => (
    <Option key={child.id} value={child.id}>
      {child.name}
    </Option>
  ))

  return (
    <FloatingLabel active={isActive} labelText={labelText} value={item.label}>
      <Select
        id={id}
        className={styles.select}
        onFocus={onFocus}
        onBlur={onBlur}
        showSearch
        allowClear
        onClear={onHandleClear}
        filterOption={false}
        notFoundContent={null}
        defaultActiveFirstOption={false}
        disabled={disabled}
        value={item.label}
        onChange={onChangeHandler}
        onPopupScroll={onScroll}
        onSearch={debouncedOnSearchHandler}
        onDropdownVisibleChange={onDropdownVisibleChangeHandler}
        placeholder={placeholder}
        loading={loading}
      >
        {optionsJsx}
      </Select>
    </FloatingLabel>
  )
}

export default AutoCompleteInfiniteSelect
