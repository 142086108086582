import React, { FunctionComponent } from 'react'

import { ChipItemProps } from '@/components/Chips/types'

export const SimpleChipItem: FunctionComponent<ChipItemProps> = ({
  children,
  onSelect,
  className,
}) => (
  <div onClick={onSelect} className={className}>
    {children}
  </div>
)
