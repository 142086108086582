import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Typography } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import {
  StudentPersonalInfoForm,
  StudentPersonalInfoFormType,
} from '@/forms/Auth/Registration/StudentPersonalInfoForm'
import { StudentRegistrationSteps } from '@/screens/AuthenticationScreens/StudentRegistrationScreens/components/StudentRegistrationSteps'

interface StudentPersonalInfoScreenProps {
  formProps?: StudentPersonalInfoFormType
  isOver18: boolean
  loading?: boolean
}

export const StudentPersonalInfoScreen: FC<StudentPersonalInfoScreenProps> = ({
  formProps,
  isOver18,
  loading,
}) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <StudentRegistrationSteps current={0} />
      </Col>
      <Col span={24}>
        <Row>
          <Col md={24} xs={21}>
            <Typography.Title level={breakpoint.xs ? 4 : 3}>
              {t('auth.register.talent.aboutYourself')}
            </Typography.Title>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <StudentPersonalInfoForm formProps={formProps} isOver18={isOver18} loading={loading} />
      </Col>
      <Col span={24} />
    </Row>
  )
}
