import { useState } from 'react'

import {
  ForgotPasswordMutationVariables,
  useForgotPasswordMutation,
} from '@/apollo/generated/graphql'
import { ForgotPasswordScreen, ForgotPasswordSuccessScreen } from '@/screens/AuthenticationScreens'

export const ForgotPasswordPage = () => {
  const [mutation, { loading }] = useForgotPasswordMutation()
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>()

  const onFinish = (values: ForgotPasswordMutationVariables) => {
    mutation({
      variables: values,
      onCompleted: () => {
        setForgotPasswordEmail(values.email)
      },
    })
  }

  return forgotPasswordEmail ? (
    <ForgotPasswordSuccessScreen email={forgotPasswordEmail} />
  ) : (
    <ForgotPasswordScreen submitting={loading} onFinish={onFinish} />
  )
}
