import React from 'react'
import { Input as AntdInput } from 'antd'
import { PasswordProps } from 'antd/es/input'

import { Icon } from '@/components/Icons'
import { FloatingLabelInput, FormItemProps } from '@/components/Input'

export const Password = ({ label, name, onChange, ...rest }: FormItemProps & PasswordProps) => (
  <FloatingLabelInput labelText={label} name={name} {...rest}>
    <AntdInput.Password
      onChange={onChange}
      size='large'
      iconRender={(visible) => <Icon size='md' name={visible ? 'EyeOpenSvg' : 'EyeClosedSvg'} />}
    />
  </FloatingLabelInput>
)
