import { UserRole } from '@/apollo/generated/graphql'
import { useRbac } from '@/rbac/hooks/useRbac'

const useRbacShortcuts = () => {
  const { satisfy } = useRbac()

  const isStudent = satisfy({
    role: ['has', UserRole.Student],
  })

  const isCompany = satisfy({
    role: ['has', UserRole.CompanyAdmin],
  })

  const isAdmin = satisfy({
    role: ['has', UserRole.Admin],
  })

  return {
    isStudent,
    isCompany,
    isAdmin,
  }
}

export default useRbacShortcuts
