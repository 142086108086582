import AntdIcon from '@ant-design/icons'

import * as Svg from './exports'

export type SvgName = keyof typeof Svg

export type IconParameters = Parameters<typeof AntdIcon>[0]

interface IconProps<SIZE extends string> extends Omit<IconParameters, 'size' | 'color'> {
  name: SvgName
  size?: SIZE
}

const sizes = {
  xs: { fontSize: '14px' },
  sm: { fontSize: '18px' },
  md: { fontSize: '23px' },
  lg: { fontSize: '25px' },
  xl: { fontSize: '30px' },
  xxl: { fontSize: '36px' },
}

const defaultStyles = {
  color: 'transparent',
}

export const Icon = ({ name, size, style, ...rest }: IconProps<keyof typeof sizes>) => {
  const styles = { ...(style || {}), ...{ fontSize: size ? sizes[size].fontSize : 'inhrerit' } }
  return <AntdIcon component={Svg[name]} {...rest} style={{ ...styles, ...defaultStyles }} />
}

const LanguageIcon = (props: IconProps<keyof typeof sizes>) => <Icon {...props} />

export const GBLanguageIcon = (props?: Omit<IconProps<keyof typeof sizes>, 'name'>) => (
  <LanguageIcon {...props} name='GbFlagSvg' />
)
