import React, { FC } from 'react'
import { ApolloClient, ApolloLink, ApolloProvider as Provider, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { notification } from 'antd'
import { createUploadLink } from 'apollo-upload-client'

import { useAuthToken } from '@/providers/AuthenticationProvider/hooks/useAuthToken'
import { i18n } from '@/services'

interface ApolloProviderProps {
  children: React.ReactNode
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions }) => {
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: `)

      const validation = extensions.validation as { name: string[] }

      validation?.name?.map((err) =>
        notification.error({
          message: err,
          duration: Math.max(message.length / 10, 3),
        }),
      )

      if (!validation?.name?.length) {
        notification.error({
          message,
          duration: Math.max(message.length / 10, 3),
        })
      }
    })
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: `)
  }
})

const httpLink = createUploadLink({
  uri: `${import.meta.env.VITE_APP_BACKEND_URL}graphql`,
})

const authMiddleware = (authToken?: string) =>
  new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
          language: i18n.language,
        },
      })
    }

    return forward(operation)
  })

const languageMiddleware = () =>
  new ApolloLink((operation, forward) => {
    if (i18n.language) {
      operation.setContext({
        ...operation.getContext(),
        headers: {
          ...operation.getContext()?.headers,
          language: i18n.language,
        },
      })
    }

    return forward(operation)
  })

const useApolloClient = () => {
  const [authToken] = useAuthToken()

  return new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      languageMiddleware(),
      authMiddleware(authToken).concat(httpLink),
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

export const ApolloProvider: FC<ApolloProviderProps> = ({ children }) => {
  const client = useApolloClient()

  return <Provider client={client}>{children}</Provider>
}
