import { CompanyUserSidebarMenuCallsToActionsFragment } from '@/apollo/generated/graphql'

// MOCK
const useCallToActionsMockedNotifications = () => {
  const loading = false
  const callsToActions: CompanyUserSidebarMenuCallsToActionsFragment['notificationsCallsToAction'] =
    {
      jobs: {
        callsToActionCount: 0,
        vacancies: [],
        applications: [],
        inProgress: [],
        archive: [],
      },
      profile: {
        callsToActionCount: 0,
      },
      documents: {
        callsToActionCount: 0,
      },
      reviews: {
        callsToActionCount: 0,
      },
    }

  const readNotification = () => {}
  const refetch = () => {}

  const dashboardNotificationsCount = 0
  const jobsNotificationsCount = callsToActions?.jobs?.callsToActionCount
  const documentsNotificationsCount = callsToActions?.documents?.callsToActionCount
  const reviewsNotificationsCount = callsToActions?.reviews?.callsToActionCount
  const profileNotificationsCount = callsToActions?.profile?.callsToActionCount

  return {
    loading,
    callsToActions,
    dashboardNotificationsCount,
    jobsNotificationsCount,
    documentsNotificationsCount,
    reviewsNotificationsCount,
    profileNotificationsCount,
    readNotification,
    refetch,
  }
}

export default useCallToActionsMockedNotifications
