import React from 'react'
import { Dropdown } from 'antd'

import { UserAvatar } from '@/containers/avatar/UserAvatar'
import { ChatHeaderMenuButton } from '@/containers/Buttons/HeaderMenu/ChatHeaderMenuButton'
import { NotificationHeaderMenuButton } from '@/containers/Buttons/HeaderMenu/NotificationHeaderMenuButton'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

import { HeaderButtons } from './components/HeaderButtons'
import { UserActionsDropDown } from './components/UserActionsDropDown'
import actionsStyles from './actions.module.less'

export const Actions = () => {
  const { xs } = useBreakpoints()

  const { isAdmin } = useRbacShortcuts()

  return (
    <div className={actionsStyles.wrapper}>
      {!isAdmin && (
        <>
          <NotificationHeaderMenuButton />

          <ChatHeaderMenuButton />
        </>
      )}

      <Dropdown
        arrow
        overlay={<UserActionsDropDown />}
        trigger={['click']}
        placement='bottomLeft'
        data-cy='cy-user-actions-dropdown'
      >
        <div>
          <UserAvatar />
        </div>
      </Dropdown>

      {!xs && <HeaderButtons />}
    </div>
  )
}
