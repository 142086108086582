import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Icon } from '@/components/Icons'

import styles from './styles.module.less'

interface GoBackLinkProps {
  onClick?: () => void
}

export const GoBackLink: FC<GoBackLinkProps> = ({ onClick }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <span className={styles.go_back_link} onClick={onClick ?? (() => navigate(-1))}>
      <Icon name='ArrowLeftSvg' size='lg' />
      <span>{t('navigation.previous')}</span>
    </span>
  )
}
