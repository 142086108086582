import { useContext } from 'react'

import { CompanyOnboardingContext } from '@/pages/AuthenticationPages/RegistrationPages/company-onboarding/company-onboarding-container'
import { RegistrationCompletedScreen } from '@/screens/AuthenticationScreens'

export const CompanyRegistrationCompletePage = () => {
  const companyOnboardingContext = useContext(CompanyOnboardingContext)

  return (
    <RegistrationCompletedScreen email={companyOnboardingContext.form.getFieldValue('email')} />
  )
}
