import React, {
  Children,
  FC,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useCallback,
} from 'react'
import { Divider, Space } from 'antd'

import { ClearAutoCompleteResults } from '@/components/Autocomplete/AutocompleteResults/ClearAutoCompleteResults'
import {
  AutocompleteResultsItem,
  AutocompleteResultsItemProps,
} from '@/components/Autocomplete/AutocompleteResults/Item'

import styles from './styles.module.less'

type OnSelect = (value: AutocompleteResultsItemProps['value']) => void

interface AutocompleteResultsComposition {
  Item: FunctionComponent<PropsWithChildren<AutocompleteResultsItemProps>>
}

export interface AutocompleteResultsProps {
  onSelect: OnSelect
  onClear?: () => void
  clearText?: Parameters<typeof ClearAutoCompleteResults>[0]['children']
  children: ReactElement<PropsWithChildren<AutocompleteResultsItemProps>>[]
  showClearComponent?: boolean
}

export const AutocompleteResults: FC<AutocompleteResultsProps> & AutocompleteResultsComposition = ({
  children,
  onSelect,
  onClear,
  clearText,
  showClearComponent = true,
}) => {
  const totalResults = Children.toArray(children).length

  const onSelectItemHandler = useCallback(
    (...props: Parameters<OnSelect>) =>
      () => {
        const [value] = props
        onSelect(value)
      },
    [onSelect],
  )

  return (
    <Space direction='vertical' size={16} className={styles.container}>
      {totalResults && showClearComponent && (
        <ClearAutoCompleteResults onClick={onClear}>{clearText}</ClearAutoCompleteResults>
      )}
      <div>
        {Children.map(children, (child, index) => {
          if (child.type === AutocompleteResultsItem) {
            return (
              <>
                <div
                  key={child.props.value}
                  onClick={onSelectItemHandler(child.props.value)}
                  className={styles.item}
                >
                  <div>{child}</div>
                </div>
                {index < totalResults - 1 && !(totalResults === 1) && <Divider />}
              </>
            )
          }

          throw new Error('Please use this component AutocompleteResultsItem')
        })}
      </div>
    </Space>
  )
}

AutocompleteResults.Item = AutocompleteResultsItem
