import { FC, ReactNode } from 'react'
import { Typography } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

interface TitleProps {
  children: ReactNode
}

export const Title: FC<TitleProps> = ({ children }) => {
  const breakpoints = useBreakpoint()

  const level = () => {
    if (breakpoints.md) {
      return 2
    }

    if (breakpoints.sm) return 3

    if (breakpoints.xs) return 4

    return 2
  }

  return (
    <Typography.Title level={level()} className='no-margin text-center'>
      {children}
    </Typography.Title>
  )
}
