import { MenuItems, useExistingMenuItems } from './useExistingMenuItems'

export const useStudentMenuItems = (): MenuItems => {
  const [, getMenuItemByName] = useExistingMenuItems()

  return [
    getMenuItemByName('home'),

    // getMenuItemByName('jobs'),
    [
      getMenuItemByName('assignments'),
      [
        getMenuItemByName('tab-assignments-applications'),
        getMenuItemByName('tab-assignments-in-progress'),
        getMenuItemByName('tab-assignments-archive'),
      ],
    ],
    getMenuItemByName('search-job'),
    [
      getMenuItemByName('marketplace'),
      [
        getMenuItemByName('tab-marketplace-projects'),
        getMenuItemByName('tab-marketplace-proposals'),
        getMenuItemByName('tab-marketplace-in_progress'),
        getMenuItemByName('tab-marketplace-archive'),
      ],
    ],
    getMenuItemByName('reviews'),
    [
      getMenuItemByName('documents'),
      [
        getMenuItemByName('invoices-documents'),
        getMenuItemByName('agreements-documents'),
        getMenuItemByName('annual-overviews-documents'),
      ],
    ],
  ]
}
