import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Steps } from 'antd'

interface CompanyRegistrationStepsProps {
  current: number
}

export const CompanyRegistrationSteps: FC<CompanyRegistrationStepsProps> = ({ current }) => {
  const { t } = useTranslation()
  return (
    <Steps size='small' current={current}>
      <Steps.Step title={t('auth.register.secondStep')} />
      <Steps.Step title={t('auth.register.firstStep')} />
    </Steps>
  )
}
