import { FC } from 'react'
import { Select as AntdSelect } from 'antd'
import { BaseOptionType, DefaultOptionType } from 'antd/es/select'

import styles from './styles.module.less'

type Value = { id: string; name: string }

export type SelectProps = {
  onChange?: (...rest: any[]) => void
  value?: Value
  getSelectedValue?: (id: string) => object | undefined
}

export const Select: FC<SelectProps & { options: [string, string][] }> = ({
  onChange,
  value,
  options,
  getSelectedValue,
  ...rest
}) => {
  const onSelect = (_: string, option: BaseOptionType | DefaultOptionType) => {
    onChange?.(
      getSelectedValue?.(option.value) || {
        id: option.value,
        name: option.children,
      },
    )
  }

  const onClear = () => {
    onChange?.(null)
  }

  const onFilterOption = (input: string, option: BaseOptionType | DefaultOptionType | undefined) =>
    (option?.children ?? '').toLowerCase().startsWith(input.toLowerCase())

  return (
    <AntdSelect
      className={styles.select}
      onSelect={onSelect}
      onClear={onClear}
      allowClear
      showSearch
      filterOption={onFilterOption}
      value={value?.name}
      {...rest}
    >
      {options.map(([optionValue, optionLabel]) => (
        <AntdSelect.Option key={optionValue} value={optionValue}>
          {optionLabel}
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  )
}
