import { Navigate, RouteObject } from 'react-router-dom'

import { AuthenticationRoutes } from '@/router/AuthenticationRoutes'

const authRedirectRoutes = (routes: RouteObject[]) =>
  routes.reduce((result: RouteObject[], route: RouteObject) => {
    // If the current route has children, recursively call this function to modify its children
    if (route.children) {
      result.push({
        ...route,
        children: authRedirectRoutes(route.children),
      })
    } else if (!route.path || route.path === '*' || route.index === true) {
      // Exclude routes with path: "*", no path specified, or index: true
      return result
    } else {
      const element = <Navigate replace to='/' />

      result.push({
        ...route,
        element,
      })
    }

    return result
  }, [])

export default authRedirectRoutes(AuthenticationRoutes.children)
