import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@ant-design/icons'
import { Empty, Row, Space, Typography } from 'antd'

import {
  ChamberOfCommerceDetailsQuery,
  ChamberOfCommerceQuery,
  CompanyByCocNumberDetails,
  useChamberOfCommerceDetailsLazyQuery,
  useChamberOfCommerceLazyQuery,
} from '@/apollo/generated/graphql'
import { Autocomplete } from '@/components/Autocomplete'
import { AutocompleteResults } from '@/components/Autocomplete/AutocompleteResults'
import { AutocompleteResultsItemProps } from '@/components/Autocomplete/AutocompleteResults/Item'
import { Link } from '@/components/Link'
import { regexRules } from '@/forms/helpers'
import { Rule, useValidationRules } from '@/forms/useValidationRules'
import { Text } from '@/shared/typography'

interface CompanyKvkSearchContainerProps {
  onSelect?: (v: CompanyByCocNumberDetails) => void
  onSkip?: () => void
  onLoading?: (loading: boolean) => void
  autoCompleteProps?: React.ComponentProps<typeof Autocomplete>
  label?: ReactNode
  maxLength?: number

  required?: boolean
  noFormatLimitations?: boolean
}

const emptyList = (
  <AutocompleteResults.Item value={''}>
    <Row justify='center'>
      <Space direction='horizontal' size={1}>
        <Empty description='' />
      </Space>
    </Row>
  </AutocompleteResults.Item>
)

export const CompanyKvkSearchContainer: FC<CompanyKvkSearchContainerProps> = ({
  onSelect,
  onSkip,
  onLoading,
  autoCompleteProps,
  label,
  maxLength,
  ...rest
}) => {
  const { t } = useTranslation()
  const [list, setList] = useState<React.ReactNode[]>([])

  const { required, cocNumber } = useValidationRules()

  // TODO: refactor: simplify and/or move out of the component
  const handleSummaryResults = (data: ChamberOfCommerceQuery) => {
    const items = data.searchCompaniesSummaryByCocNumber.map((value) => (
      <AutocompleteResults.Item value={value.coc_number}>
        <Space direction='vertical' size={1} data-cy={'kvk-result'}>
          <Typography.Text strong>{value.legal_name}</Typography.Text>
          <Typography.Text>{value.coc_number}</Typography.Text>
          <Typography.Text className='font-small'>
            {value.street}, {value.city}
          </Typography.Text>
        </Space>
      </AutocompleteResults.Item>
    ))

    if (items.length === 0) {
      setList([
        <AutocompleteResults.Item value=''>
          <div>
            <Link
              isExternal
              onClick={(e) => {
                e.preventDefault()
                onSkip?.()
              }}
              to=''
              text={t('auth.register.kvkSearchSkip')}
            />
          </div>
        </AutocompleteResults.Item>,
      ])
      return
    }

    setList(items)
  }

  const [requestQuery, { data: summaryData, loading: loadingSummary }] =
    useChamberOfCommerceLazyQuery({
      notifyOnNetworkStatusChange: true,
      onCompleted: handleSummaryResults,
    })

  const handleDetailsReceived = (data: ChamberOfCommerceDetailsQuery) => {
    if (data?.searchCompanyDetailsByCocNumber) {
      onSelect?.({ ...data.searchCompanyDetailsByCocNumber })
    }
  }

  const [requestDetailsQuery, { loading: loadingDetails }] = useChamberOfCommerceDetailsLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => handleDetailsReceived(data),
  })

  useEffect(() => {
    onLoading?.(loadingDetails || loadingSummary)
  }, [loadingDetails, loadingSummary])

  const onInputChangeHandler = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (regexRules.coc.test(event.target.value)) {
        await requestQuery({
          variables: {
            cocNumber: event.target.value,
          },
        })
      }
    },
    [requestQuery],
  )

  const onSelectedHandler = useCallback(
    async (value: AutocompleteResultsItemProps['value']) => {
      if (regexRules.coc.test(value.toString())) {
        await requestDetailsQuery({
          variables: {
            cocNumber: value.toString(),
          },
        })
      }
    },
    [requestDetailsQuery],
  )

  // TODO: FIX Types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  const rules: Rule[] = []

  if (!rest.noFormatLimitations) {
    rules.push(...cocNumber)
  }

  if (rest.required) {
    rules.push(required)
  }

  return (
    <Autocomplete
      name='coc_number'
      rules={rules}
      label={
        label ?? (
          <>
            <SearchOutlined /> {t('common.chamberOfCommerceNumber')}{' '}
            {!rest.required && (
              <Text small italic>
                ({t('form.optional')})
              </Text>
            )}
          </>
        )
      }
      inputProps={{ showCount: true, maxLength }}
      onSearch={onInputChangeHandler}
      {...autoCompleteProps}
    >
      <AutocompleteResults
        showClearComponent={list.length > 0}
        onSelect={onSelectedHandler}
        onClear={() => {
          setList([])
        }}
        clearText={
          <>
            <Typography.Text strong>
              ({summaryData?.searchCompaniesSummaryByCocNumber.length ?? 0})
            </Typography.Text>{' '}
            {t('auth.register.kvkSearchResultsFound')}
          </>
        }
      >
        {list.length > 0 ? [...list] : [emptyList]}
      </AutocompleteResults>
    </Autocomplete>
  )
}
