import generatePicker, { PickerProps } from 'antd/es/date-picker/generatePicker'
import parse from 'date-fns/parse'
// eslint-disable-next-line import/no-extraneous-dependencies
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

import { birthDateDotsFormat, birthDateFormat, birthDateSlashFormat } from '@/constants/dates'

import 'antd/es/date-picker/style/index'

const Picker = generatePicker<Date>(dateFnsGenerateConfig)

const getDateValue = (dateString: string, formats: string[]) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < formats.length; i++) {
    const date = parse(dateString, formats[i], new Date())

    if (date.toString() !== 'Invalid Date') {
      return date
    }
  }

  return false
}

export const DatePicker = (props: PickerProps<Date>) => {
  const { onChange } = props
  const dateFormatList = [birthDateFormat, birthDateSlashFormat, birthDateDotsFormat]

  return (
    <Picker
      format={dateFormatList}
      onChange={onChange}
      onBlur={(event) => {
        const date = getDateValue(event.target.value, dateFormatList)

        if (date && date.toString() !== 'Invalid Date') {
          onChange?.(date, event.target.value)
        }
      }}
      {...props}
    />
  )
}
