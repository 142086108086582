import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Noob from '@/components/Noob'

export const WpSitePage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(-1)
    return () => {
      window.open(import.meta.env.VITE_APP_WP, '_blank')
    }
  }, [])

  return <Noob />
}
