import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FreelancerPage } from '@kickjobs/search-marketplace-module'
import isEmpty from 'lodash/isEmpty'

import { AvatarSize, usePublicStudentQuery } from '@/apollo/generated/graphql'
import { formatDateDefaultFormat } from '@/helpers/dates'
import { getMetaShortDescription } from '@/helpers/getMetaShortDescription'
import MetaTagsProvider from '@/layouts/provider/MetaTagsProvider'
import { useUserState } from '@/providers/AuthenticationProvider/hooks/useUserState'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'
import { LoadingScreen } from '@/screens/CommonScreens'

import useTranslatedSearchLayoutStructure from '../staticData'

export const MarketplaceStudentPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { navigation, footer, footerBottom } = useTranslatedSearchLayoutStructure()
  const { studentName = '' } = useParams()
  const { isAuthenticated } = useUserState()
  const { isStudent } = useRbacShortcuts()

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    })
  }, [])

  const { data, loading } = usePublicStudentQuery({
    variables: {
      publicId: studentName,
    },
  })

  if (data?.publicStudent === null) {
    navigate(`/marketplace`)
  }

  if (data?.publicStudent) {
    const {
      id,
      firstName,
      lastName,
      avatar,
      avatars,
      jobCategories,
      skills,
      languages,
      educations,
      projects,
      publicId,
      about_me: aboutMe,
      work_experiences: workExperiences,
      portfolio_items: portfolioItems,
      received_reviews: receivedReviews,
      star_rating: starRating,
    } = data.publicStudent

    const allSpecializationNames: string[] = jobCategories
      ? Array.from(
          jobCategories.reduce((set: Set<string>, jobCategory) => {
            jobCategory?.specializations?.forEach((spec) => set.add(spec.name))
            return set
          }, new Set<string>()),
        )
      : []

    const parsedJobData = {
      freelancer: {
        title: `${firstName} ${lastName}`,
        description: aboutMe?.description || undefined,
        avatar:
          avatars?.find((img) => img.type === AvatarSize.OriginalAvatar)?.path ||
          avatar ||
          'https://fakeimg.pl/300x300/?text=user',
        pricePerHour: `${aboutMe?.desired_hourly_rate?.toFixed(2).replace('.', ',') || '-'}`,
        skills: skills?.map((skill) => skill.skill.name),
        totalRate: `${starRating}`,
        totalJobs: `${projects?.length ?? 0}`,
        categories: jobCategories?.map((category) => category.name),
        specialities: allSpecializationNames,
        reviewsCount: `${receivedReviews?.paginatorInfo.total}`,
        chatLink: isAuthenticated && !isStudent ? `/talents/${id}/chat` : '/',
        reviews: !isEmpty(receivedReviews?.data)
          ? receivedReviews?.data.map(({ jobInfo, comment, created_at, star_rating: rate }) => ({
              title: jobInfo.title,
              rate: `${rate}`,
              postedTime: created_at,
              description: comment,
            }))
          : undefined,
        languages: languages?.map(({ name, level }) => ({
          language: name,
          level: t(`common.languagesLevels.${level}`),
          native: level === 'native',
        })),
        portfolio: !isEmpty(portfolioItems)
          ? portfolioItems?.map(({ title, images, id: portfolioId }) => ({
              title,
              portfolioLink: `/marketplace/${publicId}/${encodeURIComponent(
                title?.replace(/\s/g, ''),
              )}-${portfolioId}`,
              mainImage: images[0]?.path || 'https://fakeimg.pl/300x300/?text=project',
              images: images.map((image) => image?.path || ''),
            }))
          : undefined,
        projects: projects?.map(
          ({ id: projectId, title: projectTitle, description: projectsDescription, amount }) => ({
            title: projectTitle,
            description: projectsDescription,
            price: `${amount}`,
            projectLink: `/marketplace/${publicId as string}/${encodeURIComponent(
              projectTitle.replace(/\s/g, ''),
            )}-${projectId}`,
          }),
        ),
        workHistory: workExperiences?.map(({ position, start, end, description }) => ({
          title: position,
          period: {
            start: formatDateDefaultFormat(start),
            end: end ? formatDateDefaultFormat(end) : t('common.present'),
          },
          description,
        })),
        education: educations?.map(({ institute, start, end, description }) => ({
          title: institute,
          period: {
            start: formatDateDefaultFormat(start),
            end: end ? formatDateDefaultFormat(end) : t('common.present'),
          },
          description,
        })),
      },
    }

    return (
      <MetaTagsProvider
        title={`${data.publicStudent.firstName as string} ${
          data?.publicStudent.lastName as string
        } | ${t('meta.marketplace.profileKickjobs')}`}
        metaDescription={getMetaShortDescription(
          data.publicStudent.about_me?.description as string,
        )}
      >
        <div data-module>
          <FreelancerPage
            content={parsedJobData}
            loading={loading}
            headerNavigation={navigation}
            footerItems={footer}
            footerBottomItems={footerBottom}
          />
        </div>
      </MetaTagsProvider>
    )
  }
  return <LoadingScreen loading />
}
