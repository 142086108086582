import loadable from '@loadable/component'

import { LoadingScreen } from '@/screens/CommonScreens'

export const EmailConfirmationPage = loadable(() =>
  import('@/pages/CommonPages/EmailConfirmationPage').then((module) => ({
    default: module.EmailConfirmationPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const NotFoundPage = loadable(() =>
  import('@/pages/CommonPages/NotFoundPage').then((module) => ({
    default: module.NotFoundPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const ToDoPage = loadable(() =>
  import('@/pages/CommonPages/ToDoPage').then((module) => ({
    default: module.ToDoPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          