import React, { useEffect } from 'react'
import ReactHelmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ReactFCWithChildren } from '@/react'

interface MetaTagsProviderProps {
  title: string
  metaDescription: string
}

const MetaTagsProvider: ReactFCWithChildren<MetaTagsProviderProps> = ({
  title,
  metaDescription,
  children,
}) => {
  const { i18n } = useTranslation()
  const location = useLocation()

  useEffect(() => {
    if (import.meta.env.VITE_APP_ENV === 'production' && location.pathname !== '/') {
      window.location.replace(import.meta.env.VITE_APP_KICKJOBS_OFFLINE)
    }
  }, [location.pathname])

  return (
    <>
      <ReactHelmet htmlAttributes={{ lang: i18n.language }}>
        <title>{title}</title>
        <meta name='description' content={metaDescription} />
      </ReactHelmet>
      {children}
    </>
  )
}

export default MetaTagsProvider
