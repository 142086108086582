import React, { ChangeEventHandler, useRef, useState } from 'react'
import { Form, FormItemProps, Input as AntdInput } from 'antd'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface'

import {
  AutocompleteResults,
  AutocompleteResultsProps,
} from '@/components/Autocomplete/AutocompleteResults'
import { Search } from '@/components/Input'
import { useClickOutsideRef } from '@/hooks/useClickOutsideRef'

import styles from './styles.module.less'

interface AutocompleteProps {
  debounce?: boolean
  children: React.ReactNode
  name: Exclude<FormItemProps['name'], undefined>
  label?: FormItemProps['label']
  onSearch: ChangeEventHandler<HTMLInputElement>
  inputProps?: React.ComponentProps<typeof AntdInput>
  rules?: Rule[]
}

export const Autocomplete = ({
  name,
  children,
  onSearch,
  inputProps,
  label = 'Search',
  rules,
}: AutocompleteProps) => {
  const itemIsSelectedRef = useRef<boolean>(false)
  const [showResults, setShowResults] = useState(false)

  const onFocusHandler = () => {
    setShowResults(true)
  }

  const hideDropdown = () => {
    setShowResults(false)
  }

  const ref = useClickOutsideRef<HTMLDivElement>(hideDropdown)

  const renderField: FormItemProps['children'] = ({ setFieldValue }) => (
    <div className={styles.container} ref={ref}>
      <Search
        name={name}
        label={label}
        rules={rules}
        inputProps={{
          ...inputProps,
          onFocus: onFocusHandler,
          onChange: onSearch,
        }}
      />

      {showResults &&
        React.isValidElement(children) &&
        children.type === AutocompleteResults &&
        React.cloneElement(
          children as React.ReactElement,
          {
            onSelect: (value) => {
              itemIsSelectedRef.current = true
              children.props.onSelect(value)
              setFieldValue(name, value)
              hideDropdown()
            },
          } as AutocompleteResultsProps,
        )}
    </div>
  )

  return (
    <div>
      <Form.Item shouldUpdate children={renderField} />
    </div>
  )
}
