import { MenuItems, useExistingMenuItems } from './useExistingMenuItems'

export const useCompanyMenuItems = (): MenuItems => {
  const [, getMenuItemByName] = useExistingMenuItems()

  return [
    getMenuItemByName('home'),
    [
      getMenuItemByName('home-vacancies'),
      [
        getMenuItemByName('tab-vacancies'),
        getMenuItemByName('tab-applications'),
        getMenuItemByName('tab-in-progress'),
        getMenuItemByName('tab-archive'),
      ],
    ],
    // getMenuItemByName('jobs'),
    // getMenuItemByName('create-job'),
    // getMenuItemByName('vacancies'),
    getMenuItemByName('create-vacancy'),
    [
      getMenuItemByName('marketplace'),
      [
        getMenuItemByName('tab-marketplace-search'),
        getMenuItemByName('tab-marketplace-proposals'),
        getMenuItemByName('tab-marketplace-in_progress'),
        getMenuItemByName('tab-marketplace-archive'),
      ],
    ],
    getMenuItemByName('reviews'),
    [
      getMenuItemByName('documents'),
      [getMenuItemByName('invoices-documents'), getMenuItemByName('agreements-documents')],
    ],
  ]
}
