export const defaultDateTimeFormat = 'dd-MM-yyyy / HH:mm'
export const dateTimeFormat = 'DD MMM YYYY HH:mm'
export const dateTimeChatFormat = 'yyyy-MM-dd HH:mm:ss'
export const dateFormat = 'dd LLL yyyy'
export const tableDateFormat = 'DD MMMM YYYY - HH:mm'
export const backendDateFormat = 'yyyy-MM-dd'
export const backendDateFormatWithTime = 'yyyy-MM-dd HH:mm:ss'
export const hoursFormat = 'HH:mm'
export const onlyDateFormat = 'dd-MM-yyyy'
export const birthDateFormat = 'dd-MM-y'
export const birthDateDotsFormat = 'dd.MM.y'
export const birthDateSlashFormat = 'dd/MM/y'
export const jobCardsDateFormat = 'dd MMM y'
export const monthYearFormat = 'MMM Y'
export const defaultDateFormat = 'dd-MM-Y'
export const hoursMinutes = 'HH:mm'
