import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DataRequirements } from '@/components/DataRequirements'

interface PasswordDataRequirementsProps {
  hasCapitalLetter: boolean
  hasNumberLetter: boolean
  hasSpecialChar: boolean
  hasMinLength: boolean
  inputsMatch: boolean
}

export const PasswordDataRequirements: FC<PasswordDataRequirementsProps> = ({
  hasCapitalLetter,
  hasNumberLetter,
  hasSpecialChar,
  hasMinLength,
  inputsMatch,
}) => {
  const { t } = useTranslation()
  return (
    <DataRequirements>
      <DataRequirements.Title>
        {t('auth.createPassword.passwordRequirementsText.title')}
      </DataRequirements.Title>
      <DataRequirements.Item done={hasCapitalLetter}>
        {t('auth.createPassword.passwordRequirementsText.capitalLetterChar')}
      </DataRequirements.Item>
      <DataRequirements.Item done={hasNumberLetter}>
        {t('auth.createPassword.passwordRequirementsText.numberChar')}
      </DataRequirements.Item>
      <DataRequirements.Item done={hasSpecialChar}>
        {t('auth.createPassword.passwordRequirementsText.specialChar')}
      </DataRequirements.Item>
      <DataRequirements.Item done={hasMinLength}>
        {t('auth.createPassword.passwordRequirementsText.minLength')}
      </DataRequirements.Item>
      <DataRequirements.Item done={inputsMatch}>
        {`${t('common.password')} = ${t('settings.repeat_password')}`}
      </DataRequirements.Item>
    </DataRequirements>
  )
}
