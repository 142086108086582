import { startTransition } from 'react'
import { useCookies } from 'react-cookie'

const TOKEN = 'authToken'
const ADMIN_TOKEN = 'authAdminToken'

export const useAuthToken = (): [
  string | undefined,
  (authToken: string) => void,
  () => void,
  (authAdminToken: string) => void,
  () => void,
  string | undefined,
] => {
  const [cookies, setCookie, removeCookie] = useCookies<
    string,
    {
      authToken?: string
      authAdminToken?: string
    }
  >([TOKEN, ADMIN_TOKEN])

  const setAuthAdminToken = (authAdminToken: string) =>
    startTransition(() => {
      setCookie(ADMIN_TOKEN, authAdminToken, {
        path: '/',
      })
    })

  const removeAuthAdminToken = () =>
    startTransition(() => {
      removeCookie(ADMIN_TOKEN, {
        path: '/',
      })
    })

  const setAuthToken = (authToken: string) =>
    startTransition(() => {
      setCookie(TOKEN, authToken, {
        path: '/',
      })
    })

  const removeAuthToken = () =>
    startTransition(() => {
      removeCookie(TOKEN, {
        path: '/',
      })
    })

  return [
    cookies[TOKEN],
    setAuthToken,
    removeAuthToken,
    setAuthAdminToken,
    removeAuthAdminToken,
    cookies[ADMIN_TOKEN],
  ]
}
