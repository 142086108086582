// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidatorRule } from 'rc-field-form/lib/interface'

import { validatePhoneNumber } from '@/shared/phonenumber/lib/validatePhoneNumber'

const ERROR_MESSAGE = 'PHONE NUMBER IS NOT VALID'

export const phoneNumberValidator = (message?: string): ValidatorRule => ({
  validator: (_, phoneNumber: string) =>
    new Promise((resolve, reject) => {
      if (validatePhoneNumber(phoneNumber) || !phoneNumber) {
        resolve(true)
      }
      reject(new Error(message ?? ERROR_MESSAGE))
    }),
})
