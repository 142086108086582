import { useTranslation } from 'react-i18next'

import { Button } from '@/components/Button'

export const SearchJobsButton = ({ block = false }) => {
  const [t] = useTranslation()

  const navigate = () => {
    window.open('/jobs', '_blank')
  }

  return (
    <Button type='primary' onClick={navigate} block={block} data-cy={'search-jobs-btn'}>
      {t('dashboard.student.search_jobs')}
    </Button>
  )
}
