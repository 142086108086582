import React, { forwardRef, useEffect, useRef } from 'react'
import { InputNumber as AntdInputNumber } from 'antd'

import { FloatingLabelInput, FormItemProps } from '@/components/Input'
import { makeId } from '@/utils/makeId'

type NumberInputProps = FormItemProps & {
  inputProps?: React.ComponentProps<typeof AntdInputNumber>
}

export const NumberInput = forwardRef<HTMLInputElement | null, NumberInputProps>((props, ref) => {
  const { label, name, inputProps, ...rest } = props

  const id = useRef(name ?? makeId(5))

  const handleWheel = (event: WheelEvent) => {
    event.preventDefault()
  }

  useEffect(() => {
    const input$ = document.getElementById(id.current.toString())
    input$?.addEventListener('wheel', handleWheel, { passive: false })

    return () => {
      input$?.removeEventListener('wheel', handleWheel)
    }
  }, [])

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <FloatingLabelInput labelText={label} name={name} {...rest}>
      <AntdInputNumber
        id={id.current.toString()}
        type='number'
        lang='nl'
        controls={false}
        ref={ref}
        onWheel={numberInputOnWheelPreventChange}
        {...inputProps}
      />
    </FloatingLabelInput>
  )
})
