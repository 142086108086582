import { FC, useEffect, useState } from 'react'
import { Col, Row } from 'antd'

import { CardSelection } from '@/components/Cards/CardSelection'

import styles from './styles.module.less'

interface SelectionVariant extends Pick<Parameters<typeof CardSelection>[0], 'imageSrc' | 'text'> {
  name: string
}

type AccountTypeSelectionProps = {
  defaultVariant: string
  variants: [SelectionVariant, ...SelectionVariant[]]
  onSelected?: (name: string) => void
}
export const AccountTypeSelection: FC<AccountTypeSelectionProps> = ({
  defaultVariant,
  variants,
  onSelected,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(defaultVariant)

  const onClickHandler = (name: string) => () => {
    setSelectedVariant(name)
  }

  useEffect(() => {
    onSelected?.(selectedVariant)
  }, [selectedVariant])

  return (
    <Row gutter={[8, 0]} className={styles.mobileColumn}>
      {variants.map(({ name, ...variant }) => (
        <Col flex={'1 1'} key={name}>
          <div onClick={onClickHandler(name)} id={`${name}-account-type`}>
            <CardSelection {...variant} active={name === selectedVariant} />
          </div>
        </Col>
      ))}
    </Row>
  )
}
