import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

type Theme = 'dark' | 'light'

type ThemeProps = {
  theme: Theme
}

type ThemeConfigContextType = {
  theme: Theme
  setTheme: Dispatch<SetStateAction<Theme>>
  defaultTheme: Theme
}

const ThemeConfigContext = createContext<ThemeConfigContextType | null>(null)

export const useThemeConfig = () => {
  const context = useContext(ThemeConfigContext)

  if (!context) {
    throw new Error(
      'The ThemeConfigContext should only be used if the ThemeConfigProvider has been initialized',
    )
  }

  return context
}

export const ThemeConfigProvider: FC<PropsWithChildren<ThemeProps>> = ({
  children,
  theme: defaultTheme,
}) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme)

  const childrenProps = { className: 'application', 'data-app': 'true', 'data-theme': theme }

  return (
    <ThemeConfigContext.Provider value={{ theme, setTheme, defaultTheme }}>
      <div {...childrenProps}>{children}</div>
    </ThemeConfigContext.Provider>
  )
}

export const withTheme =
  <T extends object>(WrappedComponent: React.ComponentType<T>, theme: Theme): FC<T> =>
  (props) => {
    const { setTheme, defaultTheme } = useThemeConfig()

    useEffect(() => {
      setTheme(theme)

      return () => {
        setTheme(defaultTheme)
      }
    }, [theme])

    return <WrappedComponent {...props} />
  }
