import { FC, ReactNode } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import clsx from 'classnames'

import { Logo } from '@/components/Logo'
import withHelmet from '@/layouts/hocs/withHelmet'

import styles from './styles.module.less'

interface EmptyLayoutProps {
  children?: ReactNode
}

const EmptyLayout: FC<EmptyLayoutProps> = ({ children })  => {
  const breakpoints = useBreakpoint()

  return (
    <Layout
      className={clsx(styles.layout, {
        [styles.layout__mobile]: breakpoints.xs,
      })}
    >
      <Layout.Header>
        <Link to={'/'}>
          <Logo />
        </Link>
      </Layout.Header>

      <Layout.Content className={styles.layout__content}>
        {children ?? <Outlet />}
      </Layout.Content>
    </Layout>
  )
}

export default withHelmet(EmptyLayout)
