import React, { FunctionComponent } from 'react'

import { ChipItemProps } from '@/components/Chips/types'

export const ChipItem: FunctionComponent<ChipItemProps> = ({
  isActive = false,
  children,
  onSelect,
  onRemoveSelection,
  className,
}) => (
  <div className={className} onClick={isActive ? onRemoveSelection : onSelect}>
    <span>{children}</span>
  </div>
)
