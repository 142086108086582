import { RouteObject } from 'react-router-dom'

import { SearchVacanciesPage, SearchVacancyPage } from '@/pages/SearchModulePages'
import { MarketplacePage } from '@/pages/SearchModulePages/MarketplacePage'
import { MarketplaceProjectPage } from '@/pages/SearchModulePages/MarketplaceProjectPage'
import { MarketplaceStudentPage } from '@/pages/SearchModulePages/MarketplaceStudentPage'

export const SearchRoutes: RouteObject = {
  children: [
    {
      path: 'jobs',
      children: [
        {
          index: true,
          element: <SearchVacanciesPage />,
        },
        {
          path: ':jobName',
          element: <SearchVacancyPage />,
        },
      ],
    },
    {
      path: 'marketplace',
      element: <MarketplacePage />,
    },
    {
      path: 'marketplace/:studentName',
      element: <MarketplaceStudentPage />,
    },
    {
      path: 'marketplace/:studentName/:projectName',
      element: <MarketplaceProjectPage />,
    },
  ],
}
