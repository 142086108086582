import { Notification } from '@/apollo/generated/graphql'

const initialNotificationEntityValues: Partial<Notification> = {
  read_at: null,
}

export const prepareNotificationToBeAdd = (notification: Notification): Notification => ({
  ...initialNotificationEntityValues,
  ...notification,
  data: JSON.stringify(notification.data),
})
