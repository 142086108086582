import React from 'react'
import { RouteObject } from 'react-router-dom'
import { Typography } from 'antd'

import { EmptyLayout } from '@/layouts'

export const NoAccessRoutes: RouteObject = {
  path: '/',
  children: [
    {
      element: <EmptyLayout />,
      children: [
        {
          index: true,
          element: <Typography.Title>No Access...</Typography.Title>,
        },
      ],
    },
  ],
}
