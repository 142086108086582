import React, { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Button } from 'antd'

import { Icon } from '@/components/Icons'
import useNotifications from '@/pages/CommonPages/ToDoPage/hooks/useNotifications'

export const NotificationHeaderMenuButton: FC = () => {
  const { totalCount, refetchUnreadNotification } = useNotifications()

  useEffect(() => {
    refetchUnreadNotification()
  }, [])

  return (
    <Link to='/to-do'>
      <Button
        shape='circle'
        type='link'
        icon={
          <Badge count={totalCount}>
            <Icon name='BellSvg' size='lg' />
          </Badge>
        }
      />
    </Link>
  )
}
