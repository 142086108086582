import { useMemo } from 'react'

import {
  CompanyUserSidebarMenuCallsToActionsFragment,
  NotificationCallToAction,
  useCompanyCallsToActionQuery,
  useReadNotificationMutation,
} from '@/apollo/generated/graphql'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

const useCallToActionsCompanyNotifications = () => {
  const { isCompany } = useRbacShortcuts()
  const { data, loading, refetch } = useCompanyCallsToActionQuery({
    notifyOnNetworkStatusChange: true,
    skip: !isCompany,
  })

  const [readNotificationRequest] = useReadNotificationMutation({
    onCompleted: () => {
      refetch()
    },
  })

  const readNotification = (notification: NotificationCallToAction) =>
    readNotificationRequest({
      variables: {
        notification: notification.id as string,
      },
    })

  const callsToActions = useMemo(
    () => (data?.me as CompanyUserSidebarMenuCallsToActionsFragment)?.notificationsCallsToAction,
    [(data?.me as CompanyUserSidebarMenuCallsToActionsFragment)?.notificationsCallsToAction],
  )

  const dashboardNotificationsCount = 0
  const jobsNotificationsCount = callsToActions?.jobs?.callsToActionCount
  const documentsNotificationsCount = callsToActions?.documents?.callsToActionCount
  const reviewsNotificationsCount = callsToActions?.reviews?.callsToActionCount
  const profileNotificationsCount = callsToActions?.profile?.callsToActionCount

  return {
    loading,
    callsToActions,
    dashboardNotificationsCount,
    jobsNotificationsCount,
    documentsNotificationsCount,
    reviewsNotificationsCount,
    profileNotificationsCount,
    readNotification,
    refetch,
  }
}

export default useCallToActionsCompanyNotifications
