import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'

import { RegisterCompanyContactPersonInput } from '@/apollo/generated/graphql'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { useValidationRules } from '@/forms/useValidationRules'
import capitalize from '@/helpers/capitalize'
import capitalizeEveryWord from '@/helpers/capitalizeEveryWord'
import { FormItemPhone } from '@/widgets/form-item-phone/ui'

export type CompanyContactPersonFormType = React.ComponentProps<
  typeof Form<RegisterCompanyContactPersonInput>
> & { loading?: boolean }

export const CompanyContactPersonForm: FC<CompanyContactPersonFormType> = ({
  onFinish,
  loading,
  ...props
}) => {
  const { requiredWithWhitespace, firstName, lastName, required, email, confirmEmail } =
    useValidationRules()

  const { t } = useTranslation()

  return (
    <Form onFinish={onFinish} {...props}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Input
                name='first_name'
                label={t('common.firstName')}
                inputProps={{
                  onInput: (e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = capitalizeEveryWord(e.target.value)
                    return e.target.value
                  },
                }}
                rules={[...requiredWithWhitespace, firstName()]}
              />
            </Col>
            <Col span={24}>
              <Input
                name='last_name'
                label={t('common.lastName')}
                inputProps={{
                  onInput: (e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = capitalize(e.target.value)
                    return e.target.value
                  },
                }}
                rules={[...requiredWithWhitespace, lastName]}
              />
            </Col>
            <Col span={24}>
              <Input
                name='position'
                label={t('common.jobPosition')}
                rules={requiredWithWhitespace}
              />
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => (
                  <FormItemPhone
                    required
                    defaultCountryCode={getFieldValue('country')?.id as string}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Input
                name='email'
                label='E-mail'
                inputProps={{
                  type: 'email',
                }}
                rules={[required, email]}
              />
            </Col>
            <Col span={24}>
              <Input
                name='email_confirmation'
                label={t('common.emailConfirmation')}
                rules={[required, email, confirmEmail('email')]}
                inputProps={{
                  type: 'email',
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Button type='primary' htmlType='submit' block loading={loading}>
            {t('navigation.continue')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
