import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'

import { useSectorsQuery } from '@/apollo/generated/graphql'
import AutoCompleteInfiniteSelect from '@/containers/AutoCompleteInfiniteSelect'
import { Rule, useValidationRules } from '@/forms/useValidationRules'

interface CompanySectorSelectFieldProps {
  name?: string
  rules?: Rule[]
}

export const CompanySectorSelectField: FC<CompanySectorSelectFieldProps> = ({
  name = 'sector',
}) => {
  const { t } = useTranslation()
  const { required } = useValidationRules()

  return (
    <Form.Item name={name} rules={[required]}>
      <Form.Item shouldUpdate>
        {({ setFieldValue, validateFields }) => (
          <AutoCompleteInfiniteSelect
            id={name}
            label={t('common.branche')}
            onChange={(id) => {
              setFieldValue('sector', id)
              validateFields([name])
            }}
            query={useSectorsQuery}
          />
        )}
      </Form.Item>
    </Form.Item>
  )
}
