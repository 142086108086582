import { z,ZodSchema } from 'zod'

import { safeParseJSON } from "@/utils"

export const objectToUrlSearchParams = <T extends object>(values: T): URLSearchParams => {
  const params = new URLSearchParams(Object.entries(values))
    return params
}

export function searchParamsToValues ( searchParams: URLSearchParams ): Record<string, unknown> {
  return Array.from(searchParams.keys()).reduce((record, key) => {
      const values = searchParams.getAll( key ).map(safeParseJSON)
      return {...record, [key]: values.length > 1 ? values : values[0]}
  }, {} as Record<string, unknown> )
}

export function makeSearchParamsObjSchema<
  Schema extends z.ZodObject<z.ZodRawShape>
> ( schema: Schema ) {
  return z.instanceof(URLSearchParams)
      .transform(searchParamsToValues)
      .pipe(schema)
}

export function validateParamsAndExtractData(params: URLSearchParams, schema: ZodSchema) {
  const parsedSchema = schema.safeParse(params)

  if (!parsedSchema.success) {
      parsedSchema.error.format()
      throw new Error('Error')
  }

  return parsedSchema.data
}
