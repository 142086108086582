import { useMemo } from 'react'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import { useAuthToken } from '@/providers/AuthenticationProvider/hooks/useAuthToken'

const pusherConfig = {
  key: import.meta.env.VITE_APP_WS_KEY,
  authEndpoint: `${import.meta.env.VITE_APP_BACKEND_URL}broadcasting/auth`,
  wsHost: import.meta.env.VITE_APP_WS_URL,
  wsPort: import.meta.env.VITE_APP_WS_PORT,
  wssPort: import.meta.env.VITE_APP_ENV === 'local' ? import.meta.env.VITE_APP_WS_PORT : undefined,
}

const useBroadcast = () => {
  const [authToken] = useAuthToken()

  return useMemo(
    () =>
      new Echo({
        key: pusherConfig.key,
        broadcaster: 'pusher',
        client: new Pusher(pusherConfig.key, {
          cluster: '',
          forceTLS: true,
          disableStats: true,
          authEndpoint: pusherConfig.authEndpoint,
          wsHost: pusherConfig.wsHost,
          wsPort: pusherConfig.wsPort,
          wssPort: pusherConfig.wssPort,
          // enabledTransports: ['ws', 'wss'],
          auth: {
            headers: {
              Authorization: authToken ? `Bearer ${authToken}` : '',
            },
          },
        }),
      }),
    [],
  )
}

export default useBroadcast
