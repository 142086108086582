import React, { forwardRef, ReactNode } from 'react'
import { Rate as AntdRate } from 'antd'
import { RateProps } from 'antd/es/rate'

import FullStarImage from '@/assets/illustrations/illustration-star.png'
import EmptyStarImage from '@/assets/illustrations/Illustration-star-empty.png'
import HalfStarImage from '@/assets/illustrations/illustration-star-half.png'

import styles from './styles.module.less'

type StarTypes = 'full' | 'half' | 'empty'
type RateSizes = 'sm' | 'md'

type CharacterType = Parameters<typeof AntdRate>[0]['character']

type R = Exclude<CharacterType, ReactNode>

type StarProps = R extends (...args: (infer U)[]) => ReactNode ? U : never

type CharacterStarProps = {
  type: StarTypes
  size: RateSizes
}

type RateSizeProps = {
  size?: RateSizes
}

type StarsPathsMap = Record<StarTypes, string>
type RateSizesType = Record<RateSizes, string>

const starSizes: RateSizesType = {
  sm: '24px',
  md: '36px',
}

const starsPathsMap: StarsPathsMap = {
  full: FullStarImage,
  half: HalfStarImage,
  empty: EmptyStarImage,
}

const CharacterStar = ({ type, size }: CharacterStarProps) => (
  <img
    src={starsPathsMap[type]}
    width={starSizes[size]}
    height={starSizes[size]}
    alt=''
    className={styles.characterStar}
  />
)

const renderCharacterStar = ({ size, ...props }: StarProps & Required<RateSizeProps>) => {
  const { value, index } = props

  if (typeof index === 'undefined') {
    return <CharacterStar type='empty' size={size} />
  }

  if (typeof value !== 'undefined' && value > index) {
    if (Math.abs(index - value) <= 0.74) {
      return <CharacterStar type='half' size={size} />
    }

    return <CharacterStar type='full' size={size} />
  }

  return <CharacterStar type='empty' size={size} />
}

export const Rate: React.ForwardRefExoticComponent<RateProps & RateSizeProps> = forwardRef(
  ({ size = 'md', ...props }, ref) => (
    <AntdRate
      className={styles.rate}
      character={(starProps) => renderCharacterStar({ ...starProps, size })}
      {...props}
      ref={ref}
    />
  ),
)
