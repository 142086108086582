import { useTranslation } from 'react-i18next'

import { useRules } from '@/features/form-fields/vat-number-field'
import { FieldFC, FormField } from '@/shared'
import { Input } from '@/shared/input'

type VatNumberFormItemFieldProps = {
  rules?: Parameters<typeof useRules>[0]
}

export const VatNumberFormItemField: FieldFC<VatNumberFormItemFieldProps> = ({
  rules,
  ...props
}) => {
  const [t] = useTranslation()

  const validationRules = useRules(rules)

  return (
    <FormField
      name={VatNumberFormItemField.fieldName}
      label={t('common.vatNumber')}
      rules={validationRules}
      {...props}
    >
      <Input />
    </FormField>
  )
}

VatNumberFormItemField.fieldName = 'vat_number'
