import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Notification,
  useReadAllNotificationsMutation,
  useReadNotificationMutation,
  useReadNotificationsQuery,
  useUnreadNotificationsQuery,
} from '@/apollo/generated/graphql'
import useBroadcast from '@/hooks/broadcast/useBroadcast'

import { prepareNotificationToBeAdd } from '../helpers/preapareNotification'

const useNotifications = () => {
  const [unReadNotifications, setUnReadNotifications] = useState<Notification[]>([])
  const [readNotifications, setReadNotifications] = useState<Notification[]>()

  const {
    data,
    loading,
    refetch: refetchUnreadNotification,
  } = useUnreadNotificationsQuery({
    onCompleted: (unreadNotificationsQuery) => {
      setUnReadNotifications(unreadNotificationsQuery?.me?.unreadNotifications || [])
    },
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  })

  const { loading: loadReadNotifications, refetch: refetchReadNotification } =
    useReadNotificationsQuery({
      onCompleted: (readNotificationsQuery) => {
        setReadNotifications(readNotificationsQuery?.me?.readNotifications?.data || [])
      },
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    })
  const [readNotificationRequest] = useReadNotificationMutation({
    onCompleted: () => {
      refetchUnreadNotification()
      refetchReadNotification()
    },
  })
  const [readAllNotifications] = useReadAllNotificationsMutation({
    onCompleted: () => {
      refetchUnreadNotification()
      refetchReadNotification()
    },
  })

  const broadcast = useBroadcast()

  const notificationChannelIsCreated = useRef(false)

  const userId = useMemo(() => data?.me?.userId, [data?.me?.userId])

  const totalCount = unReadNotifications?.length

  const addNotification = useCallback((notification: Notification): void => {
    setUnReadNotifications((prevState) => [prepareNotificationToBeAdd(notification), ...prevState])
  }, [])

  const removeNotification = (notification: Notification) => {
    setUnReadNotifications((prevState) => prevState?.filter((n) => n.id !== notification.id))
  }

  const readNotification = (notification: Notification) =>
    readNotificationRequest({
      variables: {
        notification: notification.id,
      },
    }).then(() => {
      refetchReadNotification()
      return removeNotification(notification)
    })

  useEffect(() => {
    if (userId && !notificationChannelIsCreated.current) {
      // console.info('connected')
      try {
        broadcast
          .private(`App.Models.User.${userId}`)
          .notification((notification: Notification) => {
            addNotification(notification)
          })

        broadcast
          .private(`App.Models.User.${userId}.conversations`)
          .listen('.message.sent', () => {})
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
      notificationChannelIsCreated.current = true
    }
  }, [addNotification, broadcast, userId])

  return {
    loading,
    loadReadNotifications,
    totalCount,
    unReadNotifications,
    readNotifications,
    readAllNotifications,
    addNotification,
    removeNotification,
    readNotification,
    refetchReadNotification,
    refetchUnreadNotification,
  }
}

export default useNotifications
