import React, { cloneElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu, Space } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import clsx from 'classnames'

import { useIsMobileDevice } from '@/hooks/useIsMobileDevice'
import { useAuthenticatedLayoutProviderContext } from '@/layouts/AuthenticatedLayout/provider'
import {
  MenuItem,
  MenuItems,
} from '@/layouts/AuthenticatedLayout/Sidebar/hooks/useExistingMenuItems'
import { useMenuItems } from '@/layouts/AuthenticatedLayout/Sidebar/hooks/useMenuItems'

import styles from './styles.module.less'

export const SidebarMenu = () => {
  const context = useAuthenticatedLayoutProviderContext()
  const isMobileDevice = useIsMobileDevice()
  const navigate = useNavigate()

  const [menuItems] = useMenuItems()

  const location = useLocation()
  const authenticatedLayoutProviderContext = useAuthenticatedLayoutProviderContext()

  const onClickItem = (item: MenuItem) => {
    if (item.isExternal && typeof item.to === 'string') {
      window.open(item.to)
    } else {
      navigate(item.to)
    }
  }

  const onSelectItem = () => {
    context.setSideBarCollapsed(true)
  }

  const getSelectedMenuItem = (pathToCompare: string) =>
    menuItems.flat(2).find((item) => {
      const path =
        typeof item.to === 'object' && item.to.pathname && item.to.search
          ? item.to.pathname + item.to.search
          : item.to

      return path === pathToCompare
    })

  const createItems = (items: MenuItems, iconsSize: 'sm' | 'lg' = 'lg'): ItemType[] | undefined => {
    if (!items.length) return undefined

    return items.map((element) => {
      const item = Array.isArray(element) ? element[0] : element

      const children = Array.isArray(element) ? element[1] : []

      const itemType: ItemType = {
        key: item.name,
        icon: cloneElement(item.icon, {
          size: iconsSize,
        }),
        label: (
          <Space size='small'>
            {item.text} {item.badgeCounter}
          </Space>
        ),
        onClick: !children.length
          ? () => {
              onClickItem(item)
            }
          : undefined,
        children: createItems(Array.isArray(element) ? element[1] : [], 'sm'),
      }

      return itemType
    })
  }

  const getParent = (items: MenuItems, child?: MenuItem) => {
    if (!child) return undefined

    let parent: MenuItem

    for (let i = 0; i < items.length; i++) {
      const item = items[i]

      if (Array.isArray(item)) {
        ;[parent] = item

        if (item[1].find((el) => el.name === child?.name)) {
          return parent
        }
      }
    }

    return undefined
  }

  const selectedParent = getParent(
    menuItems,
    getSelectedMenuItem(location.pathname + location.search),
  )

  const preparedItems = createItems(menuItems)

  const openKeys = selectedParent ? [selectedParent.name] : undefined

  return (
    <Menu
      className={clsx(styles.menu, { [styles.desktopMenu]: !isMobileDevice })}
      mode='inline'
      forceSubMenuRender={false}
      defaultOpenKeys={authenticatedLayoutProviderContext.sideBarCollapsed ? undefined : openKeys}
      selectedKeys={[getSelectedMenuItem(location.pathname + location.search)?.name || '']}
      items={preparedItems}
      onSelect={isMobileDevice ? onSelectItem : undefined}
    />
  )
}
