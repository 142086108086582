import React from 'react'

import { Icon } from '@/components/Icons'
import { useAuthenticatedLayoutProviderContext } from '@/layouts/AuthenticatedLayout/provider'

export const CollapseSiderButton = () => {
  const context = useAuthenticatedLayoutProviderContext()

  const onClickHandler = () => {
    context.setSideBarCollapsed((prevState) => !prevState)
  }

  return (
    <div>
      <Icon name='HamburgerSvg' size='md' className='trigger' onClick={onClickHandler} />
    </div>
  )
}
