import { CompanyProjectApplyPage } from '@/pages/CompanyPages'

export default {
  path: 'projects',
  children: [
    {
      path: ':id/apply',
      element: <CompanyProjectApplyPage />,
    },
  ],
}
