import React, { FC, PropsWithChildren } from 'react'
import { Layout as AntdLayout } from 'antd'

import { useBreakpoints } from '@/hooks/useBreakpoints'
import { useIsMobileDevice } from '@/hooks/useIsMobileDevice'
import { useAuthenticatedLayoutProviderContext } from '@/layouts/AuthenticatedLayout/provider'

export const Sider: FC<PropsWithChildren> = ({ children }) => {
  const context = useAuthenticatedLayoutProviderContext()
  const { xs } = useBreakpoints()
  const isMobileDevice = useIsMobileDevice()

  return (
    <AntdLayout.Sider
      width={270}
      collapsedWidth={xs || isMobileDevice ? 0 : undefined}
      className='site-layout-background'
      collapsed={context.sideBarCollapsed}
    >
      {children}
    </AntdLayout.Sider>
  )
}
