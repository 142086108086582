import { cloneElement, ComponentProps, FC, isValidElement, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Country } from '@/apollo/generated/graphql'
import { useCountries } from '@/entities/country'
import { model } from '@/features/form-fields/country-field'
import { useOptions } from '@/features/form-fields/country-field/model'
import { FieldFC, FormField } from '@/shared'
import { Select } from '@/shared/select'

interface CountryFormItemFieldProps
  extends Omit<ComponentProps<typeof FormField>, 'name' | 'label' | 'rules'> {
  disabled?: boolean
}

const FieldMiddleware: FC<PropsWithChildren<{ value?: Country }>> = ({ children, ...props }) => {
  const { helpers } = useCountries()

  const country = props?.value ? helpers.getCountryById(props.value.id) : null

  const newProps = {
    ...props,
    value: country,
  }

  return <>{isValidElement(children) && children && cloneElement(children, { ...newProps })}</>
}

export const CountryFormItemField: FieldFC<CountryFormItemFieldProps> = (props) => {
  const [t] = useTranslation()

  const { helpers } = useCountries()
  const options = useOptions()
  const rules = model.useRules()

  return (
    <FormField
      name={CountryFormItemField.fieldName}
      label={t('form.country')}
      rules={rules}
      {...props}
    >
      <FieldMiddleware>
        <Select options={options} getSelectedValue={helpers.getCountryById} />
      </FieldMiddleware>
    </FormField>
  )
}

CountryFormItemField.fieldName = 'country'
