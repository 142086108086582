import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Row, Typography } from 'antd'

import { Button } from '@/components/Button'

interface ForgotPasswordSuccessScreenProps {
  email: string
}

export const ForgotPasswordSuccessScreen: FC<ForgotPasswordSuccessScreenProps> = ({ email }) => {
  const { t } = useTranslation()

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Typography.Title level={2}>{t('auth.forgotPassword.v2.title')}</Typography.Title>
      </Col>
      <Col span={24}>
        <Typography.Text>
          {t('auth.forgotPassword.v2.successScreen.emailSentText', { email })}
        </Typography.Text>
      </Col>
      <Col span={24}>
        <Typography.Text>{t('auth.forgotPassword.v2.successScreen.noEmailText')}</Typography.Text>
      </Col>
      <Col span={24}>
        <Link to='/'>
          <Button type='primary' block>
            {t('auth.forgotPassword.v2.successScreen.button')}
          </Button>
        </Link>
      </Col>
    </Row>
  )
}
