import loadable from '@loadable/component'

import { LoadingScreen } from '@/screens/CommonScreens'

export const ForgotPasswordPage = loadable(() =>
  import('@/pages/AuthenticationPages/ForgotPasswordPage').then((module) => ({
    default: module.ForgotPasswordPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const LoginPage = loadable(() =>
  import('@/pages/AuthenticationPages/LoginPage').then((module) => ({
    default: module.LoginPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const NotFoundPage = loadable(() =>
  import('@/pages/AuthenticationPages/NotFoundPage').then((module) => ({
    default: module.NotFoundPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const ResetPasswordPage = loadable(() =>
  import('@/pages/AuthenticationPages/ResetPasswordPage').then((module) => ({
    default: module.ResetPasswordPage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          
export const WpSitePage = loadable(() =>
  import('@/pages/AuthenticationPages/WpSitePage').then((module) => ({
    default: module.WpSitePage,
  })), {
  fallback: <LoadingScreen loading={true} />,
}
)
          