import {
  StudentProjectCreatePage,
  StudentProjectCreateSuccessPage,
  StudentProjectEditPage,
  TalentPrivateProjectAssignmentCreatePage,
  TalentPrivateProjectAssignmentUpdatePage,
} from '@/pages/StudentPages'
import { StudentProjectCreatedSuccessfullyPage } from '@/pages/StudentPages/StudentProjectCreatedSuccessfullyPage'

export default {
  path: 'projects',
  children: [
    {
      path: 'create',
      element: <StudentProjectCreatePage />,
    },
    {
      path: 'create/success',
      element: <StudentProjectCreatedSuccessfullyPage />
    },
    {
      path: ':id/edit',
      element: <StudentProjectEditPage />,
    },
    {
      path: ':id/success',
      element: <StudentProjectCreateSuccessPage />,
    },
    {
      path: 'propose-to-company/:companyId',
      element: <TalentPrivateProjectAssignmentCreatePage />,
    },
    {
      path: 'propose-to-company/:id/edit',
      element: <TalentPrivateProjectAssignmentUpdatePage />,
    },
  ],
}
