import { createContext, FC, PropsWithChildren } from 'react'

type CountryProviderContextType = {
  homeCountryCountryCode: string
}

export const countryProviderContext = createContext<CountryProviderContextType | null>(null)

const { Provider } = countryProviderContext

export const CountryProvider: FC<PropsWithChildren<CountryProviderContextType>> = ({
  children,
  ...props
}) => (
  <Provider
    value={{
      homeCountryCountryCode: props.homeCountryCountryCode.toUpperCase(),
    }}
  >
    {children}
  </Provider>
)
