import React, { FC, PropsWithChildren, ReactNode } from 'react'
import clsx from 'classnames'

import styles from './styles.module.less'

type FloatingLabelProps = {
  active?: boolean
  label: ReactNode | string
}

export const FloatingLabel: FC<PropsWithChildren<FloatingLabelProps>> = ({
  active,
  children,
  label,
}) => (
  <div className={styles.container}>
    {children}

    <div className={styles.container__floating_label_wrapper}>
      <div>
        <span
          className={clsx(styles.label, {
            [styles.floatingLabel]: active,
          })}
        >
          {label}
        </span>
      </div>
    </div>
  </div>
)
