import { allowedCountryCodes } from '@/shared'

export type Country = { name: string; id: string }

type GetCountries = (lang: string) => Record<string, Country>

export const getCountries: GetCountries = (lang = 'en') => {
  const countryName = new Intl.DisplayNames([lang], { type: 'region', languageDisplay: 'standard' })
  let countries: Record<string, Country> = {}
  allowedCountryCodes.forEach((code) => {
    const name = countryName.of(code)
    if (name && code !== name) {
      countries = {
        ...countries,
        [code]: {
          name,
          id: code,
        },
      }
    }
  })

  return countries
}
