// import { ReactNode } from 'react'

// import { useIsMobileDevice } from '@/layouts/hooks/useIsMobileDevice'
import useRbacShortcuts from '@/rbac/hooks/useRbacShortcuts'

import { useAdminMenuItems } from './useAdminMenuItems'
import { useCompanyMenuItems } from './useCompanyMenuItems'
import { MenuItems } from './useExistingMenuItems'
import { useStudentMenuItems } from './useStudentMenuItems'

// type Extra = ReactNode | undefined

const emptyItems: MenuItems = []

export const useMenuItems = (): [MenuItems] => {
  const { isStudent, isCompany, isAdmin } = useRbacShortcuts()
  // const isMobileDevice = useIsMobileDevice()

  const studentMenuItems = useStudentMenuItems()
  const companyMenuItems = useCompanyMenuItems()
  const adminMenuItems = useAdminMenuItems()

  // if (isStudent) return [studentMenuItems, isMobileDevice && <SearchJobsButton />]
  if (isStudent) return [studentMenuItems]
  if (isCompany) return [companyMenuItems]
  if (isAdmin) return [adminMenuItems]

  return [emptyItems]
}
