import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Row } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import { Button } from '@/components/Button'
import { Illustration } from '@/components/Illustration'
import { Title } from '@/screens/CommonScreens/ReviewJobScreen/components/Title'

interface VacancyApplicationCongratsScreenProps {
  assignmentId?: string
}
export const VacancyApplicationCongratsScreen: FC<VacancyApplicationCongratsScreenProps> = ({
  assignmentId,
}) => {
  const [t] = useTranslation()
  const breakpoints = useBreakpoint()

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Row justify='center'>
          <Illustration
            name='celebrate'
            imageProps={{
              width: breakpoints.xs ? '160px' : '200px',
              height: breakpoints.xs ? '160px' : '200px',
            }}
          />
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify='center'>
          <Title>{t('jobs.successApply')}</Title>
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify='center'>
          {assignmentId ? (
            <Link to={`/assignments/${assignmentId}`}>
              <Button type='primary'>{t('common.viewDetails')}</Button>
            </Link>
          ) : (
            <Link to='/'>
              <Button type='primary'>{t('common.goBack')}</Button>
            </Link>
          )}
        </Row>
      </Col>
    </Row>
  )
}
