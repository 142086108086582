import { ComponentProps, FC } from 'react'
import { Image, Select, Space, Typography } from 'antd'
import clsx from 'classnames'

import { getFlagImgPathByRegionCode, getRegions } from '@/shared/phonenumber'
import { regionNames } from '@/shared/phonenumber/lib/regionNames'

import styles from './styles.module.less'

export const CountrySelect: FC<
  Omit<ComponentProps<typeof Select>, 'size' | 'defaultValue'> & {
    defaultValue?: string
    getDropdownContainer?: () => HTMLElement
  }
> = ({ className, getDropdownContainer, value, defaultValue, ...rest }) => {
  const regions = getRegions()

  // remove getDropdownContainer checker because ref current is null when component render first time
  // and the flags list isn't rendered which provide bug

  return (
    <div className={styles.container}>
      <Select
        className={clsx(styles.select, className)}
        size='large'
        showSearch
        filterOption={(input, option) =>
          (option?.key ?? '').toLowerCase().includes(input.toLowerCase()) ||
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
        getPopupContainer={getDropdownContainer}
        value={value || defaultValue}
        {...rest}
      >
        {regions.map((region) => (
          <Select.Option value={region} key={regionNames.of(region)}>
            <Space direction='horizontal' size={8}>
              <Image
                src={getFlagImgPathByRegionCode(region)}
                width={30}
                height={30}
                alt={region}
                preview={false}
              />

              <Typography.Text data-selet-option-label>{regionNames.of(region)}</Typography.Text>
            </Space>
          </Select.Option>
        ))}
      </Select>
    </div>
  )
}
