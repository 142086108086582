import React, { FC } from 'react'
import { Image } from 'antd'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'

import LogoSvg from '@/assets/images/logo.svg'
import LogoBright from '@/assets/images/logo-bright.svg'
import LogoMiniSvg from '@/assets/logo_mini.svg'

type Props = {
  bright?: boolean
}

export const Logo: FC<Props> = ({ bright }) => {
  const breakpoints = useBreakpoint()
  if (bright) return <Image src={LogoBright} preview={false} />
  return <Image src={breakpoints.xs ? LogoMiniSvg : LogoSvg} preview={false} />
}
