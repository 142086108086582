import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Steps } from 'antd'

interface StudentRegistrationStepsProps {
  current: number
}

export const StudentRegistrationSteps: FC<StudentRegistrationStepsProps> = ({ current }) => {
  const { t } = useTranslation()
  return (
    <Steps size='small' current={current}>
      <Steps.Step
        icon={current > 0 ? <CheckCircleOutlined style={{ color: '#389E0D' }} /> : undefined}
        title={t('auth.register.talent.aboutYouStep')}
      />
      <Steps.Step title={t('auth.register.talent.interestsStep')} />
    </Steps>
  )
}
