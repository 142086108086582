import React, { ComponentProps, FC } from 'react'
import { Input as AntdInput } from 'antd'

import { transformersComposer } from '@/shared/lib/transformers/transformersComposer'
import { Transformer } from '@/shared/lib/transformers/types'

interface InputProps extends Omit<ComponentProps<typeof AntdInput>, 'size' | 'autoComplete'> {
  transformers?: Transformer[]
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
}

export const Input: FC<InputProps> = ({ transformers, onChange, ...props }) => {
  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.target.value = transformersComposer(transformers ?? [], e.target.value)
    onChange?.(e)
  }

  return <AntdInput size='large' autoComplete='off' onChange={onChangeHandler} {...props} />
}
