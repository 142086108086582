import { Avatar } from 'antd'
import clsx from 'classnames'

import styles from './styles.module.less'

interface AvatarInitialProps {
  size?: 'small' | 'large' | number
  initials?: string
}

export const AvatarInitial = ({ initials, size = 'large' }: AvatarInitialProps) => {
  const getAvatarSize = () => {
    if (typeof size === 'number') return size

    if (size === 'large') return 120

    return 64
  }

  return (
    <Avatar
      size={getAvatarSize()}
      className={clsx(styles.avatar, {
        [styles.avatar__small]: size === 'small',
        [styles.avatar__large]: size === 'large',
      })}
      icon={
        <span className={styles.avatar__title}>{initials?.charAt(0).toUpperCase() || null}</span>
      }
    />
  )
}
