import { createRef } from 'react'
import { Button as AntdButton } from 'antd'
import { ButtonProps as AntdButtonProps, ButtonType } from 'antd/es/button/button'
import clsx from 'classnames'

import './styles.module.less'

type ExistingButtonType = Extract<ButtonType, 'primary' | 'default'>

export interface ButtonProps extends Omit<AntdButtonProps, 'type' | 'ghost'> {
  type?: ExistingButtonType
  info?: true
  loading?: boolean
  success?: true
  link?: true
  light?: true
}

export const Button = ({ light, info, success, link, className, ...props }: ButtonProps) => {
  const ref = createRef<HTMLElement>()

  const classes = clsx(className, {
    light,
    info,
    success,
    danger: props.danger,
    link,
  })

  return <AntdButton ref={ref} {...props} className={classes} />
}
