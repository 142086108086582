import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Skeleton, Space, Typography } from 'antd'
import clsx from 'classnames'

import {
  StudentJobCategoryLevel,
  StudentSpecialization,
  VacancySpecialization,
  VacancySpecializationLevel,
} from '@/apollo/generated/graphql'
import { Chips } from '@/components/Chips'
import { ChipItem } from '@/components/Chips/ChipItem'
import { Icon } from '@/components/Icons'
import { Select } from '@/components/Input'
import { useValidationRules } from '@/forms/useValidationRules'

import styles from './styles.module.less'

interface SpecializationFieldsProps {
  specializations?: StudentSpecialization[]
  maxItems?: number
  typeChips?: string
  initialValues?: StudentSpecialization[]
  withLevels?: boolean
}

interface OptionsState {
  label?: string
  value?: string
  id?: string
  sequence?: number
}

export type Section = OptionsState & {
  sequence: number
}

const { Text } = Typography

const findArrayDifferences = (arr1: OptionsState[], arr2: VacancySpecialization[]) =>
  arr1.filter(
    (item) =>
      !arr2.some((otherItem) => item.id === (otherItem?.id ? otherItem.id : otherItem?.name)),
  )

export const SpecializationFields: FC<SpecializationFieldsProps> = ({
  specializations,
  maxItems = 6,
  typeChips = 'multiple',
  withLevels = true,
}) => {
  const { t } = useTranslation()
  const { required } = useValidationRules()
  const [selectedItem, setSelectedItem] = useState<string>()

  const allSpecializations =
    specializations?.map((data) => ({
      label: data.name,
      value: data.id,
      id: data.id,
    })) || []

  const [options, setOptions] = useState<OptionsState[]>([])

  if (!allSpecializations?.length) {
    return (
      <div className={styles.skeleton}>
        {Array.from(Array(6).keys()).map(() => (
          <Skeleton.Input size='small' active />
        ))}
      </div>
    )
  }

  const updateOptions = (selectedSpecializations: VacancySpecialization[]) => {
    if (selectedSpecializations.length === allSpecializations.length) {
      return []
    }
    const removeSelectedValues = findArrayDifferences(allSpecializations, selectedSpecializations)
    setOptions(removeSelectedValues)

    return removeSelectedValues
  }

  return (
    <Space size={12} direction='vertical' className='w-full'>
      <div className={styles.selectSpecialization}>
        <Form.List name='specializations'>
          {(fields, { add, remove }) => (
            <>
              {(!fields.length
                ? [{ name: 0, key: 0, fieldKey: 0, isListField: true }]
                : fields
              ).map((field) => (
                <div key={field.key}>
                  <Form.Item shouldUpdate>
                    {({ getFieldValue, setFieldsValue }) => (
                      <Space size={12} direction='vertical' className={styles.selectContainer}>
                        <Select
                          {...field}
                          activeSelect
                          onFocusSelect={() => updateOptions(getFieldValue('specializations'))}
                          name={[field.name, 'name']}
                          rules={[required]}
                          label={t('specializations.title')}
                          className='w-full'
                          selectProps={{
                            value: selectedItem,
                            showSearch: true,
                            filterOption: (inputValue, option) =>
                              option?.label.toLowerCase().includes(inputValue.toLowerCase()),
                            onChange: (value) => {
                              const allSelectedSpecializations = getFieldValue('specializations')

                              allSelectedSpecializations[field.name] = {
                                ...allSelectedSpecializations[field.name],
                                id: value,
                              }

                              setFieldsValue({
                                specializations: allSelectedSpecializations,
                              })

                              setSelectedItem(value)
                            },
                            options: options.length
                              ? options
                              : updateOptions(getFieldValue('specializations')),
                          }}
                        />

                        <Icon
                          name='CloseSvg'
                          size='sm'
                          className={styles.removeIcon}
                          onClick={() => {
                            remove(field.name)
                            updateOptions(getFieldValue('specializations'))
                          }}
                        />
                        {withLevels && (
                          <div
                            className={clsx(styles.levelsChips, 'justify-center sm:justify-start')}
                          >
                            <Form.Item {...field} name={[field.name, 'levels']} rules={[required]}>
                              <Chips
                                type={typeChips}
                                defaultValues={
                                  typeChips === 'multiple'
                                    ? getFieldValue('specializations')[field.name]?.levels?.map(
                                        (level: VacancySpecializationLevel) => ({ name: level }),
                                      )
                                    : getFieldValue('specializations')[field.name]?.levels
                                }
                              >
                                <ChipItem name={StudentJobCategoryLevel.Junior}>
                                  {t('job_categories.levels.junior_year')}
                                </ChipItem>

                                <ChipItem name={StudentJobCategoryLevel.Mid}>
                                  {t('job_categories.levels.middle_year')}
                                </ChipItem>

                                <ChipItem name={StudentJobCategoryLevel.Expert}>
                                  {t('job_categories.levels.senior_year')}
                                </ChipItem>
                              </Chips>
                            </Form.Item>

                            {typeChips === 'multiple' && (
                              <Text
                                onClick={() => {
                                  const reformattedSpecialization = {
                                    ...getFieldValue('specializations')[field.name],
                                    levels: [
                                      VacancySpecializationLevel.Junior,
                                      VacancySpecializationLevel.Mid,
                                      VacancySpecializationLevel.Expert,
                                    ],
                                  }

                                  const allSelectedSpecializations =
                                    getFieldValue('specializations')

                                  allSelectedSpecializations[field.name] = reformattedSpecialization

                                  setFieldsValue({
                                    specializations: allSelectedSpecializations,
                                  })
                                }}
                                className='cursor-pointer hover:underline sm:ml-4 mt-4 sm:mt-0'
                              >
                                {t('common.selectAll')}
                              </Text>
                            )}
                          </div>
                        )}
                      </Space>
                    )}
                  </Form.Item>
                </div>
              ))}

              {options.length >= 1 && (
                <Row gutter={[0, 15]} justify='end' className={styles.containerAdd}>
                  <Col>
                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) =>
                        (!getFieldValue('specializations') ||
                          getFieldValue('specializations')?.length < maxItems) &&
                        getFieldValue('specializations').length !== allSpecializations.length && (
                          <Text
                            onClick={() => {
                              const isEmpty = getFieldValue('specializations')?.filter(
                                (specialization) =>
                                  specialization === undefined || specialization.name === undefined,
                              )

                              if (isEmpty.length === 0) {
                                updateOptions(getFieldValue('specializations'))
                                add()
                              }
                            }}
                            className={styles.addText}
                          >
                            {t('specializations.add')}
                            <Icon name='PlusSvg' size='sm' />
                          </Text>
                        )
                      }
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Form.List>
      </div>
    </Space>
  )
}
