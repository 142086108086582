import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Typography } from 'antd'

import IllustrationCompany from '@/assets/illustrations/illustration-company.png'
import IllustrationStudent from '@/assets/illustrations/illustration-talent.png'
import { AccountTypeSelection } from '@/components/AccountTypeSelection'
import { Button } from '@/components/Button'
import {
  ImageTypeEnum,
  OnboardingImageContext,
} from '@/layouts/UnauthenticatedLayout/UnauthenticatedLayoutTemplate'

export const AccountTypeScreen = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [accountType, setAccountType] = useState('talent')
  const imageContext = useContext(OnboardingImageContext)

  useEffect(() => {
    imageContext?.setImage(ImageTypeEnum.accountType)
    return () => {
      imageContext?.setImage(ImageTypeEnum.default)
    }
  }, [])

  const handleOnButtonClick = useCallback(() => {
    navigate(`/registration/${accountType}`)
  }, [navigate, accountType])

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={2}>Join us today!</Typography.Title>
      </Col>
      <Col span={24}>
        <AccountTypeSelection
          defaultVariant='talent'
          variants={[
            {
              imageSrc: IllustrationStudent,
              text: (
                <>
                  <Row justify={'center'}>
                    <Typography.Text>{`${t('common.freelancer')}`}</Typography.Text>
                  </Row>
                  <Row justify={'center'}>
                    <Typography.Text className={'text-nowrap'} strong>
                      {t('onboarding.cards.freelancerAccountType.explanation')}
                    </Typography.Text>
                  </Row>
                </>
              ),
              name: 'talent',
            },
            {
              imageSrc: IllustrationCompany,
              text: (
                <>
                  <Row justify={'center'}>
                    <Typography.Text>{`${t('common.companyOg')}`}</Typography.Text>
                  </Row>
                  <Row justify={'center'}>
                    <Typography.Text className={'text-nowrap'} strong>
                      {t('onboarding.cards.companyAccountType.explanation')}
                    </Typography.Text>
                  </Row>
                </>
              ),
              name: 'company',
            },
          ]}
          onSelected={(name) => setAccountType(name)}
        />
      </Col>
      <Col span={24}>
        <Button type='primary' block onClick={handleOnButtonClick}>
          {t('navigation.continue')}
        </Button>
      </Col>
    </Row>
  )
}
