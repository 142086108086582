import { FC, PropsWithChildren, ReactNode } from 'react'
import Spline from '@splinetool/react-spline'

import styles from './styles.module.less'

interface LoadingScreenProps {
  loading?: boolean
  children?: ReactNode
}

export const LoadingScreen: FC<PropsWithChildren<LoadingScreenProps>> = ({ children, loading }) => {
  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.loader}>
          <Spline scene='/assets/scene_loader.splinecode' />
        </div>
      </div>
    )
  }

  return <>{children}</>
}
