import React, { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Button } from 'antd'

import { useMeQuery } from '@/apollo/generated/graphql'
import { Icon } from '@/components/Icons'
import useBroadcast from '@/hooks/broadcast/useBroadcast'

export const ChatHeaderMenuButton: FC = () => {
  const echo = useBroadcast()
  const unreadMessagesCountIsSetRef = useRef<boolean>(false)
  const { data } = useMeQuery()

  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0)

  const listenToConversations = (userId: string | undefined) => {
    if (!userId) {
      return undefined
    }

    return echo.private(`App.Models.User.${userId}.conversations`)
  }

  useEffect(() => {
    if (!unreadMessagesCountIsSetRef.current) {
      unreadMessagesCountIsSetRef.current = true
    }
    setUnreadMessagesCount(data?.me?.unreadMessagesCount ?? 0)
  }, [data?.me?.unreadMessagesCount])

  useEffect(() => {
    const subscription = listenToConversations(data?.me?.id)?.listen('.message.sent', () => {
      setUnreadMessagesCount((prevState) => prevState + 1)
    })

    return () => {
      subscription?.stopListening('.message.sent')
    }
  }, [data?.me])

  useEffect(() => {
    const subscription = listenToConversations(data?.me?.id)?.listen('.message.read', () => {
      setUnreadMessagesCount((prevState) => (prevState > 0 ? prevState - 1 : prevState))
    })

    return () => {
      subscription?.stopListening('.message.read')
    }
  }, [data?.me])

  return (
    <Link to='/chat'>
      <Button
        shape='circle'
        type='link'
        icon={
          <Badge count={unreadMessagesCount} overflowCount={9}>
            <Icon name='ChatSvg' size='lg' />
          </Badge>
        }
      />
    </Link>
  )
}
