import React, {
  Children,
  cloneElement,
  FC,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
} from 'react'
import { Skeleton, Typography } from 'antd'

import {
  CardDataViewItem,
  CardDataViewItemProps,
} from '@/components/Cards/CardDataView/CardDataViewItem'
import Noob from '@/components/Noob'

import styles from './styles.module.less'

interface CardDataViewProps {
  title?: string
  extra?: React.ReactNode
  children:
    | ReactElement<PropsWithChildren<CardDataViewItemProps>>[]
    | ReactElement<PropsWithChildren<CardDataViewItemProps>>
  loading?: boolean
}

interface CardDataViewComposition {
  Item: FunctionComponent<CardDataViewItemProps>
}

const { Title } = Typography

export const CardDataView: FC<CardDataViewProps> & CardDataViewComposition = ({
  title,
  extra,
  children,
  loading,
}) => (
  <div className={styles.card_data_view}>
    {loading ? (
      <Skeleton style={{ padding: '1rem' }} />
    ) : (
      <>
        {title ? <Title level={4}>{title}</Title> : <Noob />}

        {Children.map(children, (child) => {
          if (child.type === CardDataViewItem) {
            return cloneElement(child, child.props)
          }

          return null
        })}

        {!!extra && <div className={styles.card_data_view__footer}>{extra}</div>}
      </>
    )}
  </div>
)

CardDataView.Item = CardDataViewItem
