import { AdminProjectAssignmentPage } from '@/pages/AdminPages'

export default {
  path: 'projectAssignment',
  children: [
    {
      path: ':id',
      element: <AdminProjectAssignmentPage />,
    },
  ],
}
